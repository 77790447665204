<section class="section-contact-us-sim">
    <div class="content-wrap contact-us">
        <div class="content" data-aos="fade-up">
            <h3 [innerHTML]="text1"></h3>
            
            <div class="flex-vertical-centered prom-borded-button-wrapper">
                <app-prom-animated-button [arrow]="'right'" [theme]="'sim-white'" (click)="openContactDialog()">
                    {{'GENERAL.TALK_TO_US' | translate | uppercase}}
                </app-prom-animated-button>
            </div>
        </div>
    </div>

    <div class="content-wrap anteo-newsletter">
        <div class="content" data-aos="fade-up">
            <h3 [innerHTML]="text2"></h3>
            
            <div class="flex-vertical-centered prom-borded-button-wrapper">
                <app-prom-animated-button [arrow]="'right'" [theme]="'sim-red'" [bgColor]="'dark'" (click)="openContactDialog()">
                    {{'ANTEO.SUBSCRIBE' | translate | uppercase}}
                </app-prom-animated-button>
            </div>
        </div>
    </div>
</section>
