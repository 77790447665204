import { Component, Input } from '@angular/core';
import { INewsReference, LocaleEnum, NewsTypesWithIconEnum } from '../../models/common';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { KebabCasePipe } from "../../pipes/kebab-case.pipe";
import { TranslateModule } from '@ngx-translate/core';
import { PromAnimatedButtonComponent } from '../prom-animated-button/prom-animated-button.component';

@Component({
  selector: 'app-section-news-headlines',
  templateUrl: './section-news-headlines.component.html',
  styleUrl: './section-news-headlines.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, KebabCasePipe, PromAnimatedButtonComponent],
})
export class SectionNewsHeadlinesComponent {
  @Input() news?: INewsReference[] = [];
  @Input() bgColor: string = 'white';
  @Input() currentLang: string = LocaleEnum.IT;
  @Input() showSeeMoreButton: boolean = true;

  readonly supportedIcons: NewsTypesWithIconEnum[] = Object.values(NewsTypesWithIconEnum);

  readonly articleRoot: string = '/about-us/insights/article';
}
