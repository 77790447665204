import { __assign, __rest } from './_virtual/_tslib.js';
import './types.js';
import { invoke } from './actionTypes.js';
import './utils.js';
import './environment.js';
function toInvokeSource(src) {
  if (typeof src === 'string') {
    var simpleSrc = {
      type: src
    };
    simpleSrc.toString = function () {
      return src;
    }; // v4 compat - TODO: remove in v5

    return simpleSrc;
  }
  return src;
}
function toInvokeDefinition(invokeConfig) {
  return __assign(__assign({
    type: invoke
  }, invokeConfig), {
    toJSON: function () {
      invokeConfig.onDone;
      invokeConfig.onError;
      var invokeDef = __rest(invokeConfig, ["onDone", "onError"]);
      return __assign(__assign({}, invokeDef), {
        type: invoke,
        src: toInvokeSource(invokeConfig.src)
      });
    }
  });
}
export { toInvokeDefinition, toInvokeSource };