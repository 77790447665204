import { Component, Input, AfterViewInit } from '@angular/core';
import { ISitePageFlippingCard } from '../../models/common';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrl: './image-card.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class ImageCardComponent implements AfterViewInit {
  @Input() card?: ISitePageFlippingCard;
  @Input() borderColor: 'electric-blue' | 'red' = 'red';
  @Input() imageSize: 'default' | 'small' = 'default';
  @Input() cardHeight: 'default' | 'tall' = 'default';
  @Input() useMarginInBody: boolean = false;

  calculatedHeight: number = 0;

  ngAfterViewInit() {
    if (this.cardHeight !== 'tall') return;

    setTimeout(() => {
      const cards = Array.from(document.getElementsByClassName(`image-card`));
      const tallestCard = cards.sort((a, b) => b.clientHeight - a.clientHeight)[0];
      if (cards && tallestCard.clientHeight) {
        // console.log('tallestCardHeight', tallestCard);
        this.calculatedHeight = tallestCard.clientHeight;
      }
    }, 1);
  }
}
