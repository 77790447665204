import { Component, Input } from '@angular/core';
import { ICarouselItem } from '../../models/common';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-quote-section',
  templateUrl: './quote-section.component.html',
  styleUrl: './quote-section.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class QuoteSectionComponent {

  @Input() slide?: ICarouselItem;
  @Input() quotationMarkColor: 'yellow' | 'green' = 'yellow';

}
