@if (source && source.url && openState()) {
    <div id="prom-video-embedder" class="video-embedder">
        <span class="icon-close" (click)="closeVideo()">
            <mat-icon aria-hidden="false" aria-label="Close icon" fontIcon="close" class="icon-size"></mat-icon>
        </span>
        <iframe class="video-embedder--video"
                [src]="source.url | safe: 'resourceUrl'"
                frameborder="0"
                allowfullscreen
                allowautoplay
                allow="autoplay; fullscreen"
                width="{{videoWidth}}" height="{{videoHeight}}"
                [ngStyle]="{'margin-top': iframeMarginTop}">
        </iframe>    
    </div>
}
