import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IPeopleGroupBios } from '../../models/common';

@Component({
  selector: 'app-people-accordion',
  templateUrl: './people-accordion.component.html',
  styleUrl: './people-accordion.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class PeopleAccordionComponent {
  @Input() list?: (IPeopleGroupBios & { isOpen?: boolean})[];
  @Input() theme: 'default' | 'sim-red' = 'default';
  @Input() bgColor: 'deep-blue' | 'white' = 'white';
  @Input() paddingSize: 'default' | 'small' = 'default';
}
