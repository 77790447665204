import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Location } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { PromAnimatedButtonComponent } from "../../components/prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { removeKebabCase, scrollToTop } from '../../services/utilities.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, RouterModule, MatInputModule, MatIconModule, MatButtonModule, PromAnimatedButtonComponent],
})
export class SearchPageComponent implements OnInit {

  get currentLang(): string {
    return this.translate.currentLang;
  }

  searchBoxInput = new FormControl('', [Validators.required, Validators.minLength(3)]);

  searchResults: {id: string,title: string, maxItemsToShow: number, increment: number, list: {
    slug: string,
    root?: string,
    baseRoute?: string,

    category?: string,
    title?: string,
    publicationDate?: Date,
  }[]}[] = [
    { id: 'all', title: 'All-site', list: [], maxItemsToShow: 8, increment: 8 },
    { id: 'insights', title: 'Insights', list: [], maxItemsToShow: 6, increment: 6 },
  ];

  get isAllEmpty() {
    console.log(this.searchResults, this.searchResults.every(r => r.list.length === 0));
    return this.searchResults.every(r => r.list.length === 0);
  }

  constructor(
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly api: ApiService,
    private readonly location: Location,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      // console.log("searching", params);
      const queryString = params['q'];
      this.searchBoxInput.setValue(queryString);
      this.loadSearchResults();
    });
  }

  loading: boolean = false;
  private async loadSearchResults() {
    const queryString = this.searchBoxInput.value;
    if (!queryString) return;

    this.loading = true;

    try {
      const [allSite, insights] = await Promise.all([
        this.api.searchResults.allSite(queryString),
        this.api.searchResults.insights(queryString, 0, this.searchResults.find(r => r.id === 'insights')!.increment + 1),
      ]);

      /** assign allSite results to searchResults and patch it for SaaS&Cloud sub-routes */
      this.searchResults[0].list = allSite.map(result => {
        if (result.baseRoute === 'solutions' && ['solutions-prometeia-aws', 'solutions-prometeia-microsoft-azure'].includes(result.slug)) {
          result.slug = 'saas-cloud/' + result.slug.replace('solutions-', '');
        }

        return result;
      });

      /** assign insights results to searchResults */
      this.searchResults[1].list = insights.items;

      /** replace (if any) "__" with "/" in slugs */
      this.searchResults.forEach(r => {
        r.list.forEach(item => {
          item.slug = item.slug.replaceAll('__', '/');
        });
      });
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loading = false;
      scrollToTop();
    }
  }

  submitSearch() {
    if (!this.searchBoxInput.valid) {
      console.error('Invalid search input');
      return;
    }

    this.router.navigate([`/${this.currentLang}/search`], { queryParams: { q: this.searchBoxInput.value }, replaceUrl: true });
  }

  removeKebabCase = removeKebabCase;

  onLoadMoreClick(id: string) {
    const r = this.searchResults.find(r => r.id === id);
    if (!r) return;

    if (id === 'all') {
      r.maxItemsToShow += r.increment;
      return;
    }

    if (r.list.length > r.maxItemsToShow) {
      /* with the previous API call we have more items than max (-> increment max) */
      r.maxItemsToShow += 6;
    } else {
      /* with the previous API call we don't have more items than max (-> new API call) */
      console.log("altro caso");

      if (id === 'insights') {
        this.api.searchResults.insights(this.searchBoxInput.value!, r.maxItemsToShow + 1, r.increment + 1).then((insights) => {
          r.list = r.list.concat(insights as any);
        });

        r.maxItemsToShow += r.increment;
      }
    }
  }

  goBack() {
    this.location.back();
  }

}
