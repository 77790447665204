import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { PageRoots } from '../../../models/pages';
import { IAboutUsPageOurTeam } from '../../../models/about-us';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IPersonBio } from '../../../models/common';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPersonBioComponent } from '../../../components/dialog-person-bio/dialog-person-bio.component';
import { VIDEO_SOURCES } from '../../../models/constants';
import { PromAnimatedButtonComponent } from '../../../components/prom-animated-button/prom-animated-button.component';

const ROOT_SLUG: PageRoots = 'about-us';

@Component({
  selector: 'app-our-team-page',
  templateUrl: './our-team-page.component.html',
  styleUrl: './our-team-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, PromAnimatedButtonComponent],
})
export class OurTeamPageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;
  readonly videoSrc = VIDEO_SOURCES.OUR_TEAM;

  override get slug() {
    return "our-team";
  }

  override pageData?: IAboutUsPageOurTeam;

  constructor(
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly api: ApiService,
    protected override readonly translate: TranslateService,
    private readonly dialog: MatDialog,
  ) {
    super(activatedRoute, api, translate);
  }

  override ngOnInit(): void {
    super.staticSlugInit();
  }

  override async loadPage() {
    if (!this.slug) return;

    this.loading = true;
    try {
      this.pageData = await this.api.pages.getBySlug(this.slug);
      this.loading = false;

      this.formatPage();
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    } finally {
      this.loading = false;
    }
  }

  onPersonClick(person: IPersonBio) {
    // console.log('onPersonClick', person);

    const dialogRef = this.dialog.open(DialogPersonBioComponent, {
      id: 'modal-dialog-person',

      minWidth: '750px',
      // maxWidth: '60%',
      
      // height: 'auto',
      // minHeight: '80%',
      // maxHeight: '100%',
      
      data: {bio: person},
    });
    // dialogRef.afterClosed().subscribe( async (res) => {});
  }
}
