import { deDupeAsyncFunction, assertTokenProviderConfig, decodeJWT } from '@aws-amplify/core/internals/utils';
import { initiateAuth } from './clients/CognitoIdentityProvider/index.mjs';
import { getRegion } from './clients/CognitoIdentityProvider/utils.mjs';
import { assertAuthTokensWithRefreshToken } from './types.mjs';
import { AuthError } from '../../../errors/AuthError.mjs';
import { getUserContextData } from './userContextData.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const refreshAuthTokensFunction = async ({
  tokens,
  authConfig,
  username
}) => {
  assertTokenProviderConfig(authConfig?.Cognito);
  const region = getRegion(authConfig.Cognito.userPoolId);
  assertAuthTokensWithRefreshToken(tokens);
  const refreshTokenString = tokens.refreshToken;
  const AuthParameters = {
    REFRESH_TOKEN: refreshTokenString
  };
  if (tokens.deviceMetadata?.deviceKey) {
    AuthParameters.DEVICE_KEY = tokens.deviceMetadata.deviceKey;
  }
  const UserContextData = getUserContextData({
    username,
    userPoolId: authConfig.Cognito.userPoolId,
    userPoolClientId: authConfig.Cognito.userPoolClientId
  });
  const {
    AuthenticationResult
  } = await initiateAuth({
    region
  }, {
    ClientId: authConfig?.Cognito?.userPoolClientId,
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    AuthParameters,
    UserContextData
  });
  const accessToken = decodeJWT(AuthenticationResult?.AccessToken ?? '');
  const idToken = AuthenticationResult?.IdToken ? decodeJWT(AuthenticationResult.IdToken) : undefined;
  const {
    iat
  } = accessToken.payload;
  // This should never happen. If it does, it's a bug from the service.
  if (!iat) {
    throw new AuthError({
      name: 'iatNotFoundException',
      message: 'iat not found in access token'
    });
  }
  const clockDrift = iat * 1000 - new Date().getTime();
  return {
    accessToken,
    idToken,
    clockDrift,
    refreshToken: refreshTokenString,
    username
  };
};
const refreshAuthTokens = deDupeAsyncFunction(refreshAuthTokensFunction);
const refreshAuthTokensWithoutDedupe = refreshAuthTokensFunction;
export { refreshAuthTokens, refreshAuthTokensWithoutDedupe };
