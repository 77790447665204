<div class="dialog-notice">
    @if (data) {
        <div class="content">
            <div class="icon-wrap">
                <mat-icon class="icon" fontIcon="check_circle"></mat-icon>
            </div>

            <p class="category">{{data.category}}</p>
            <p class="title">{{data.title}}</p>
        </div>
    }

    <div mat-dialog-actions class="buttons flex-row flex-row--centered">
        <app-prom-animated-button [theme]="'deep-blue'" [arrow]="'none'" (click)="onClose()">
            {{'CLOSE' | translate | uppercase}}
        </app-prom-animated-button>
    </div>
</div>
