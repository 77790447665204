import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';

import { RestClientService } from '../../services/rest-client.service';

import { IHeaderRow } from '../../models/header';
import { AmplifyAuthService } from '../../services/amplify-auth.service';
import { ArDialogAuthComponent } from '../ar-dialog-auth/ar-dialog-auth.component';
import { DialogComponent } from '../dialog/dialog.component';
import { HeaderDataService } from '../../services/header-data.service';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { MobileHeaderMenuComponent } from "../mobile-header-menu/mobile-header-menu.component";
import { PageRoots } from '../../models/pages';
import { scrollToTop } from '../../services/utilities.service';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { LocaleEnum, SUPPORTED_LANGS } from '../../models/common';
import { ContactModalComponent } from '../section-contact-us/contact-modal.component';
import { ApiService } from '../../services/api.service';

const ACTIVE_FIRST_LEVEL_BAR_ANIMATION_DELAY = 80;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule, MatButtonModule, MatIconModule, MatDialogModule, MatTooltipModule, MatInputModule, MatMenuModule, PromAnimatedButtonComponent, MobileHeaderMenuComponent, TranslateModule],
})
export class HeaderComponent extends ContactModalComponent implements OnInit, OnDestroy {

  expertisePageRoot: PageRoots = 'expertise';

  @Input() isTop: boolean = true;
  
  isLoggedAR : boolean = false;
  showLogout : boolean = false;

  /* private poolStatusSubscription?: Subscription | null = null;*/
  COOKIE_TOKEN_AR : string = 'prom-ar-token'; 

  _headerData?: IHeaderRow[];
  get headerData(): IHeaderRow[] | undefined {
    return this._headerData /* ?? this.activatedRoute.snapshot.data['headerData'] as IHeaderRow[] | undefined */;
  }

  activeFirstLevel?: string;

  routerEventsSubscription?: Subscription;

  isLanguagesOpen: boolean = false;

  constructor(
    protected override readonly dialog: MatDialog,
    protected override readonly translate: TranslateService,
    protected override readonly api: ApiService,
    protected override readonly headerDataService: HeaderDataService,
    private readonly restClient: RestClientService,
    private readonly amplifyService: AmplifyAuthService,
    private readonly router: Router,
    private readonly location: Location,
  ) {
    super(dialog, translate, api, headerDataService);
  }

  ngOnInit(): void {
    this.loadHeaderData();

    this.checkPoolStatus();

    this.routerEventsSubscription = this.router.events.subscribe((event: any) => {     
      if(event){
        this.checkPoolStatus();
      }
    });
  }

  ngOnDestroy(): void {
    this.checkPoolStatus();
    this.routerEventsSubscription?.unsubscribe();
  }

  async loadHeaderData() {
    if (!this.headerData) {
      this._headerData = await this.headerDataService.getFormattedHeaderData();
    }
  }

  getThirdLevelLabel(thirdLevel: IHeaderRow): string[] {
    // return thirdLevel.label.split(" & ").map((x, i) => (i !== 0 ? "& " : "") + x);
    return [thirdLevel.label];
  }


  get currentSitePage(): string {
    const urlArray = window.location.href.replace(window.location.origin, '').split('/');

    if (urlArray.length < 3) {
      if (!SUPPORTED_LANGS.includes(urlArray[1] as LocaleEnum)) {
        return urlArray[1];
      } else {
        return '';
      }
    }

    return urlArray[2].split("?")[0];
  }
  
  get currentFirstLevel(): string {
    if (this.activeFirstLevel) {
      return '';
    }
    return this.currentSitePage;
  }

  get secondLevel(): IHeaderRow[] {
    return this.headerData?.find((x: any) => x.slug === this.activeFirstLevel)?.children ?? [];
  }

  get showSecondLevel() {
    return !!this.secondLevel;
  }

  isActiveBarMovingRight: boolean = false;
  isActiveBarMovingLeft: boolean = false;
  previousActiveFirstLevel?: string;
  onMouseOverFirstLevel(code: string) {
    if (this.isSearchBoxOpen) {
      this.isSearchBoxOpen = false;
    }

    // console.log("enter", code);

    this.previousActiveFirstLevel = this.activeFirstLevel ? this.activeFirstLevel + "" : undefined;
    // const isMovingToOtherFirstLevel: boolean = this.previousActiveFirstLevel !== code;
    this.activeFirstLevel = code;

    if (!this.headerData?.find((x: any) => x.slug === code)?.children?.length) {
      this.onMouseExitFirstLevel();
      return;
    }

    const el: HTMLElement | null = document.getElementById(`header-item-${code}`);
    const bar: HTMLElement | null = document.getElementById(`animated-sliding-bar`);
    const secondLevel: HTMLElement | null = document.getElementById(`second-level-menu-block`);
    if (el && bar) {
      const elWidth = el.offsetWidth;

      if (!this.previousActiveFirstLevel?.length) {
        bar.style.left = `${el.offsetLeft + (elWidth / 2)}px`;
        bar.style.width = `0px`;
      }

      if (secondLevel) {
        secondLevel.style.left = `${el.getBoundingClientRect().left}px`;
      }

      setTimeout(() => {
        bar.style.left = `${el.offsetLeft}px`;
        bar.style.width = `${elWidth}px`;
      }, ACTIVE_FIRST_LEVEL_BAR_ANIMATION_DELAY);
    }
  }

  onMouseExitFirstLevel() {
    this.activeFirstLevel = undefined;
    this.isLanguagesOpen = false;
    
    const bar: HTMLElement | null = document.getElementById(`animated-sliding-bar`);
    if (bar) {
      bar.style.left = `${bar.offsetLeft + (bar.offsetWidth / 2)}px`;
      bar.style.width = `0px`;
    }
  }

  changeLanguage(newLocale?: string) {
    const loc = newLocale ?? (this.translate.currentLang === LocaleEnum.EN ? LocaleEnum.IT : LocaleEnum.EN);
    this.isLanguagesOpen = false;
    this.translate.use(loc);
    // this.translate.reloadLang(this.translate.currentLang);
    this.restClient.setupLocalization(newLocale);
    this.reloadCurrentRoute(loc);
  }

  reloadCurrentRoute(newLocale: string) {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.startsWith("/it/") ? currentUrl.replace("/it/", `/${newLocale}/`) : currentUrl.replace("/en/", `/${newLocale}/`);

    this.router.navigateByUrl(currentUrl);
  }

  clickOnThirdLevel() {
    this.activeFirstLevel = undefined;
    scrollToTop();
  }


  /** SEARCH */

  searchBoxInput = new FormControl('', [Validators.required, Validators.minLength(3)]);

  isSearchBoxOpen: boolean = false;
  isSearchBoxHidden: boolean = false;
  
  openSearchBox() {
    if (this.currentFirstLevel === 'search') {
      this.goBack();
      return;
    } else {
      this.activeFirstLevel = undefined;
    }

    this.isSearchBoxOpen = !this.isSearchBoxOpen;
    document.getElementById('search-input')?.focus();
  }

  closeSearchBox() {
    this.isSearchBoxOpen = false;
  }

  /** hide search-box immediately, than it closes it normally */
  private hideSearchBox() {
    this.isSearchBoxHidden = true;

    setTimeout(() => {
      this.isSearchBoxOpen = false;
      this.isSearchBoxHidden = false;
    }, 200);
  }

  submitSearch() {
    if (!this.searchBoxInput.valid) {
      console.error('Invalid search input');
      return;
    }

    this.router.navigate([`/${this.currentLang}/search`], { queryParams: { q: this.searchBoxInput.value } });
    this.hideSearchBox();
  }

  goBack() {
    this.location.back();
  }


  /** RESERVED AREA */

  private async checkPoolStatus() {
    this.isLoggedAR = await this.amplifyService.isLoggedAR(this.COOKIE_TOKEN_AR);
    this.showLogout = this.isLoggedAR && window.location.href.includes('reserved-area');    
  }

  async login() {    
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px',
      id: 'modal-ar-login'
    });
    dialogRef.afterClosed().subscribe( async () => {
      this.checkPoolStatus();
    })
  }

  async logout() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant('AR.LOGOUT_CONFIRM'), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      if(result){
        const r = await this.amplifyService.logout('ar');
        this.amplifyService.removeTokenAR(this.COOKIE_TOKEN_AR);
        this.router.navigate(['/']);
        this.checkPoolStatus();    
      }
      else{
        dialogRef.close();
      }
    });
  }  

  resevedArea(){
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate(['/', 'reserved-area']);
    else window.location.reload();
  }
}
