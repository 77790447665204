"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Creates a log message.
 * @private
 * @export
 * @param {string} [message='']
 * @returns {string}
 */
function log(message) {
  if (message === void 0) {
    message = '';
  }
  return "lodash-decorators -> " + message;
}
exports.log = log;
