<div class="page sim-archive {{slug}}-archive">
    <div class="page-content">
        <div class="archive-header">
            @if (baseRoute === 'sim-anteo') {
                <h3>Anteo</h3>
            }
            @if (baseRoute === 'sim-event') {
                <h3>{{pageData?.title ?? 'Pecorsi di InFormazione'}}</h3>
            }
        </div>

        @if (!loading && pageData) {
            <div class="archive accordion">
                @for (item of pageData.items; track $index) {
                    @if ($index < limit) {
                        <div class="accordion-item default-card-border" [ngClass]="{'open': item.isOpen, 'closed': item.isOpen === false}">
                            <div [ngClass]="{header: true, 'not-interactive': isAnteoLatestTemplate}" (click)="toggleItem(item, $index)">
                                <div>
                                    <p class="overtitle">
                                        @if (baseRoute === 'sim-anteo') {
                                            {{(item.pubDate | date: 'MMMM yyyy') | uppercase}}
                                        }
                                        @if (baseRoute === 'sim-event') {
                                            {{item.issueNumber}}
                                        }
                                    </p>
                                    <h4 class="title" [innerHTML]="item.title"></h4>
                                </div>
            
                                @if (!isAnteoLatestTemplate) {
                                    <button mat-icon-button class="accordion-toggle">
                                        @if (item.isOpen) {
                                            <img class="open-state-icon simplified-arrow-less" src="/assets/icons/down_simplified_arrow__blue.svg" alt="" />
                                        }
                                        @else {
                                            <img class="open-state-icon simplified-arrow-more" src="/assets/icons/down_simplified_arrow__blue.svg" alt="" />
                                        }
                                    </button>
                                }
                            </div>
            
                            <div class="content">
                                @if (loadingAnteoIssue) {
                                    <app-dots-loader></app-dots-loader>
                                    <br><br>
                                }

                                @if (baseRoute === 'sim-anteo' && item.issueContent) {
                                    @for (article of item.issueContent.sections; track article.id) {
                                        <div class="article interactive" [routerLink]="['/sim/' + currentLang + '/anteo/article', article.id]">
                                            <p class="title">{{article.title}}</p>
                                            <p class="author">{{article.author}}</p>
                                        </div>
                                    }
    
                                    <div class="download-button">
                                        <a href="{{item.issueContent.attachment}}" target="_blank" download>
                                            <app-prom-animated-button [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'down'">
                                                {{'download' | uppercase}}
                                            </app-prom-animated-button>
                                        </a>
                                    </div>
                                }
                                @else if (baseRoute === 'sim-anteo' && !loadingAnteoIssue) {
                                    <p>N/D</p>
                                }
    
                                @if (baseRoute === 'sim-event') {
                                    @if (item.body.trim().startsWith('<p>')) {
                                        <div class="body inner-html" data-aos="fade-up" [innerHTML]="item.body"></div>
                                    }
                                    @else {
                                        <div class="body inner-html"><p [innerHTML]="item.body"></p></div>
                                    }
                                }
                            </div>
                        </div>
                    }
                }

                @if (isAnteoLatestTemplate) {
                    <div class="load-more">
                        <app-prom-animated-button [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'right'" [routerLink]="['/sim/' + currentLang, 'archive', 'anteo']">
                            {{'ANTEO.ARCHIVE' | translate | uppercase}}
                        </app-prom-animated-button>
                    </div>
                }
                @else if (limit <= pageData.items.length) {
                    <div class="load-more">
                        <app-prom-animated-button [theme]="'deep-blue'" [bgColor]="'light'" (click)="onLoadMoreClick()">
                            {{'GENERAL.LOAD_MORE' | translate | uppercase}}
                        </app-prom-animated-button>
                    </div>
                }
            </div>
        }
        @else {
            <div class="archive empty">
                <p>{{'EMPTY_LISTS.EMPTY' | translate}}</p>
            </div>
        }

        <app-section-contact-us-sim></app-section-contact-us-sim>
    </div>
</div>