@if (block) {
    <section [ngStyle]="{'background-image': block.cover ? 'url(' + block.cover + ')' : 'none'}">
        <div class="flex-row section-content" data-aos="fade-up">
            <div class="infos">
                <h3 class="title" [innerHTML]="block.title"></h3>
                <div class="body" [innerHTML]="block.body"></div>
    
                @if (block.link) {
                    <div class="reserved-area-button">
                        @if (block.link.url) {
                            <a href="{{block.link.url}}" target="_blank">
                                <app-prom-animated-button [arrow]="'top-right'" [theme]="buttonTheme" [bgColor]="'dark'">
                                    {{block.link.label | uppercase}}
                                </app-prom-animated-button>
                            </a>
                        }
                        @else if (false && block.link.route && block.link.route === 'reserved-area') {
                            <!-- @if (showLogout) {
                                <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="logout()">
                                    {{'logout' | uppercase}}
                                </app-prom-animated-button>
                            }
                            @else if (!isLoggedAR && !showLogout) { -->
                            @if (!isLoggedAR && !showLogout) {
                                <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="login()">
                                    {{block.link.label | uppercase}}
                                </app-prom-animated-button>
                            }
                            @else {
                                <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="resevedArea()">
                                    <!-- {{'AR.TITLE' | translate | uppercase}} -->
                                    {{block.link.label | uppercase}}
                                </app-prom-animated-button>
                            }
                        }
                        @else if (block.link.route) {
                            <!-- <app-prom-animated-button [arrow]="'top-right'" [bgColor]="'dark'" [routerLink]="['/' + currentLang, block.link.route]" (click)="scrollToTop()">
                                {{block.link.label | uppercase}}
                            </app-prom-animated-button> -->
    
                            <a href="{{currentLang}}/{{block.link.route}}" target="_blank">
                                <app-prom-animated-button [arrow]="'top-right'" [bgColor]="'dark'">
                                    {{block.link.label | uppercase}}
                                </app-prom-animated-button>
                            </a>
                        }
                    </div>
                }
            </div>
    
            <div class="image-wrap">
                <img [src]="block.url">
            </div>
        </div>
    </section>
}
