<div [ngClass]="{'header': true, 'small': !isTop}" (mouseleave)="onMouseExitFirstLevel()">
    <div class="nav-bgc default-shadow"></div>

    <div class="header-content">
        <div class="logo-wrap">
            <a [routerLink]="['/' + currentLang]" (click)="onMouseExitFirstLevel()">
                <img class="logo" src="/assets/images/Prometeia50_Spa_NEG.png"/>
            </a>
        </div>
    
        @if (headerData) {
            <div class="navigation {{activeFirstLevel ?? 'no-first-level'}}-is-active">
                <nav class="navigation--container first-level">
                    <ul class="navigation--list">
                        @for (fl of headerData; track fl.slug) {
                            <li id="header-item-{{fl.slug}}" [ngClass]="{
                                'navigation--list--item first-level--list': true,
                                'btn': fl.slug === 'con',
                                'current': fl.slug === currentFirstLevel || fl.label.toLowerCase() === currentFirstLevel,
                                'active': fl.children?.length && fl.slug === activeFirstLevel,
                            }" (mouseenter)="onMouseOverFirstLevel(fl.slug)">
                                @if (fl.url) {
                                    @if (fl.url.includes('sim')) {
                                        <a class="first-level--label" href="{{fl.url}}/{{currentLang}}" target="_blank">
                                            {{fl.label | uppercase}}
                                            <!-- 🔗 -->
                                            <!-- ↗ -->
                                            <img class="external-link default" src="/assets/icons/external_link__white.svg" alt="">
                                            <img class="external-link hovering" src="/assets/icons/external_link__green.svg" alt="">
                                        </a>
                                    }
                                    @else {
                                        <a class="first-level--label" href="{{fl.url}}" target="_blank">
                                            {{fl.label | uppercase}}
                                            <!-- 🔗 -->
                                            <!-- ↗ -->
                                            <img class="external-link default" src="/assets/icons/external_link__white.svg" alt="">
                                            <img class="external-link hovering" src="/assets/icons/external_link__green.svg" alt="">
                                        </a>
                                    }
                                }
                                @else if (fl.slug && !fl.children?.length) {
                                    <a class="first-level--label" [routerLink]="['/' + currentLang, fl.slug]" (click)="activeFirstLevel = undefined;">
                                        {{fl.label | uppercase}}
                                    </a>
                                }
                                @else {
                                    <a class="first-level--label">
                                        {{fl.label | uppercase}}
                                    </a>
                                }
                            </li>
                        }
                        <li id="animated-sliding-bar" [ngClass]="{'sliding-bar': true, 'active': activeFirstLevel !== undefined}">&nbsp;</li>
                    </ul>
                </nav>
        
                <div [ngClass]="{'navigation--container second-level': true, 'active': secondLevel.length !== 0, 'default-shadow': secondLevel.length !== 0 && !isSearchBoxOpen && currentSitePage === 'search'}">
                    <div [ngClass]="{'second-level-content': true, 'single-second-level': secondLevel.length === 1}" id="second-level-menu-block">
                        @let lastExpertiseIndex = (secondLevel.length - 1);
                        @let lastSecondLevel = secondLevel[(secondLevel.length - 1)];
                        
                        @for (sl of secondLevel; track $index) {
                            @if (activeFirstLevel !== expertisePageRoot || $index < lastExpertiseIndex) {
                                <div class="second-level-column">
                                    @if (sl.label) {
                                        <p class="second-level--label" [innerHTML]="sl.label | uppercase"></p>
                                    }
    
                                    <ul class="second-level-navigation-list">
                                        @for (element of sl.children; track element.slug) {
                                            @if (element.slug) {
                                                <a [routerLink]="['/' + currentLang, activeFirstLevel, element.slug]" (click)="clickOnThirdLevel()">
                                                    <li class="third-level-wrap">
                                                        <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                        @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                            <span class="third-level--label">{{labelPiece}}</span>
                                                        }
                                                    </li>
                                                </a>
                                            }
                                            @else {
                                                <li class="third-level-wrap">
                                                    <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                    @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                        <a class="third-level--label">{{labelPiece}}</a>
                                                    }
                                                </li>
                                            }
                                        }
                                    </ul>

                                    <!-- if EXPERTISE is the active first level and it is the penultimate second level -->
                                    @if (activeFirstLevel === expertisePageRoot && $index == (lastExpertiseIndex - 1)) {
                                        @if (lastSecondLevel.label) {
                                            <p class="second-level--label" [innerHTML]="lastSecondLevel.label | uppercase"></p>
                                        }
        
                                        <ul class="second-level-navigation-list">
                                            @for (element of lastSecondLevel.children; track element.slug) {
                                                @if (element.slug) {
                                                    <a [routerLink]="['/' + currentLang, activeFirstLevel, element.slug]" (click)="clickOnThirdLevel()">
                                                        <li class="third-level-wrap">
                                                            <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                            @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                                <span class="third-level--label">{{labelPiece}}</span>
                                                            }
                                                        </li>
                                                    </a>
                                                }
                                                @else {
                                                    <li class="third-level-wrap">
                                                        <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                        @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                            <a class="third-level--label">{{labelPiece}}</a>
                                                        }
                                                    </li>
                                                }
                                            }
                                        </ul>
                                    }
                                </div>
                            }
                        }
                    </div>
                </div>

                <div [ngClass]="{'navigation--container second-level search-box': true, 'active': isSearchBoxOpen, 'hidden': isSearchBoxHidden}">
                    <div class="search-box-wrap">
                        <div class="search-box-spacer"></div>

                        <div class="search-box-input">
                            <mat-form-field class="search-box-input-element">
                                <input id="search-input" type="text" matInput [formControl]="searchBoxInput" (keyup.enter)="submitSearch()">
                            </mat-form-field>
                        </div>

                        <div class="search-box-close">
                            <button mat-icon-button (click)="closeSearchBox()">
                                <mat-icon aria-hidden="false" aria-label="close-search" fontIcon="close"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }

        <div class="quick-buttons">
            <div class="btn-container contact-us">
                <app-prom-animated-button [glowOnClick]="true" (click)="openContactDialog()">
                    {{'GENERAL.CONTACT_US' | translate | uppercase}}
                </app-prom-animated-button>
            </div>

            <!-- <div class="btn-container reseved-area">
                @if(showLogout){
                    <button mat-icon-button (click)="logout()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="logout"></mat-icon></button>
                }
                @else if(!isLoggedAR && !showLogout){
                    <button mat-icon-button (click)="login()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock"></mat-icon></button>
                }
                @else{
                    <button mat-icon-button (click)="resevedArea()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock_open"></mat-icon></button>
                }
            </div> -->
            
            <div [ngClass]="{'btn-container search-in-prometeia': true, 'active': currentFirstLevel === 'search'}">
                <button mat-icon-button (click)="openSearchBox()">
                    <mat-icon aria-hidden="false" aria-label="search" fontIcon="search"></mat-icon>
                </button>
            </div>

            <div class="btn-container change-language">
                <!-- <button mat-button class="menu-button" [matMenuTriggerFor]="localizationMenu" aria-label="Menu button for localization" style="color: white" (click)="isLanguagesOpen = true;">
                    {{'LOCALE' | translate | uppercase}}
                    @if (!isLanguagesOpen) {
                        <mat-icon iconPositionEnd>expand_more</mat-icon>
                    }
                    @else {
                        <mat-icon iconPositionEnd>expand_less</mat-icon>
                    }
                </button>
                <mat-menu #localizationMenu="matMenu" (closed)="isLanguagesOpen = false;">
                    <button mat-menu-item (click)="changeLanguage('it')">ITA</button>
                    <button mat-menu-item (click)="changeLanguage('en')">ENG</button>
                </mat-menu> -->

                <button mat-button class="menu-button" aria-label="Menu button for localization" style="color: white" (click)="isLanguagesOpen = !isLanguagesOpen;">
                    {{'LOCALE' | translate | uppercase}}
                    @if (!isLanguagesOpen) {
                        <mat-icon iconPositionEnd>expand_more</mat-icon>
                    }
                    @else {
                        <mat-icon iconPositionEnd>expand_less</mat-icon>
                    }
                </button>
                @if (isLanguagesOpen) {
                    <p class="next-lang" (click)="changeLanguage()">{{'NEXT_LOCALE' | translate | uppercase}}</p>
                }
            </div>
        </div>
    </div>
</div>

<div class="mobile-header default-shadow">
    <div class="header-content">
        <div class="mobile-navigation mobile-header-menu" style="display: flex;">
            <app-mobile-header-menu [data]="headerData" (openContactDialogEvent)="openContactDialog()"></app-mobile-header-menu>
        </div>

        <div class="logo-wrap">
            <a [routerLink]="['/' + currentLang]" (click)="onMouseExitFirstLevel()">
                <img class="logo" src="/assets/images/Prometeia50_Spa_NEG.png"/>
            </a>
        </div>

        <div class="mobile-navigation" style="display: flex;">
            <div [ngClass]="{'btn-container search-in-prometeia': true, 'active': currentFirstLevel === 'search'}">
                <button mat-icon-button (click)="openSearchBox()">
                    <mat-icon aria-hidden="false" aria-label="search" fontIcon="search"></mat-icon>
                </button>
            </div>

            <div class="btn-container change-language">
                <!-- <button mat-button class="menu-button" [matMenuTriggerFor]="localizationMenu" aria-label="Menu button for localization" style="color: white" (click)="isLanguagesOpen = true;">
                    {{'LOCALE' | translate | uppercase}}
                    @if (!isLanguagesOpen) {
                        <mat-icon iconPositionEnd>expand_more</mat-icon>
                    }
                    @else {
                        <mat-icon iconPositionEnd>expand_less</mat-icon>
                    }
                </button>
                <mat-menu #localizationMenu="matMenu" (closed)="isLanguagesOpen = false;">
                    <button mat-menu-item (click)="changeLanguage('it')">ITA</button>
                    <button mat-menu-item (click)="changeLanguage('en')">ENG</button>
                </mat-menu> -->

                <button mat-button class="menu-button" aria-label="Menu button for localization" style="color: white" (click)="isLanguagesOpen = !isLanguagesOpen;">
                    {{'LOCALE' | translate | uppercase}}
                    @if (!isLanguagesOpen) {
                        <mat-icon iconPositionEnd>expand_more</mat-icon>
                    }
                    @else {
                        <mat-icon iconPositionEnd>expand_less</mat-icon>
                    }
                </button>
                @if (isLanguagesOpen) {
                    <p class="next-lang" (click)="changeLanguage()">{{'NEXT_LOCALE' | translate | uppercase}}</p>
                }
            </div>
        </div>
    </div>
</div>
