@if (mediaSection) {
    <div class="media-list-section in-{{pageRoot}}">
        <div class="theme--default">
            <div id="media-list-section-item-0" class="media-images-list" data-aos="fade-up">
                <div class="row">
                    <div class="col">
                        <div class="text-container">
                            <h3>{{mediaSection.title}}</h3>
            
                            <div class="body" data-aos="fade-up">
                                <p [innerHTML]="mediaSection.body"></p>
                            </div>
                        </div>
                    </div>
    
                    <div class="col">
                        <div class="images-container">
                            @for (item of mediaSection.items; track $index) {
                                @if (item.url) {
                                    <a href="{{item.url}}" target="_blank">
                                        <div class="image-wrap interactive">
                                            <img src="{{item.image}}" alt="media_image">
                                        </div>        
                                    </a>
                                }
                                @else if (item.route) {
                                    <div class="image-wrap interactive" [routerLink]="[item.route]">
                                        <img src="{{item.image}}" alt="media_image">
                                    </div>
                                }
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
