import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { SectionContactUsSimComponent } from "../../../components/section-contact-us-sim/section-contact-us-sim.component";
import { IAnteoArticle } from '../../../models/sim';
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";

@Component({
  selector: 'app-sim-article',
  templateUrl: './sim-article.component.html',
  styleUrl: './sim-article.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, SectionContactUsSimComponent, PromAnimatedButtonComponent],
})
export class SimArticleComponent extends BaseSitePageComponent {

  get baseRoute() {
    if (window.location.href.includes('in-formation-paths/latest')) {
      return 'in-formation-paths';
    }

    return this.activatedRoute.snapshot.paramMap.get('baseRoute');
  }

  override get slug() {
    if (window.location.href.includes('in-formation-paths/latest')) {
      return 'in-formation-paths';
    }
    
    return this.activatedRoute.snapshot.paramMap.get('slug');
  }

  override pageData?: IAnteoArticle;

  get article() {
    return this.pageData?.news;
  }

  override ngOnInit(): void {
    if (!window.location.href.includes('in-formation-paths/latest')) {
      super.ngOnInit();
    } else {
      super.staticSlugInit();
    }
  }

  override async loadPage() {
    if (!this.slug) {
      return;
    }

    try {
      switch (this.baseRoute) {
        case 'anteo':
          this.pageData = (await this.api.simPages.anteoArticle(this.slug));
          break;
        case 'in-formation-paths':
          this.pageData = await this.api.simPages.get({ baseRoute: 'sim-event', slug: 'latest' });
          // this.pageData = inFormationPathsLatestMockData;
          break;
        default:
          this.pageData = undefined;
      }
    } catch (ex) {
      console.error(ex);
    }
  }

}

const inFormationPathsLatestMockData: IAnteoArticle = {
  title: 'InFormation Paths',
  news: {
    title: 'Su quale rotta sta navigando Argo: il mito della stabilità economica',
    subtitle: 'Dublino 2024',
    author: '',
    body: `<p>Una stella dalla luce incerta sembra guidare la navigazione delle banche centrali:
      il tasso di interesse naturale, cui le autorità guardano per ritrovare una stabilità
      monetaria dopo il lungo periodo di quantitative easing e la più recente e intensa
      fase di restrizione monetaria.</p>
      <p>Lʼincertezza nellʼindividuare punti di riferimento ai quali ancorare le aspettative
      degli operatori è dovuta allʼintreccio dei complessi processi di transizione
      destinati a segnare le traiettorie di crescita e di inflazione. Uno scenario in cui due
      importanti fattori - la rivoluzione dellʼAI e la mobilizzazione del risparmio privato –
      possono giocare un ruolo fondamentale per reperire risorse per la transizione
      climatica, per lʼaccentuarsi della crisi demografica, per gli effetti delle crisi
      geopolitiche e i crescenti investimenti richiesti dai sistemi di difesa.</p>
      <p>Alla tecnologia è affidato il recupero di produttività e la definizione delle
      caratteristiche e dei ritmi di un nuovo sviluppo industriale. Al risparmio privato il
      compito di affiancare sempre più il settore pubblico nel finanziamento
      dellʼinnovazione e della riconversione del sistema produttivo verso la sostenibilità
      ambientale.</p>
      <p>Con il XVIII percorso di inFormazione che si terrà a Dublino il 13-16 giugno,
      Prometeia Advisor Sim invita gli investitori e gli operatori finanziari a confrontarsi
      su analisi, strategie e strumenti finanziari che meglio possono individuare la rotta
      per affrontare le transizioni in atto. Facendo tesoro del monito di Seneca che “non
      esiste vento favorevole per il marinaio che non sa dove andareˮ</p>
      
      <br><br>

      <p><strong>Presentazioni Focus Group a cura degli Sponsor Platinum</strong></p>
      <p><a href="https://www.google.com" target="_blank">Anima</a></p>
      <p><a href="https://www.google.com" target="_blank">AXA</a></p>

      <br><br>

      <p><strong>Presentazioni Focus Group a cura degli Sponsor Gold</strong></p>
      <p><a href="https://www.google.com" target="_blank">BNP Paribas</a></p>
      <p><a href="https://www.google.com" target="_blank">Decalia</a></p>
      <p><a href="https://www.google.com" target="_blank">Eurazeo</a></p>
      <p><a href="https://www.google.com" target="_blank">Ver Capital</a></p>
      
      <br><br>

      <p><strong>Presentazioni Prometeia</strong></p>
      <p><a href="https://www.google.com" target="_blank">Intervento Lorenzo Forni</a></p>
      <p><a href="https://www.google.com" target="_blank">Intervento Lea Zicchino</a></p>`,
    category: '18° edizione',
    issueNumber: '1',
    related: [],
    id: 'latest',
    permalink: 'latest',
    pubDate: new Date()
  }
}