import { Component } from '@angular/core';
import { SectionContactUsComponent } from '../section-contact-us/section-contact-us.component';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-section-contact-us-sim',
  templateUrl: './section-contact-us-sim.component.html',
  styleUrl: './section-contact-us-sim.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, PromAnimatedButtonComponent],
})
export class SectionContactUsSimComponent extends SectionContactUsComponent {

  override theme: 'default' | 'yellow' | 'red' = "red";

  text1 = "Get in touch<br>with our team";
  text2 = "Subscribe to our<br>Anteo newsletter";

}
