import { Component, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { IHeaderRow } from '../../models/header';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LocaleEnum } from '../../models/common';
import { PromAnimatedButtonComponent } from '../prom-animated-button/prom-animated-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { AlumniAuthComponent } from "../alumni-auth/alumni-auth.component";
import { BurgerMenuComponent } from "../burger-menu/burger-menu.component";

@Component({
  selector: 'app-mobile-header-menu',
  templateUrl: './mobile-header-menu.component.html',
  styleUrl: './mobile-header-menu.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, MatIconModule, MatMenuModule, TranslateModule, PromAnimatedButtonComponent, BurgerMenuComponent],
})
export class MobileHeaderMenuComponent {
  @Input() data?: IHeaderRow[] = [];
  @Input() currentLang: LocaleEnum = LocaleEnum.IT;

  @Output() openContactDialogEvent = new EventEmitter<void>();

  activeFirstLevel?: IHeaderRow;
  activeSecondLevel?: IHeaderRow;
  firstLevelSlug?: string;

  onFirstLevelEnter(row: IHeaderRow) {
    // console.log('onFirstLevelEnter', row);

    this.firstLevelSlug = row.slug;

    if ((row.children?.length ?? 0) > 1) {
      this.activeFirstLevel = row;
    } else {
      /** skips the first level if it has only one child */
      this.activeFirstLevel = row.children?.[0];
    }
  }

  onSecondLevelEnter(row: IHeaderRow) {
    this.activeSecondLevel = row;
  }

  openContactDialogRequest() {
    this.openContactDialogEvent.emit();
  }

  isMenuOpen: WritableSignal<boolean> = signal(false);
  toggleMenu() {
    this.isMenuOpen.set(!this.isMenuOpen());
  }
}
