<div class="dialog-contact {{data?.useWhiteTheme ? 'theme--white' : 'theme--default'}}">
    @if (contactForm) {
        <form mat-dialog-content [formGroup]="contactForm">
            @if (data?.title) {
                <p class="dialog-contact-title">{{data!.title}}</p>
            }

            <div class="form-content row">
                @for (field of contactFormFields; track $index) {
                    <div class="field col-{{field.col}} {{getColumnOrientation($index)}}">
                        <p class="field--label">
                            @if (field.label) {
                                {{field.label | uppercase}}
                            }
                            @else {
                                {{'FORMS.' + (field.id | uppercase) | translate | uppercase}}
                            }

                            @if (isFieldRequired(field.id)) {
                                <span class="field--required">*</span>
                            }
                        </p>
                        <mat-form-field [ngClass]="{'dialog-contact-input-element': true, 'field-error': isFieldInvalid(field.id)}">
                            @if (field.dropdown?.length) {
                                <mat-select>
                                    @for (option of field.dropdown; track option.id) {
                                        <mat-option [value]="option.id" (click)="setFieldValue(field.id, option.id)">{{option.title}}</mat-option>
                                    }
                                </mat-select>
                            }
                            @else {
                                <input type="text" id="{{field.id}}" matInput formControlName="{{field.id}}">
                            }
                        </mat-form-field>
                    </div>
                }

                @if (!data?.list) {
                    <div class="field col-12">
                        <p class="field--label">
                            {{'FORMS.MESSAGE' | translate | uppercase}}
                        </p>
                        <mat-form-field class="dialog-contact-input-element dialog-contact-text-area">
                            <textarea id="message" matInput formControlName="message" rows="8"></textarea>
                        </mat-form-field>
                    </div>
                }
            </div>

            @if (data?.privacyPolicyLink) {
                <div class="form-content" style="overflow: hidden;">
                    <mat-checkbox [ngClass]="{'privacy-policy-checkbox': true, 'field-error': isFieldInvalid('privacyPolicyCheck')}" formControlName="privacyPolicyCheck">
                        {{'FORMS.PRIVACY_POLICY' | translate}}
                        <a href="{{data!.privacyPolicyLink}}" target="_blank">privacy policy</a>
                    </mat-checkbox>
                </div>
            }
        </form>
    }

    <div mat-dialog-actions class="buttons flex-row flex-row--space-between">
        <app-prom-animated-button [theme]="'deep-blue'" [arrow]="'none'" (click)="onNoClick()">
            {{'CANCEL' | translate | uppercase}}
        </app-prom-animated-button>

        @if(!canOnlyClose) {
            <app-prom-animated-button [theme]="'deep-blue'" [arrow]="'right'" (click)="onYesClick()">
                {{'SEND' | translate | uppercase}}
            </app-prom-animated-button>
        }
    </div>
</div>
