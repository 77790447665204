import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-long-arrow-down',
  templateUrl: './long-arrow-down.component.html',
  styleUrl: './long-arrow-down.component.scss',
  standalone: true,
  imports: [],
})
export class LongArrowDownComponent {
  @Input() theme: 'default' | 'fluo-yellow' | 'sim-red' | 'deep-blue' | 'white' = 'default';
}
