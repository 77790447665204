import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ISelector, ISitePageReference } from '../models/common';
import { IJobFilters, IJobOffer } from '../models/careers';
import { sleep } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class CareersService {

  private originalListFromApi?: { filters: IJobFilters; offers: IJobOffer[]; };
  private openPositions?: ISitePageReference[];
  private selectors: ISelector[] = [
    { name: 'Tribes', options: [] },
    { name: 'Famiglie Professionali', options: [] },
    { name: 'Location', options: [] },
  ];

  private citiesListFromApi?: string[];

  loading: boolean = false;

  constructor(
    private readonly apiService: ApiService,
  ) { }

  private async originalList(): Promise<{ filters: IJobFilters; offers: IJobOffer[]; } | undefined> {
    if (this.loading) {
      await sleep(1000);
    }
    
    if (this.originalListFromApi) {
      return this.originalListFromApi;
    }

    this.loading = true;

    try {
      const [res1, res2] = await Promise.all([
        this.apiService.careers.list(),
        this.apiService.pages.jobOffers(),
      ]);
      this.originalListFromApi = res1;
      this.citiesListFromApi = res2?.filters?.find(f => f.id === "offices")?.items.map(i => i.label);
      return res1;
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loading = false;
    }

    return undefined;
  }

  public async list(): Promise<ISitePageReference[]> {
    if (!this.openPositions || !this.originalListFromApi) {
      const list = await this.originalList();
      if (list?.offers?.length) {
        this.openPositions = this.jobOffersToSitePageReferences(list.offers);
      }
    }
    
    return this.openPositions!;
  }

  public async filters(): Promise<ISelector[]> {
    if (!this.originalListFromApi) {
      await this.originalList();
    }

    this.selectors[0].options = this.originalListFromApi?.filters?.areas || [];
    this.selectors[1].options = this.originalListFromApi?.filters?.categories || [];

    // const cities = new Map();
    // for (const item of this.originalListFromApi?.offers || []) {
    //   if (!cities.has(item.subtitle)) {
    //     cities.set(item.subtitle, true);
    //     this.selectors[2].options.push({ name: item.subtitle });
    //   }
    // }
    this.selectors[2].options = this.citiesListFromApi?.map((city) => ({ name: city })) || [];

    return this.selectors;
  }

  public async search(q: string): Promise<ISitePageReference[]> {
    try {
      const list = await this.apiService.careers.list({ q });
      
      return this.jobOffersToSitePageReferences(list.offers);
    } catch (ex) {
      console.error(ex);
    }

    return [];
  }

  private jobOffersToSitePageReferences(jobOffers: IJobOffer[]): ISitePageReference[] {
    const sitePageReferences: ISitePageReference[] = [];

    for (const item of jobOffers) {
      sitePageReferences.push({
        title: item.title,
        slug: item.id,

        jobArea: item.area,
        jobCategory: item.category,
        jobOffice: item.subtitle,
      });
    }

    return sitePageReferences;
  }
}
