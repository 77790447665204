@if (!loading && pageData) {
    <div class="page solutions">
        <!-- <div class="intro-background"></div> -->
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.category"></h2></div>
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                </div>

                <app-long-arrow-down></app-long-arrow-down>
            </section>

            @if (isAlternateSolutionsPage && pageData.intro) {
                <section class="extra-intro">
                    <div class="section-content">
                        @if (pageData.intro.title?.length) {
                            <h3>{{pageData.intro.title}}</h3>
                        }

                        @if (pageData.intro.body.trim().startsWith('<p>')) {
                            <div class="body" data-aos="fade-up" [innerHTML]="pageData.intro.body"></div>
                        }
                        @else {
                            <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.intro.body"></p></div>
                        }
                    </div>
                </section>
            }
            
            @if (pageData.body) {
                <section class="how-we-help">
                    <div class="section-content">
                        @if (isAlternateSolutionsPage) {
                            <h3>Why us</h3>
                        }
                        @else {
                            <h3>How We Help</h3>
                        }
    
                        @if (pageData.body.trim().startsWith('<p>')) {
                            <div class="body" data-aos="fade-up" [innerHTML]="pageData.body"></div>
                        }
                        @else {
                            <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.body"></p></div>
                        }
                        
                        @if (pageData.cards) {
                            <app-prom-cards-list [cards]="pageData.cards.items" [size]="isAlternateSolutionsPage ? 'half' : 'full'" [arrowColor]="'green'"></app-prom-cards-list>
                        }
                    </div>
                </section>
            }

            @if (pageData.mediaSection) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSection" data-aos="fade-up"></app-media-section>
                </section>
            }

            @if (pageData.expertise) {
                <section class="expertise">
                    <div class="section-content">
                        <h3>Our Expertise</h3>
                        <app-routes-to-pages [baseRoute]="'expertise'" [items]="pageData.expertise.items" [size]="'thirds'" [arrowColor]="'blue'"></app-routes-to-pages>
                    </div>
                </section>
            }

            @if (isAlternateSolutionsPage) {
                <app-section-contact-us [text]="'Want to know more?<br>Get in touch with our team!'"></app-section-contact-us>
            }
            @else {
                <app-section-contact-us></app-section-contact-us>
            }

            @if (pageData.news) {
                <app-section-news-headlines [news]="pageData.news.items" [bgColor]="isAlternateSolutionsPage ? 'white' : 'blue'" [currentLang]="currentLang"></app-section-news-headlines>
            }
        </div>
    </div>
}
