import { Component, OnInit } from '@angular/core';
import { ApiArService } from '../../services/api-ar.service'
import { ActivatedRoute, ParamMap, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IARDocumentFolder, paths } from '../../models/reservedArea';
import { AmplifyAuthService } from '../../services/amplify-auth.service';
import { ArDialogAuthComponent } from '../../components/ar-dialog-auth/ar-dialog-auth.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-reserved-area',
  templateUrl: './reserved-area.component.html',
  styleUrls: ['./reserved-area.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ]
})
export class ReservedAreaComponent implements OnInit {
  public loading = true;
  private library: any; // IARDocumentFolder;
  public currentFolder?: IARDocumentFolder;
  public breadcrumbs: IARDocumentFolder[] = [];
  private attachmentsCache = new Map<string, any>();
  route_ar: string = paths.reservedArea;
  private isLoggedAR?: boolean;
  TOKEN_AR: string = 'prom-ar-token';

  get currentLang(): string {
    return this.translate.currentLang;
  }

  showTopButtons: boolean = false;

  constructor(
    private apiArService: ApiArService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private dialog: MatDialog,
    private amplifyService: AmplifyAuthService,
    private cookieService: CookieService,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }


  ngOnInit() {
    this.init();
  }

  async init() {
    await this.checkPoolStatus();
    if (!this.isLoggedAR) {
      this.openDialoglogin();
    }

    setTimeout(async () => {
      this.library = await this.getLibrary();

      if (this.library) {
        this.route.paramMap.subscribe(async (params: ParamMap) => {
          this.currentFolder = this.library;
          this.breadcrumbs = [];

          for (const folder of (params.get('folder') || '').split('#')) {
            const currentFolder = this.currentFolder?.children?.find(c => c._id == folder);
            if (currentFolder) {
              this.currentFolder = currentFolder;
              this.breadcrumbs.push(currentFolder);
            }
          }
          if (this.currentFolder && this.currentFolder._id) {
            // console.log('attachments',this.currentFolder);
            this.currentFolder.attachments = await this.updateAttachments(this.currentFolder._id);
            // console.log(this.currentFolder);
          }
        });
      }
    }, 100);
  }

  private async checkPoolStatus() {
    this.isLoggedAR = await this.amplifyService.isLoggedAR(this.COOKIE_TOKEN_AR);  
  }


  async getLibrary() {
    try {
      return await this.apiArService.libraries.get('ar');
    }
    catch (ex: any) {
      console.error("Si è verificato un errore durante il caricamento dei documenti", ex);
      if (ex.status == '401') {
        //  this.componentsServices.setARnotLogged(true);
        this.openDialoglogin();
        this.cookieService.delete(this.TOKEN_AR);
      }
      else {
        this.openSnackBar(this.translate.instant('Attenzione, si è verificato un errore durante il caricamento dei dati'));
      }
      return false;
    }

  }

  async updateAttachments(folderId: string) {
    if (!this.attachmentsCache.has(folderId)) {
      this.loading = true;
      try {
        let attachments = await this.apiArService.libraries.getContent(folderId);
        this.attachmentsCache.set(folderId, attachments);
        return attachments;
      }
      catch (ex: any) {
        console.log("Si è verificato un errore durante il caricamento dei documenti", ex);
        if (ex.status == '401') {
          //  this.componentsServices.setARnotLogged(true);
          this.openDialoglogin();
          this.cookieService.delete(this.TOKEN_AR);
        }
        else {
          this.openSnackBar(this.translate.instant('Attenzione, si è verificato un errore durante il caricamento dei dati'));
        }

      }
      this.loading = false;
    }
    else {
      return this.attachmentsCache.get(folderId);
    }
  }

  getExtention(fileName: string) {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }


  async openDialoglogin() {
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px', 
      id: 'modal-ar-login'
    });
    dialogRef.afterClosed().subscribe(async () => {
      this.isLoggedAR = await this.amplifyService.isLoggedIn('ar');
      if (this.isLoggedAR) { this.loginAR(); }
    });
  }

  async loginAR() {
    try {
      const tokenCognito = await this.amplifyService.getToken('ar');
      const login = await this.apiArService.libraries.login(tokenCognito || '');
      if (login) {
        window.location.reload();
      }
    }
    catch (error) {
      this.openSnackBar(this.translate.instant('Attenzione, Account non attivo'));
      setTimeout(() => {
        this.amplifyService.logout('ar');
        console.log(error);
      }, 100);
    }
    finally {
      await this.amplifyService.logout('ar');
    }
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


  COOKIE_TOKEN_AR : string = 'prom-ar-token'; 
  async logout() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant('AR.LOGOUT_CONFIRM'), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      if(result){
        const r = await this.amplifyService.logout('ar');
        this.amplifyService.removeTokenAR(this.COOKIE_TOKEN_AR);
        this.router.navigate(['/']);
      }
      else{
        dialogRef.close();
      }
    });
  }
}
