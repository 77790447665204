import { Component } from '@angular/core';
import { ISitePageMediaSection } from '../../models/pages';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReservedAreaListItemComponent } from "./reserved-area-list-item/reserved-area-list-item.component";
import { RESERVED_AREAS_IT, RESERVED_AREAS_EN } from '../../models/constants';

@Component({
  selector: 'app-reserved-areas-list-page',
  templateUrl: './reserved-areas-list-page.component.html',
  styleUrl: './reserved-areas-list-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, ReservedAreaListItemComponent],
})
export class ReservedAreasListPageComponent {

  get currentLang(): string {
    return this.translate.currentLang;
  }

  /* pageData: {
    mediaSection: ISitePageMediaSection;
  } = {
    mediaSection: RESERVED_AREAS
  } */

  get pageData(): { mediaSection: ISitePageMediaSection } {
    if (this.currentLang === 'en') {
      return { mediaSection: RESERVED_AREAS_EN }
    } else {
      return { mediaSection: RESERVED_AREAS_IT }
    }
  }

  constructor(
    private translate: TranslateService,
  ) { }

}
