import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ISitePageFlippingCard } from '../../models/common';

@Component({
  selector: 'app-section-reports-list',
  templateUrl: './section-reports-list.component.html',
  styleUrl: './section-reports-list.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class SectionReportsListComponent {
  @Input() reports?: ISitePageFlippingCard[] = [];
  @Input() bgColor: string = 'white';
}
