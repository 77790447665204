import { AfterViewInit, Component, computed, Input, OnDestroy, OnInit, Signal, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IMediaSectionItem } from '../../models/common';
import { SafePipe } from '../../pipes/safe.pipe';
import { DEFAULT_VIDEO_WIDTH, DEFAULT_VIDEO_HEIGHT } from '../../models/constants';

@Component({
  selector: 'app-video-embedder',
  templateUrl: './video-embedder.component.html',
  styleUrls: ['./video-embedder.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, SafePipe],
})
export class VideoEmbedderComponent implements OnInit, AfterViewInit, OnDestroy {

  // safeUrl: SafeResourceUrl;
  @Input() source?: IMediaSectionItem;
  @Input() isOpenEvt?: WritableSignal<boolean>;
  openState: Signal<boolean> = computed(() => this.isOpenEvt != undefined ? (this.isOpenEvt() ?? false) : false);
  private isOpenEvtSubscription?: Subscription;
  private playVideoRequestSubscription?: Subscription;

  // videoWidth = 560;  //800   *1.4285714285714
  // videoHeight = 315; //450   *1.4285714285714
  iframeMarginTop = "15vh";

  browserType: string = "";
  deviceType: string = "";

  isViewInitialized: boolean = false;

  constructor(
    // private videoService: VideoService,
    // private componentsServices: ComponentsServices,
    // private browserServices: BrowserServices
  ) { }

  ngOnInit(): void {
    if (this.source && !this.openState) this.play(this.source);
  }

  ngAfterViewInit () {
    this.isViewInitialized = true;
  }

  ngOnDestroy() {
    if (this.isOpenEvtSubscription != null) {
      this.isOpenEvtSubscription.unsubscribe();
      this.isOpenEvtSubscription = undefined;
    }
    this.playVideoRequestSubscription?.unsubscribe();
  }
  
  get videoWidth() {
    if (!this.isViewInitialized) return DEFAULT_VIDEO_WIDTH;
    const clientWidth = document.getElementById(`prom-video-embedder`)?.clientWidth ?? DEFAULT_VIDEO_WIDTH;
    return clientWidth * 0.6;
  }

  get videoHeight() {
    if (!this.isViewInitialized) return DEFAULT_VIDEO_HEIGHT;
    const clientHeight = document.getElementById(`prom-video-embedder`)?.clientHeight ?? DEFAULT_VIDEO_HEIGHT;
    return Math.min(clientHeight, this.videoWidth * 9 / 16);
  }

  play(src: IMediaSectionItem) {
    this.source = src;
  }

  closeVideo() {
    this.isOpenEvt?.set(false);
  }

  openYouTube(videoID: string) {
    if (this.deviceType == 'iPhone') {
      window.location.href = "https://youtu.be/" + videoID;
    } else {
      /** target='_blank' is the default */
      window.open("https://youtu.be/" + videoID);
    }
  }

}
