import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Sanitize HTML
 */
@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform {
    /**
     * Pipe Constructor
     *
     * @param _sanitizer: DomSanitezer
     */
    // tslint:disable-next-line
    constructor(protected _sanitizer: DomSanitizer) {
    }

    /**
     * Transform
     *
     * @param value: string
     * @param type: string
     */
    transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        // console.log("pipe", type, value);

        const cookieEnabled = false;
        
        switch (type) {
            case 'html':
                let v = value;
                if (v && !cookieEnabled) {
                    v = v.replace(/<iframe\s+.*?\s+src=(".*?").*?<\/iframe>/, "<p>" + "CONTENT_NOT_AVAIBLE" + "</p>");
                }
                return this._sanitizer.bypassSecurityTrustHtml(v);
            case 'style':
                return this._sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this._sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this._sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                const x = this._sanitizer.sanitize(SecurityContext.URL, value);
                return x ? this._sanitizer.bypassSecurityTrustResourceUrl(x) : "";
            default:
                return this._sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}