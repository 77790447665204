<ul class="sim-links-list">
    @for (link of links; track $index) {
        <li>
            <div>
                <a [href]="link.url" target="_blank">
                    {{link.label}}
                </a>
                <img src="/assets/icons/external_link__red.svg" alt="" class="external-link">
            </div>
            <div class="underline"></div>
        </li>
    }
</ul>
