import { Component, inject, OnInit } from '@angular/core';
import { IPersonBio } from '../../models/common';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-dialog-person-bio',
  templateUrl: './dialog-person-bio.component.html',
  styleUrl: './dialog-person-bio.component.scss',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule],
})
export class DialogPersonBioComponent implements OnInit {

  data?: {bio: IPersonBio};

  constructor(
    public readonly dialogRef: MatDialogRef<DialogPersonBioComponent>
  ) {
    this.data = inject(MAT_DIALOG_DATA);
  }

  ngOnInit(): void {
    this.adjustBackPosition();
  }

  private adjustBackPosition(): void {
    const scrollTop = document.getElementsByClassName('page-container')[0].getBoundingClientRect().top;
    // console.log('disableScroll', scrollTop);
    window.scrollTo(0, scrollTop);
  }

}
