{
  "name": "prometeia-corporate-web-ui-2024",
  "version": "0.9.8",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration development --port=4201",
    "build": "ng build",
    "staging": "ng build --configuration staging",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.2.3",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/material": "^18.2.3",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/aos": "^3.0.7",
    "aos": "^2.3.4",
    "aws-amplify": "^6.6.0",
    "axios": "^1.7.7",
    "lodash": "^4.17.21",
    "lodash-decorators": "^6.0.1",
    "ngx-cookie-service": "^18.0.0",
    "ngx-owl-carousel-o": "^18.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.0",
    "@angular/cli": "^18.0.0",
    "@angular/compiler-cli": "^18.0.0",
    "@aws-amplify/ui-angular": "^5.0.23",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^22.5.4",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
