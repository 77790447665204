<div class="dialog"> 
  <h1 mat-dialog-title class="neutra2_book">{{data.title}}</h1>
  <div mat-dialog-content>
    <p class="neutra2_book">{{data.text}}</p> 
  </div>
  <div mat-dialog-actions class="dialog-wrap-btn">
    <button mat-button (click)="onNoClick()" class="prom-caption___lato-regular dialog-btn dialog-btn--close">
      {{'CANCEL' | translate | uppercase}}
    </button>
    @if(!data.onlyClose){
      <button mat-button [mat-dialog-close]="true"  cdkFocusInitial class="prom-caption___lato-regular dialog-btn dialog-btn--confirm">
        {{'CONFIRM' | translate | uppercase}}
      </button>   
    }
   
  </div>
</div>
