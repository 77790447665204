import { Component } from '@angular/core';
import { PageRoots } from '../../../models/pages';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { ISolutionsSubpage } from '../../../models/solutions';
import { CommonModule } from '@angular/common';
import { LongArrowDownComponent } from "../../../components/long-arrow-down/long-arrow-down.component";
import { MediaSectionComponent } from "../../../components/media-section/media-section.component";
import { SectionContactUsComponent } from "../../../components/section-contact-us/section-contact-us.component";
import { PromCardsListComponent } from "../../../components/prom-cards-list/prom-cards-list.component";

const ROOT_SLUG: PageRoots = 'solutions';

@Component({
  selector: 'app-solutions-subpage',
  templateUrl: './solutions-subpage.component.html',
  styleUrl: './solutions-subpage.component.scss',
  standalone: true,
  imports: [CommonModule, LongArrowDownComponent, MediaSectionComponent, SectionContactUsComponent, PromCardsListComponent],
})
export class SolutionsSubpageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;

  override async loadPage() {
    // console.log(ROOT_SLUG, "->", this.activatedRoute.snapshot.paramMap.get('slug'));

    try {
      this.pageData = await this.api.pages.getBySlug((ROOT_SLUG + '-' + this.slug) || '');
      
      this.formatPage();
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    }
  }
}
