@if (slide) {
    <div class="slide-content" [ngStyle]="{'background-image': 'url(' + slide.cover + ')'}">
        <div class="content-wrap">
            <div class="content">
                <h3>
                    <span class="text--{{quotationMarkColor}}">“</span>
                    <br>
                    {{slide.quote}}
                </h3>
    
                <h4 class="content--label-and-details"><span class="content--label">{{slide.label}}</span>, {{slide.details}}</h4>
            </div>
        </div>
    </div>
}
