@if (!loading && pageData) {
    <div class="page sim-page">
        <!-- <div class="intro-background"></div> -->
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.category"></h2></div>
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                </div>

                <app-long-arrow-down [theme]="'white'"></app-long-arrow-down>
            </section>

            @if (pageData.body) {
                <section class="page-info">
                    <div class="section-content">
                        @if (pageData.body.trim().startsWith('<p>')) {
                            <div class="body" data-aos="fade-up" [innerHTML]="pageData.body"></div>
                        }
                        @else {
                            <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.body"></p></div>
                        }

                        @if (pageData.cards) {
                            <app-prom-cards-list [cards]="pageData.cards.items" [theme]="'red'"></app-prom-cards-list>
                        }
                    </div>
                </section>
            }

            @if (pageData.mediaSection) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSection"></app-media-section>
                </section>
            }

            @if (pageData.consulence) {
                <section class="consulence">
                    <div class="section-content">
                        <h3>{{pageData.consulence.title}}</h3>
                        
                        @if (pageData.consulence.items.length) {
                            <app-routes-to-pages [baseRoute]="'sim/' + currentLang + '/page/'" [items]="pageData.consulence.items" [size]="'thirds'" [theme]="'red'" [arrowColor]="'blue'"></app-routes-to-pages>
                        }

                        @if (pageData.consulence.links.length) {
                            <div class="sim-links-list-container">
                                <app-links-list [links]="pageData.consulence.links"></app-links-list>
                            </div>
                        }
                    </div>
                </section>
            }

            <app-section-contact-us-sim></app-section-contact-us-sim>
        </div>
    </div>
}
