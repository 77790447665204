<div [ngClass]="{'selector-ship': true, 'open': activeTab >= 0, 'closed': activeTab < 0 && !isClosing, 'closing': isClosing}" class="theme--{{theme}}">
    <div class="header">
        <div class="names-wrap flex-row">
            @for (item of selectors; track $index) {
                <div [ngClass]="{'selector names': true, 'active': $index === activeTab, 'not-active': activeTab >= 0 && $index !== activeTab}" (click)="onTabClick($index)">
                    <h4 class="title">
                        {{item.name}}
                        @let selectedOptionsNumber = getSelectedOptionsNumberBySeletorName(item.name);
                        @if (selectedOptionsNumber > 0) {
                            <span class="badge">({{selectedOptionsNumber}})</span>                        
                        }
                    </h4>
    
                    @if ($index === activeTab) {
                        <!-- <mat-icon class="open-state-icon" fontIcon="expand_less"></mat-icon> -->
                        <img class="open-state-icon simplified-arrow-less" src="/assets/icons/down_simplified_arrow__{{theme === 'careers' ? 'yellow' : 'blue'}}.svg" alt="" />
                    }
                    @else {
                        <!-- <mat-icon class="open-state-icon" fontIcon="expand_more"></mat-icon> -->
                        <img class="open-state-icon simplified-arrow-more" src="/assets/icons/down_simplified_arrow__{{theme === 'careers' ? 'yellow' : 'blue'}}.svg" alt="" />
                    }
                </div>
            }
        </div>

        <div  [ngClass]="{'selector search': true, 'active': searchIconTabIndex === activeTab, 'not-active': activeTab >= 0 && searchIconTabIndex !== activeTab}" (click)="onTabClick(searchIconTabIndex)">
            <!-- <p>🔍</p> -->
            <mat-icon class="search-icon" fontIcon="search"></mat-icon>
        </div>
    </div>

    <div [ngClass]="{'body': true, 'active': activeTab >= 0, 'not-active': activeTab < 0}">
        @if (activeTab >= 0 && activeTab !== searchIconTabIndex) {
            @let options = getOptionsBySeletorName();
            @for (item of options; track $index) {
                <div [ngClass]="{'option': true, 'selected': item.selected}" (click)="onItemClick(item)">
                    <p>{{item.name}}</p>
                </div>
            }
            @empty {
                @if (activeTab >= 0) {
                    <div class="no-options">
                        <p>
                            {{'EMPTY_LISTS.NO_' + (selectors[activeTab].name | uppercase) | translate}}
                        </p>
                    </div>
                }
            }
        }
        @else if (activeTab === searchIconTabIndex) {
            <div class="{{theme}}-selector-search-box-input">
                <mat-form-field class="{{theme}}-selector-search-box-input-element on-light-bgc">
                    <input id="search-input" type="text" matInput [formControl]="searchBoxInput" (keyup.enter)="submitSearch()" [tabindex]="0">
                </mat-form-field>
            </div>
        }
    </div>
</div>
