@if (!loading && pageData) {
    <div class="page customers">
        <div class="page-content">
            <section class="intro">
                <div class="desktop-only section-content-image" [ngStyle]="{'background-image': 'url(' + pageData.heroImage + ')'}"></div>
                
                <div class="section-content" data-aos="fade-up">
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                </div>

                <!-- <div class="desktop-only section-content-colored-filter"></div> -->

                <app-long-arrow-down></app-long-arrow-down>
            </section>

            @if (pageData.mediaSection) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSection" data-aos="fade-up"></app-media-section>
                </section>
            }

            @if (pageData.cards) {
                <section class="links-section">
                    <div class="section-content">
                        @if (pageData.cards.title?.length) {
                            <h3>{{pageData.cards.title}}</h3>
                        }
                        <app-prom-cards-list [cards]="pageData.cards.items" [size]="'full'" [arrowColor]="'green'"></app-prom-cards-list>
                    </div>
                </section>
            }

            <app-section-contact-us [text]="'Request a demo'"></app-section-contact-us>
        </div>
    </div>
}
