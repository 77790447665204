import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HeaderSimComponent } from "../../components/header-sim/header-sim.component";
import { FooterSimComponent } from "../../components/footer-sim/footer-sim.component";

@Component({
  selector: 'app-sim-layout',
  templateUrl: './sim-layout.component.html',
  styleUrl: './sim-layout.component.scss',
  standalone: true,
  imports: [RouterOutlet, HeaderSimComponent, FooterSimComponent],
})
export class SimLayoutComponent {
  appVersion: string = environment.appVersion;

  
}
