import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from '../../services/cookie.service';
import { scrollToTop } from '../../services/utilities.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-privacy-and-cookies',
  templateUrl: './privacy-and-cookies.component.html',
  styleUrls: ['./privacy-and-cookies.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatIconModule],
})
export class PrivacyAndCookiesComponent implements OnInit {

  logo_scuro: string = "/assets/images/logo.png";

  isBannerVisible: boolean = true;
  isDetailVisible: boolean = false;

  bannerOpenStateClass: string = 'closed';

  cookieDetailOpenState: boolean[] = [false, false, false];
  /**
   * each item can be ->
   *  0: not setted |
   *  1: accepted |
   * -1: NOT accepted
   */
  cookiePreference: number[] = [1, 0, 0];

  constructor(
    public readonly translate: TranslateService,
    public readonly cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    const cookieSettingsLoaded = this.cookieService.isCookiesStored();
    // console.log("cookieSettingsLoaded", cookieSettingsLoaded);
    this.isBannerVisible = !cookieSettingsLoaded;
    this.isDetailVisible = false;
    
    setTimeout(() => {
      this.bannerOpenStateClass = 'open';
    }, 10);
  }

  accept(necessaryOnly: boolean = false) {
    this.isBannerVisible = false;
    if (necessaryOnly) {
      this.cookieService.setMainCookie();
    } else {
      this.setCookies();
    }
  }

  preferences() {
    scrollToTop();

    setTimeout(() => {
      this.isDetailVisible = true;
    }, 1);
  }

  send() {
    this.isBannerVisible = false;
    this.closeDetail();
    this.cookieService.removeCookiePreferences();
    this.setCookies();
  }

  private setCookies() {
    this.cookieService.setMainCookie();
    // console.log("this.cookiePreference 1", this.cookiePreference);
    if (!this.allCookiesAreSetted()) {
      this.cookiePreference = [1, 1, 1];
    }
    // console.log("this.cookiePreference 2", this.cookiePreference);
    this.cookieService.setCookiePreferences(this.cookiePreference);

    setTimeout(() => {
      window.location.reload();
    }, 10);
  }


  toggleCookieDetailOpenState(index: number): void {
    this.cookieDetailOpenState[index] = !this.cookieDetailOpenState[index];
    this.bannerOpenStateClass = 'close';
  }

  isCookieDetailOpen(index: number): boolean {
    return this.cookieDetailOpenState[index];
  }

  setCookiePreference(index: number, value: boolean): void {
    if (value) this.cookiePreference[index] = 1;
    else this.cookiePreference[index] = -1;
  }

  isCookieAccepted(index: number): number {
    return this.cookiePreference[index];
  }

  allCookiesAreSetted(): boolean {
    let res = 1;

    this.cookiePreference.forEach(item => {
      res = res * item;
    });

    if (res == 0) return false;
    return true;
  }


  closeDetail() {
    this.isDetailVisible = false;
    this.cookieService.forceReopenCookieModal = false;
  }

}
