import { Component } from '@angular/core';
import { PageRoots } from '../../../models/pages';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { CommonModule } from '@angular/common';
import { LongArrowDownComponent } from '../../../components/long-arrow-down/long-arrow-down.component';
import { IAboutUsPageOurWorld } from '../../../models/about-us';
import { IMediaSectionItem } from '../../../models/common';
import { MediaSectionComponent } from "../../../components/media-section/media-section.component";
import { PeopleAccordionComponent } from "../../../components/people-accordion/people-accordion.component";
import { MediaListSectionComponent } from "../../../components/media-list-section/media-list-section.component";
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { RouterModule } from '@angular/router';
import { ImageCardComponent } from "../../../components/image-card/image-card.component";
import { SectionContactUsComponent } from "../../../components/section-contact-us/section-contact-us.component";
import { VIDEO_SOURCES } from '../../../models/constants';

const ROOT_SLUG: PageRoots = 'about-us';

@Component({
  selector: 'app-our-world-page',
  templateUrl: './our-world-page.component.html',
  styleUrl: './our-world-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, LongArrowDownComponent, MediaSectionComponent, PeopleAccordionComponent, MediaListSectionComponent, PromAnimatedButtonComponent, ImageCardComponent, SectionContactUsComponent],
})
export class OurWorldPageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;
  readonly videoSrc = VIDEO_SOURCES.OUR_WORLD;

  override get slug() {
    return "our-world";
  }

  override pageData?: IAboutUsPageOurWorld;

  override ngOnInit(): void {
    super.staticSlugInit();
  }

  protected override formatPage(): void {
    super.formatPage();

    this.setThemes();
  }

  private setThemes() {
    let firstDeepBlue: boolean = false;
    this.pageData?.mediaSection?.items?.forEach((item: IMediaSectionItem, index: number) => {
      if (["full-section"].includes(item.type)) {
        item.theme = "white";
        item.subTheme = "reversed";
      } else if (!firstDeepBlue && !["image", "video"].includes(item.type)) {
        item.theme = "deep-blue";
        firstDeepBlue = true;
      }
    });
  }
}
