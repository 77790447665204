import { Component, Input } from '@angular/core';
import { ISitePageLink } from '../../models/common';

@Component({
  selector: 'app-links-list',
  templateUrl: './links-list.component.html',
  styleUrl: './links-list.component.scss',
  standalone: true,
  imports: [],
})
export class LinksListComponent {

  @Input() links: ISitePageLink[] = [];

}
