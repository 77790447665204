import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ISitePageCard } from '../../models/common';
import { PromCardComponent } from "../prom-card/prom-card.component";

@Component({
  selector: 'app-prom-cards-list',
  templateUrl: './prom-cards-list.component.html',
  styleUrl: './prom-cards-list.component.scss',
  standalone: true,
  imports: [PromCardComponent],
})
export class PromCardsListComponent implements OnInit, AfterViewInit {
  @Input() cards: ISitePageCard[] = [];
  @Input() size: 'full' | 'half' | 'third' = 'half';
  @Input() arrowColor: 'blue' | 'green' = 'blue';
  @Input() titleColor: 'default' | 'green' = 'default';
  @Input() theme: 'default' | 'yellow' | 'red' = 'default';

  /** used for pages with multiple prom-cards-list */
  @Input() sectionID?: string;

  private longestTextIndex: number = -1;
  private longestTextNumber: number = 0;

  /** used for debug */
  private longestTitle: string = '';

  calculatedHeight: number = 0;

  ngOnInit(): void {
    this.cards?.forEach((item, index) => {
      const textLen = (item.title.length * 2.7) + (item.body.length * 1.6);
      // console.log("textLen", textLen, item.title, "\n\t", item.body);
      if (textLen > this.longestTextNumber) {
        this.longestTitle = item.title;

        this.longestTextNumber = textLen;
        this.longestTextIndex = index;
      }
    });
  }
    
  ngAfterViewInit() {
    setTimeout(() => {
      const tallestCard = this.sectionID ? document.getElementById(`single-prom-card-${this.longestTextIndex}-${this.sectionID}`) : document.getElementById(`single-prom-card-${this.longestTextIndex}`) as HTMLElement | null;
      if (tallestCard) {
        // console.log("card's calculatedHeight:", tallestCard.clientHeight, this.longestTitle, "---", this.sectionID);
        
        if (this.size === 'third') {
          /* -80 to account for vertical padding (40px on top and 40px on bottom) */
          this.calculatedHeight = tallestCard.clientHeight - 80;
        } else if (this.size === 'half') {
          /* -120 to account for vertical padding (60px on top and 60px on bottom) */
          this.calculatedHeight = tallestCard.clientHeight - 120;
        } else {
          /** if size === 'full', then calculatedHeight will be ignored */
        }
      }
    }, 1);
  }
}
