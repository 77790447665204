// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var AmplifyErrorCode;
(function (AmplifyErrorCode) {
  AmplifyErrorCode["NoEndpointId"] = "NoEndpointId";
  AmplifyErrorCode["PlatformNotSupported"] = "PlatformNotSupported";
  AmplifyErrorCode["Unknown"] = "Unknown";
  AmplifyErrorCode["NetworkError"] = "NetworkError";
})(AmplifyErrorCode || (AmplifyErrorCode = {}));
export { AmplifyErrorCode };
