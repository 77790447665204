// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var AuthErrorTypes;
(function (AuthErrorTypes) {
  AuthErrorTypes["NoConfig"] = "noConfig";
  AuthErrorTypes["MissingAuthConfig"] = "missingAuthConfig";
  AuthErrorTypes["EmptyUsername"] = "emptyUsername";
  AuthErrorTypes["InvalidUsername"] = "invalidUsername";
  AuthErrorTypes["EmptyPassword"] = "emptyPassword";
  AuthErrorTypes["EmptyCode"] = "emptyCode";
  AuthErrorTypes["SignUpError"] = "signUpError";
  AuthErrorTypes["NoMFA"] = "noMFA";
  AuthErrorTypes["InvalidMFA"] = "invalidMFA";
  AuthErrorTypes["EmptyChallengeResponse"] = "emptyChallengeResponse";
  AuthErrorTypes["NoUserSession"] = "noUserSession";
  AuthErrorTypes["Default"] = "default";
  AuthErrorTypes["DeviceConfig"] = "deviceConfig";
  AuthErrorTypes["NetworkError"] = "networkError";
  AuthErrorTypes["AutoSignInError"] = "autoSignInError";
  AuthErrorTypes["OAuthSignInError"] = "oauthSignInError";
})(AuthErrorTypes || (AuthErrorTypes = {}));
export { AuthErrorTypes };
