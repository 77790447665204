import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IMediaListSectionItem, INewsReference, LocaleEnum } from '../../models/common';
import { PageRoots } from '../../models/pages';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-media-list-section',
  templateUrl: './media-list-section.component.html',
  styleUrl: './media-list-section.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class MediaListSectionComponent {

  @Input() mediaSection?: IMediaListSectionItem;
  @Input() pageRoot?: PageRoots;
  @Input() insightList?: INewsReference[];
  @Input() currentLang: string = LocaleEnum.IT;
  
}
