import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { INewslettersItem } from '../../models/common';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { MatDialog } from '@angular/material/dialog';
import { DialogContactComponent } from '../dialog-contact/dialog-contact.component';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogNoticeComponent } from '../dialog-notice/dialog-notice.component';

enum NewslettersSlugs {
  Anteo = 'ANTEO',
  RiskNews = 'RISK_NEWS',
  TrendingTopics = 'TRENDING_TOPICS',
  PromNext = 'PROM_NEXT'
}

interface NewsletterSubscriptionForm {
  "email": string | null,
  "firstName": string | null,
  "lastName": string | null,
  "company"?: string | null,
  "companyCountry"?: string | null,
  "country"?: string | null,
  "job"?: string | null,  //position
  "jobCategory"?: string | null,
  "jobCategory_other"?: string | null,
}

@Component({
  selector: 'app-newsletters',
  templateUrl: './newsletters.component.html',
  styleUrl: './newsletters.component.scss',
  standalone: true,
  imports: [CommonModule, MatIconModule, PromAnimatedButtonComponent],
})
export class NewslettersComponent {
  @Input() newsletters?: INewslettersItem[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly api: ApiService,
    private readonly translate: TranslateService,
  ) { }

  async openNewsletterDialog(newsletter: INewslettersItem) {
    const newsletterData = await this.loadNewsletterForm(newsletter);
    if (!newsletterData || !newsletterData.slug) {
      return;
    }

    const dialogRef = this.dialog.open(DialogContactComponent, {
      id: 'modal-dialog-contact',

      minWidth: '750px',
      // maxWidth: '60%',
      
      height: 'auto',
      minHeight: '80%',
      maxHeight: '100%',
      
      data: {/* title: `Newsletter - ${newsletterData.title}`, */ list: newsletterData.sections, privacyPolicyLink: `/${this.translate.currentLang}/article/privacy-policy`},
    });
    dialogRef.afterClosed().subscribe( async (res) => {
      if (res) {
        // console.log("from form:", res);

        this.subscribeToNewsletter(newsletter.slug, convertFormData(res));
      }
    });
  }


  private async loadNewsletterForm(newsletter: INewslettersItem) {
    // newsletter-form-fields__risk_news / newsletter-form-fields__trending_topics / newsletter-form-fields__anteo

    try {
      const res = await this.api.newsletters.get(newsletter.slug.replaceAll('-', '_'));

      /** rename position field */
      const positionField = res.sections.find((field: {id: string, title: string}) => field.id === 'position');
      if (positionField) {
        positionField.title = this.translate.instant('FORMS.POSITION');
      }

      /** move e-mail field after first name and last name fields */
      const emailFieldIndex: number = res.sections.findIndex((field: {id: string, title: string}) => field.id === 'email' || field.id === 'e-mail');
      if (emailFieldIndex !== -1) {
        const emailField = res.sections[emailFieldIndex];
        res.sections.splice(emailFieldIndex, 1);
        res.sections.splice(2, 0, emailField);
      }

      /** sets required fields */
      const defaultRequiredFields: string[] = ["name", "firstName", "lastName", "email", "e-mail"];
      const anteoRequiredFields: string[] = ["name", "firstName", "lastName", "email", "e-mail", "company", "positionCategory", "positionCategory_other"];
      const requiredFields: string[] = newsletter.slug === 'anteo' ? anteoRequiredFields : defaultRequiredFields;
      res.sections.forEach((field: {id: string, title: string, minValueLen: number, isRequired?: boolean}) => {
        field.isRequired = requiredFields.includes(field.id);
        // console.log(field);
      });

      return res;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  private newsletterSlugToPostSlug(slug: string) {
    switch (slug) {
      case "anteo":
        return NewslettersSlugs.Anteo;
      case "prom-next":
        return NewslettersSlugs.PromNext;
      case "risk-news":
        return NewslettersSlugs.RiskNews;
      case "trending-topics":
        return NewslettersSlugs.TrendingTopics;
      default:
        return slug;
    }
  }

  private async subscribeToNewsletter(slug: string, formData: NewsletterSubscriptionForm) {
    const subscription: string = JSON.stringify(formData);
    try {
      const resp = await this.api.newsletters.post(this.newsletterSlugToPostSlug(slug), subscription);
      // console.log("request will be:", `/newsletters/subscribe/${this.newsletterSlugToPostSlug(slug)}`, subscription);

      // if (resp?.status == 200 || resp?.status == "200 OK" || resp?.status == "OK") {
        this.dialog.open(DialogNoticeComponent, {
          id: 'modal-dialog-notice',
          
          data: {
            category: this.translate.instant('FORMS.NEWSLETTER_SUBSCRIBED'),
            title: "",
          },
        });
      // }
    } catch (ex: any) {
      const err = ex?.error?.error;
      // console.log("catched", err);

      if (err?.code && err.code == 3) {
        console.error("already registered");
      } else if (err?.code && err.code == 2) {
        console.error("double check the fields");
      } else /* if (err.status == 500) */ {
        console.error("try again later");
      }
    }
  }
}

function convertFormData(formData: any): NewsletterSubscriptionForm {
  const data: NewsletterSubscriptionForm = {
    firstName: formData['name'],
    lastName: formData.lastName,
    email: formData['e-mail'],

    company: formData.company,
    companyCountry: formData.companyCountry,
    country: formData.country,
    job: formData['position'],
    jobCategory: formData['positionCategory'],
    jobCategory_other: formData['positionCategory_other'],
  };

  return data;
}
