import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../base-site-page.component';
import { ISitePage } from '../../models/pages';
import { PromAnimatedButtonComponent } from "../../components/prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafePipe } from '../../pipes/safe.pipe';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrl: './article-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, SafePipe, PromAnimatedButtonComponent],
})
export class ArticlePageComponent extends BaseSitePageComponent {

  readonly categoryCssClass: string = "general-article";

  override pageData?: ISitePage & { body: string; attachment?: string; };

  private routerSubscription?: Subscription;

  get pageButton() {
    switch (this.slug) {
      case 'ethics':
        return {
          label: {
            en: 'Model 231 - General Part',
            it: 'Modello 231 - Parte Generale',
          },
          attachment: '/cdn2/site/general/Modello 231 - Parte Generale.pdf',
        };

      default:
        return undefined;
    }
  }

  constructor(
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly api: ApiService,
    protected override readonly translate: TranslateService,
    private readonly router: Router,
    private readonly cookieService: CookieService,
  ) {
    super(activatedRoute, api, translate);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(event.urlAfterRedirects.includes("#")) {
          setTimeout(() => {
            window.scroll(0, window.scrollY - 200);
          }, 1);
        }
      }
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routerSubscription?.unsubscribe();
  }

  override async loadPage() {
    if (!this.slug) {
      return;
    }

    this.loading = true;
    try {
      this.pageData = await this.api.pages.getBySlug(this.slug);
      this.loading = false;

      this.formatPage();
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    } finally {
      this.loading = false;
    }
  }


  /** ARTICLE__COOKIE */
  reopenCookiesSettings() {
    setTimeout(() => {
      this.cookieService.forceReopenCookieModal = true;
    }, 1);
  }

  notDefinedYet() {
    window.alert("Funzione o metodo non ancora definito");
  }
}
