<div [ngClass]="{'header sim-header': true}">
    <div class="nav-bgc default-shadow"></div>

    <div class="header-content">
        <div class="logo-wrap">
            <img class="logo" src="/assets/images/Prometeia50_Sim_NEG.png" [routerLink]="['/sim/' + currentLang]"/>
        </div>

        <div class="quick-buttons">
            <nav class="navigation--container first-level">
                <a class="first-level--label" href="/" target="_blank">
                    {{'Prometeia spa' | uppercase}}
                    <img class="external-link default" src="/assets/icons/external_link__white.svg" alt="">
                    <img class="external-link hovering" src="/assets/icons/external_link__red.svg" alt="">
                </a>
            </nav>

            <!-- <div class="btn-container reseved-area">
                @if(showLogout){
                    <button mat-icon-button (click)="logout()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="logout"></mat-icon></button>
                }
                @else if(!isLoggedAR && !showLogout){
                    <button mat-icon-button (click)="login()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock"></mat-icon></button>
                }
                @else{
                    <button mat-icon-button (click)="resevedArea()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock_open"></mat-icon></button>
                }
            </div> -->

            <div class="btn-container reserved-area">
                <a [href]="arURL" target="_blank">
                    <app-prom-animated-button [arrow]="'top-right'" [bgColor]="'dark'" [theme]="'sim-red'">
                        {{'AR.TITLE' | translate | uppercase}}
                    </app-prom-animated-button>
                </a>
            </div>

            <div class="btn-container change-language">
                <button mat-button class="menu-button" aria-label="Menu button for localization" style="color: white" (click)="isLanguagesOpen = !isLanguagesOpen;">
                    {{'LOCALE' | translate | uppercase}}
                    @if (!isLanguagesOpen) {
                        <mat-icon iconPositionEnd>expand_more</mat-icon>
                    }
                    @else {
                        <mat-icon iconPositionEnd>expand_less</mat-icon>
                    }
                </button>
                @if (isLanguagesOpen) {
                    <p class="next-lang" (click)="changeLanguage()">{{'NEXT_LOCALE' | translate | uppercase}}</p>
                }
            </div>
        </div>
    </div>
</div>

<div class="mobile-header default-shadow">
    <div class="header-content">
        <div class="logo-wrap">
            <img class="logo" src="/assets/images/Prometeia50_Sim_NEG.png" [routerLink]="['/sim/' + currentLang]"/>
        </div>

        <div class="mobile-navigation" style="display: flex;">
            <!-- <div class="btn-container reseved-area" style="margin-top: -7px;">
                @if(showLogout){
                    <button mat-icon-button (click)="logout()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="logout"></mat-icon></button>
                }
                @else if(!isLoggedAR && !showLogout){
                    <button mat-icon-button (click)="login()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock"></mat-icon></button>
                }
                @else{
                    <button mat-icon-button (click)="resevedArea()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="lock_open"></mat-icon></button>
                }
            </div> -->

            <!-- <app-mobile-header-menu [data]="headerData"></app-mobile-header-menu> -->

            <div class="btn-container change-language">
                <button mat-button class="menu-button" aria-label="Menu button for localization" style="color: white" (click)="isLanguagesOpen = !isLanguagesOpen;">
                    {{'LOCALE' | translate | uppercase}}
                    @if (!isLanguagesOpen) {
                        <mat-icon iconPositionEnd>expand_more</mat-icon>
                    }
                    @else {
                        <mat-icon iconPositionEnd>expand_less</mat-icon>
                    }
                </button>
                @if (isLanguagesOpen) {
                    <p class="next-lang" (click)="changeLanguage()">{{'NEXT_LOCALE' | translate | uppercase}}</p>
                }
            </div>
        </div>
    </div>
</div>
