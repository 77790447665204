import { createAssertionFunction } from '@aws-amplify/core/internals/utils';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var TokenProviderErrorCode;
(function (TokenProviderErrorCode) {
  TokenProviderErrorCode["InvalidAuthTokens"] = "InvalidAuthTokens";
})(TokenProviderErrorCode || (TokenProviderErrorCode = {}));
const tokenValidationErrorMap = {
  [TokenProviderErrorCode.InvalidAuthTokens]: {
    message: 'Invalid tokens.',
    recoverySuggestion: 'Make sure the tokens are valid.'
  }
};
const assert = createAssertionFunction(tokenValidationErrorMap);
export { TokenProviderErrorCode, assert };
