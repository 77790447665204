import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { LongArrowDownComponent } from "../../../components/long-arrow-down/long-arrow-down.component";
import { scrollToTop } from '../../../services/utilities.service';
import { ISimHomePage } from '../../../models/sim';
import { IMediaSectionItem, LocaleEnum } from '../../../models/common';
import { RoutesToPagesComponent } from "../../../components/routes-to-pages/routes-to-pages.component";
import { MediaSectionComponent } from "../../../components/media-section/media-section.component";
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SectionContactUsComponent } from "../../../components/section-contact-us/section-contact-us.component";
import { PeopleAccordionComponent } from '../../../components/people-accordion/people-accordion.component';
import { ImageCardComponent } from "../../../components/image-card/image-card.component";
import { ReservedAreaListItemComponent } from "../../reserved-areas-list-page/reserved-area-list-item/reserved-area-list-item.component";
import { RESERVED_AREAS_EN, RESERVED_AREAS_IT, VIDEO_SOURCES } from '../../../models/constants';
import { LinksListComponent } from "../../../components/links-list/links-list.component";

const BASE_ROUTE: 'sim' = 'sim';

@Component({
  selector: 'app-sim-home-page',
  templateUrl: './sim-home-page.component.html',
  styleUrl: './sim-home-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, LongArrowDownComponent, RoutesToPagesComponent, MediaSectionComponent, PromAnimatedButtonComponent, SectionContactUsComponent, PeopleAccordionComponent, ImageCardComponent, ReservedAreaListItemComponent, LinksListComponent],
})
export class SimHomePageComponent extends BaseSitePageComponent {
  override readonly pageRoot: any = BASE_ROUTE;
  readonly videoSrc = VIDEO_SOURCES.SIM_HOME;

  override get slug() {
    return "sim-home";
  }

  get reserveAreaBlock(): IMediaSectionItem {
    if (this.currentLang === 'en') {
      return RESERVED_AREAS_EN.items[4];
    } else {
      return RESERVED_AREAS_IT.items[4];
    }
  }

  latestInFormationPath?: {
    edition: string;
    title: string;
    subtitle: string;
  };

  override ngOnInit(): void {
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.loadPage();
      this.loadLatestInFormationPath();
      scrollToTop();

      if (paramMap.has('lang')) {
        const lang = paramMap.get('lang');
        if (lang) {
          this.translate.use(lang);
        }
      }
    });
  }

  override async loadPage() {
    // console.log(BASE_ROUTE, "->", this.slug);

    this.pageData = SIM_HOME__MOCK_DATA;
    this.pageData.mediaSection.items[0].theme = 'sim-red';

    // try {
    //   this.pageData = await this.api.simPages.get({
    //     baseRoute: this.pageRoot,
    //     slug: this.slug || ''
    //   });

    //   this.formatPage();
    // } catch (ex: any) {
    //   console.error(ex);
    //   this.pageData = undefined;
    //   this.pageDataErrorCode = ex.status;
    // }
  }

  async loadLatestInFormationPath() {
    this.latestInFormationPath = {
      edition: "18° edizione",
      title: "Su quale rotta sta navigando Argo: il mito della stabilità economica",
      subtitle: "Dublino 2024",
    };
  }
}

const SIM_HOME__MOCK_DATA: ISimHomePage = {
  root: "careers", // fake
  baseRoute: "sim",
  locale: LocaleEnum.IT,
  slug: "sim-home",
  title: `Prometeia Advisor Sim
          è il consulente finanziario
          leader sul mercato
          istituzionale italiano.`,
  subtitle: "",

  anteo: {
    title: "Anteo",
    body: `Anteo è la newsletter di Prometeia che dal 2003 è
          dedicata al mercato istituzionale italiano. Anteo,
          redatto bimestralmente, ha lʼobiettivo di far
          circolare maggiore informazione, far parlare i
          protagonisti del mercato, creare un dialogo
          sempre più diretto tra investitori istituzionali e
          intermediari finanziari. Differenti sono le sezioni
          della newsletter: oltre ad articoli sullʼandamento e
          la previsione dei mercati economico e finanziari,
          tematiche di asset allocation e novità normative,
          ospitiamo interviste realizzate a rappresentanti
          degli investitori istituzionali, contributi forniti dalle
          società di gestione e approfondimenti tratti dal
          Rapporto di Previsione di Prometeia.`,
    imageUrl: "/cdn2/site/anteo/tmp_ANTEO.png",
    link: { // ignored
      label: "Edizioni passate",
      route: "archive/anteo"
    },
  },

  consulence: {
    title: "I nostri servizi di consulenza",
    items: [
      { title: "Performance & Risk Analysis", slug: "performance-and-risk-analysis" },
      { title: "Asset Allocation Strategica", slug: "strategic-asset-allocation" },
      { title: "Asset Allocation Tattica", slug: "tactic-asset-allocation" },
      { title: "ALM", slug: "alm" },
      { title: "Selezione gestori e prodotti gestiti", slug: "selection-of-managers-and-managed-products" },
      { title: "Risk Management", slug: "risk-management" },
      { title: "Investment Process Engineering", slug: "investment-process-engineering" },
      { title: "RTO e Mediazione", slug: "rto-and-mediation" },
      { title: "Sostenibilità", slug: "sustainability" },
    ],
    links: [
      {
        label: "Arbitro per le controversie finanziarie",
        url: "/it/sim-article/ombudsman"
      }
    ]
  },

  mediaSection: {
    items: [
      {
        type: "text",
        size: "full",
        title: "Learn more about Prometeia Advisor Sim",
        download: {
          label: "Company Profile",
          url: "/cdn2/upload/2024/9/ab1dc25d-b8c0-479c-a346-2f616f0533b1.pdf"
        },
      }
    ]
  },

  peopleSection: {
    title: "Organi societari",
    body: "Per la propria attività Prometeia Advisor Sim si avvale dei seguenti organi: consiglio di amministrazione, collegio sindacale, società di revisione, responsabile per  a gestione del rischio, responsabile del controllo della conformità alle norme, responsabile antiriciclaggio, Organismo di Vigilanza, Data Protection Officer.",
    items: [
      {
        title: "Consiglio di amministrazione",
        items: [
          {
            name: "Andrea Landi",
            position: "Presidente",
            avatar: "https://www.prometeia.3logic.it/cdn/financial_advisory/societary_organs/landi.png",
            body: "Presidente di Prometeia Advisor Sim dal 2019, consigliere di amministrazione dal 2016. Professore Ordinario di Economia degli intermediari finanziari presso il Dipartimento di Economia “Marco Biagiˮ dell'Università di Modena e Reggio Emilia. È membro, tra gli altri, del comitato scientifico di Prometeia Associazione e dellʼassociazione internazionale di professori di Banking and Finance. Laureato in Economia presso l'Università degli Studi di Modena, in passato ha ricoperto, tra le altre, le cariche di Preside della Facoltà di Economia dell'Università di Modena e Reggio Emilia, Presidente della Fondazione Cassa di Risparmio di Modena, Presidente della Commissione ricerca dellʼAssociazione delle Fondazioni e Casse di Risparmio italiane. È stato componente del Collegio sindacale della Cassa Depositi e Prestiti. È membro del comitato scientifico della rivista “Banca Impresa e Societàˮ."
          },
          {
            name: "Paolo Onofri",
            position: "VicePresidente",
            avatar: "https://www.prometeia.3logic.it/cdn/financial_advisory/societary_organs/onofri.png",
            body: "Fondatore e Presidente di Prometeia Associazione, fondatore e Vice Presidente di Prometeia e Vice Presidente di Prometeia Advisor Sim. Dal 2000 è Grande Ufficiale della Repubblica per meriti relativi al disegno di politica economica per lʼadesione dellʼItalia allʼUnione Monetaria Europea. È Consigliere indipendente di Arca Impresa Gestioni Sgr, membro dellʼOrganismo di Vigilanza di Panariagroup. Laurea in Scienze Politiche presso lʼUniversità di Bologna, ha proseguito gli studi di economia a Oxford sotto la supervisione di Sir John Hicks. Dal 1974 al 2012 nello stesso ateneo bolognese ha insegnato Politica Economica. È stato Consigliere economico del Presidente del Consiglio dei Ministri (1996-1998). Consigliere economico del Ministro del Tesoro (1998-2000). Consigliere economico del Presidente del Consiglio (2000-2001). Dal 1999 al 2003 è stato membro del Nucleo di Valutazione della Spesa Previdenziale. Ambito primario dei suoi studi è l'analisi degli effetti delle politiche macroeconomiche. In particolare, valutazioni quantitative per la gestione della politica economica e la formulazione di previsioni circa l'evoluzione dell'economia italiana. Dal 1988 al 1997 ha collaborato con “Il Sole 24 Oreˮ occupandosi di politica economica italiana ed europea, è stato editorialista di “Affari&Finanzaˮ."
          },
          {
            name: "Davide Squarzoni",
            position: "Amministratore Delegato",
            avatar: "https://www.prometeia.3logic.it/cdn/financial_advisory/societary_organs/squarzoni.png",
            body: "Partner di Prometeia dal 2008 e Senior Partner dal 2015. Dal 2018 è Amministratore Delegato di Prometeia Advisor Sim, dove dal 2006 ricopre anche la carica di Direttore Generale. Si è laureato con lode in Scienze Statistiche ed Economiche presso lʼUniversità di Bologna e in Prometeia dal 1999, ha approfondito le tematiche di Asset Allocation e Portfolio Management e ha sviluppato progetti relativi allʼinnovazione dellʼofferta e al posizionamento commerciale per gli intermediari finanziari. In qualità di manager responsabile dei progetti di consulenza per gli investitori istituzionali ha gestito le attività di definizione dellʼasset allocation, la selezione e il monitoraggio degli asset manager e ha coordinato il team che ha sviluppato il software per le analisi di fund selection, di benchmarking quali-quantitativo e di performance attribution. Nel 2005, in qualità di dirigente responsabile della Practice Financial Advisory di Prometeia, ha gestito progetti di business consulting a supporto di asset manager (tradizionali e alternativi), investment bank e altri intermediari finanziari. Coordina oggi un team di professionisti coinvolti nella realizzazione di progetti di consulenza in materia di investimenti e di mediazione a beneficio degli investitori istituzionali."
          }
        ]
      },
      {
        title: "Collegio sindacale",
        items: [
          {
            name: "Giacomo Ramenghi",
            position: "Presidente",
          },
          {
            name: "Marta Benini"
          }
        ]
      },
      {
        title: "Società di revisione",
        items: [
          {
            name: "BDO Italia Spa"
          }
        ]
      },
      {
        title: "Responsabile per la gestione del rischio",
        items: [
          {
            name: "Riccardo Tedeschi"
          }
        ]
      },
      {
        title: "Responsabile del controllo della conformità alle norme",
        items: [
          {
            name: "Patrizia Pasquini"
          }
        ]
      },
      {
        title: "Responsabile antiriciclaggio",
        items: [
          {
            name: "Alessandro Pomelli"
          }
        ]
      },
      {
        title: "Organismo di vigilanza",
        items: [
          {
            name: "Prof. Marco Lamandini",
            position: "Presidente",
          }
        ]
      },
      {
        title: "Data Protection Officer",
        items: [
          {
            name: "Avv. Anna Cataleta"
          }
        ]
      }
    ]
  },

  sustainability: {
    title: "Sostenibilità",
    body: `<p>Lʼimpegno di Prometeia Advisor Sim per lo sviluppo sostenibile
          vuole andare oltre lʼofferta di soluzioni di consulenza: si traduce
          in un senso di responsabilità che caratterizza tutte le sue attività
          e l'offerta di servizi.
          </p><p>
          Nel 2018 è stato presentato il report di sostenibilità di Prometeia
          Advisor Sim, redatto secondo le linee guida dell'iniziativa PRI
          (Principles for Responsible Investment), alla quale la Sim
          aderisce dal 2015. PRI, supportata dalle Nazioni Unite, è il
          principale sostenitore mondiale dell'investimento responsabile.
          </p><p>
          Nel rapporto viene declinato il concetto di sostenibilità secondo
          Prometeia Advisor Sim e vengono dettagliate attività e servizi, in
          termini di obiettivi, metodologie e cultura societaria, al fine di
          integrare sempre più la sostenibilità nei processi e nei servizi a
          favore dei propri clienti.</p>`,
    links: [
      { label: "Politica di sostenibilità", url: "/it/sim-article/sustainability" },
      { label: "Informativa sulla sostenibilità nel settore dei servizi finanziari 2024", url: "/it/sim-article/sustainability-2024" },
      { label: "Relazione di sostenibilità 2023", url: "/it/sim-article/sustainability-2023" },
      { label: "Transparency Report PRI 2020", url: "/it/sim-article/sustainability-2022" }
    ]
  },

  legalDocuments: {
    title: "Documenti legali",
    links: [
      { label: "Informativa generale su impresa di investimento - professionali", url: "/it/sim-article/0001p" },
      { label: "Informativa generale su impresa di investimento - retail", url: "/it/sim-article/0001r" },
      { label: "Informativa sul processo di trattazione dei reclami", url: "/it/sim-article/0002" },
      { label: "Classificazione della clientela e relativo livello di tutela", url: "/it/sim-article/0003" },
      { label: "Politica in materia di conflitto di interesse", url: "/it/sim-article/0004" },
    ]
  },

  offices: {
    items: [
      {
        title: "Bologna",
        body: `
          <p>Piazza Trento e Trieste, 3</p>
          <p>40137</p>
          <p>+39 051 6480911</p>
          <p>info@prometeia.com</p>
        `,
        cover: "/assets/images/tmp_office.jpg"
      },
      {
        title: "Milano",
        body: `
          <p>Via Brera, 18</p>
          <p>20121</p>
          <p>+39 02 80505845</p>
          <p>info@prometeia.com</p>
        `,
        cover: "/assets/images/tmp_office.jpg"
      },
      {
        title: "Roma",
        body: `
          <p>Viale Regina Margherita, 279</p>
          <p>00198</p>
          <p>info@prometeia.com</p>
        `,
        cover: "/assets/images/tmp_office.jpg"
      }
    ]
  }
}
