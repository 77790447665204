"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Applicator = function () {
  function Applicator() {}
  return Applicator;
}();
exports.Applicator = Applicator;
