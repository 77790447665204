{
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_AcpAnJb6F",
    "aws_user_pools_web_client_id": "20j8mkvomemf5l0mlnh7fht8pv",
    "oauth": {
        "domain": "prometeia-ar-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://www.prometeia.3logic.it/authenticated",
        "redirectSignOut": "https://www.prometeia.3logic.it/disconnected",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
}