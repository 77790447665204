import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { LongArrowDownComponent } from "../../../components/long-arrow-down/long-arrow-down.component";
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { IAboutUsPageSocialResponsibility } from '../../../models/about-us';
import { ISitePageMediaSection, PageRoots } from '../../../models/pages';
import { CommonModule } from '@angular/common';
import { PromCardsListComponent } from "../../../components/prom-cards-list/prom-cards-list.component";
import { ImageCardComponent } from "../../../components/image-card/image-card.component";
import { QuoteSectionComponent } from "../../../components/quote-section/quote-section.component";
import { VIDEO_SOURCES } from '../../../models/constants';
import { MediaSectionComponent } from "../../../components/media-section/media-section.component";

const ROOT_SLUG: PageRoots = 'about-us';

@Component({
  selector: 'app-social-responsibility-page',
  templateUrl: './social-responsibility-page.component.html',
  styleUrl: './social-responsibility-page.component.scss',
  standalone: true,
  imports: [CommonModule, LongArrowDownComponent, PromAnimatedButtonComponent, PromCardsListComponent, ImageCardComponent, QuoteSectionComponent, MediaSectionComponent],
})
export class SocialResponsibilityPageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;
  readonly videoSrc = VIDEO_SOURCES.SOCIAL_RESPONSIBILITY;

  override get slug() {
    return "social-responsibility";
  }

  override pageData?: IAboutUsPageSocialResponsibility;

  override ngOnInit(): void {
    super.staticSlugInit();
  }

  protected override formatPage(): void {
    super.formatPage();

    this.fixCsrAttachment();
  }

  fixCsrAttachment() {
    const item = this.pageData?.mediaSection?.items.find((item, index) => (item.type === 'text') || index === 0);
    // console.log(item);

    if (!item) {
      return;
    }

    item.theme = 'electric-blue';
    item.size = 'full';

    if (item.download) {
      item.download.url = this.pageData?.report.url;
    } else {
      item.download = {
        label: this.pageData?.report.label || 'Download',
        url: this.pageData?.report.url
      };
    }
  }
}
