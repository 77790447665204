import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LongArrowDownComponent } from '../../../components/long-arrow-down/long-arrow-down.component';
import { MediaSectionComponent } from '../../../components/media-section/media-section.component';
import { SectionContactUsComponent } from '../../../components/section-contact-us/section-contact-us.component';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { PageRoots } from '../../../models/pages';
import { ICareersPageOurTribe } from '../../../models/careers';
import { getYoutubeID, scrollToTop } from '../../../services/utilities.service';
import { FlippingCardComponent } from "../../../components/flipping-card/flipping-card.component";
import { PromCardsListComponent } from "../../../components/prom-cards-list/prom-cards-list.component";
import { ISitePageReference } from '../../../models/common';
import { RoutesToPagesComponent } from "../../../components/routes-to-pages/routes-to-pages.component";
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DotsLoaderComponent } from "../../../components/dots-loader/dots-loader.component";
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { CareersService } from '../../../services/careers.service';
import { CarouselComponent } from "../../../components/carousel/carousel.component";
import { VIDEO_SOURCES } from '../../../models/constants';

const ROOT_SLUG: PageRoots = 'careers';

const OPEN_POSITION_TO_SHOW: number = 3;
const OPEN_POSITION_INCREMENT: number = 3;

@Component({
  selector: 'app-our-tribe-page',
  templateUrl: './our-tribe-page.component.html',
  styleUrl: './our-tribe-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MediaSectionComponent, SectionContactUsComponent, LongArrowDownComponent, FlippingCardComponent, PromCardsListComponent, RoutesToPagesComponent, PromAnimatedButtonComponent, DotsLoaderComponent, CarouselComponent],
})
export class OurTribePageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;
  readonly videoSrc = VIDEO_SOURCES.OUR_TRIBE;

  override get slug() {
    return "our-tribe";
  }

  override pageData?: ICareersPageOurTribe;

  private _openPositions?: ISitePageReference[];
  private _filteredOpenPositions?: ISitePageReference[];
  openPositionsLimit: number = OPEN_POSITION_TO_SHOW;
  get openPositions(): ISitePageReference[] | undefined {
    return this._filteredOpenPositions?.slice(0, this.openPositionsLimit);
  }

  get canLoadMore(): boolean {
    return this.openPositionsLimit < (this._filteredOpenPositions?.length ?? 0);
  }

  constructor(
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly api: ApiService,
    protected override readonly translate: TranslateService,
    private readonly careersService: CareersService,
  ) {
    super(activatedRoute, api, translate);
  }

  override ngOnInit() {
    this.loadPage();
    this.loadOpenPositions();
    scrollToTop();
    
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      if (paramMap.has('lang')) {
        const lang = paramMap.get('lang');
        if (lang) {
          this.translate.use(lang);
        }
      }
    });
  }

  loadingOpenPositions: boolean = false;
  private async loadOpenPositions() {
    this.loadingOpenPositions = true;

    try {
      const res = await this.careersService.list();
      // console.log(res);
  
      this._openPositions = res;
      this._filteredOpenPositions = res;
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loadingOpenPositions = false;
    }
  }

  onLoadMoreClick() {
    this.openPositionsLimit += OPEN_POSITION_INCREMENT;
  }


  override formatPage() {
    super.formatPage();
    this.formatSubTitle();
    this.formatCarousel();
  }

  private formatSubTitle() {
    if (!this.pageData?.subtitle || !this.pageData?.subtitle?.includes('.')) return;
    this.pageData.subtitle = this.pageData.subtitle?.replaceAll(". ", '.<br>');
  }

  private formatCarousel() {
    if (!this.pageData?.carousel) return;
    this.pageData.carousel.items.forEach((slide) => {
      if (slide.link?.url && !slide.link.url.endsWith('.mp4')) {
        slide.link.url = `https://www.youtube-nocookie.com/embed/${getYoutubeID(slide.link.url)}`;
      }
    });
  }

}
