import { CommonModule } from '@angular/common';
import { Component, inject, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, TranslateModule],
})
export class DialogComponent {
  buttonOk = {
    theme: 'btn_color__yellow',
    label: "OK"
  }

  data = inject(MAT_DIALOG_DATA);
  constructor(public dialogRef: MatDialogRef<DialogComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
