@if (!loading && pageData) {
    <div class="page about-us--insights">
        <div class="page-content">
            <section class="insights">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.category"></h2></div>
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    <!-- <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div> -->
                </div>

                <!-- <app-long-arrow-down></app-long-arrow-down> -->

                <div class="section-content">
                    <div [ngClass]="{'selectors-container': true, 'with-empty-list': !newsList?.items?.length}">
                        <app-selector-ship [selectors]="selectors" (selectionChangedEvent)="onTagsSelectionChanged($event)" (stringSearchEvent)="onStringSearch($event)" (clearSearchEvent)="onClearStringSearch()"></app-selector-ship>
                    </div>
                </div>

                @if (newsList?.items?.length) {
                    <app-section-news-headlines [news]="newsList!.items" [bgColor]="'almost-white'" [currentLang]="currentLang" [showSeeMoreButton]="false"></app-section-news-headlines>
                }
                @else if (!loadingNews) {
                    <div class="section-content">
                        <h3 class="empty-list">{{'EMPTY_LISTS.EMPTY' | translate}}</h3>
                    </div>
                }

                @if (loadingNews) {
                    <!-- <div class="spinner">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div> -->

                    <div class="dots">
                        <app-dots-loader></app-dots-loader>
                    </div>
                }
                @else if (canLoadMore) {
                    <div class="load-more">
                        <app-prom-animated-button [theme]="'deep-blue'" (click)="onLoadMoreClick()">
                            {{'GENERAL.LOAD_MORE' | translate | uppercase}}
                        </app-prom-animated-button>
                    </div>
                }
            </section>

            <section class="newsletters">
                <div class="section-content">
                    <h3>Our Newsletters</h3>
                    
                    <app-newsletters [newsletters]="pageData.newsletters?.items"></app-newsletters>
                </div>
            </section>

            <app-section-contact-us [text]="'Any questions?<br>Get in touch with our team!'"></app-section-contact-us>
        </div>
    </div>
}
