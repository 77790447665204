<div class="long-arrow-down animation-up-and-down">
    @switch (theme) {
        @case ('fluo-yellow') {
            <img src="/assets/icons/long_arrow_down__yellow.svg" alt="">
        }
        @case ('sim-red') {
            <img src="/assets/icons/long_arrow_down__red.svg" alt="">
        }
        @case ('deep-blue') {
            <img src="/assets/icons/long_arrow_down__blue.svg" alt="">
        }
        @case ('white') {
            <img src="/assets/icons/long_arrow_down__white.svg" alt="">
        }
        @default {
            <img src="/assets/icons/long_arrow_down.svg" alt="">
        }
    }
</div>
