@if (newsletters?.length) {
    <div class="newsletters">
        @for (newsletter of newsletters; track $index) {
            <div class="newsletter default-card-border" [ngClass]="{'open': newsletter.isOpen, 'closed': !newsletter.isOpen}">
                <div class="header" (click)="newsletter.isOpen = !newsletter.isOpen;">
                    <h4 class="title" [innerHTML]="newsletter.title"></h4>

                    <button mat-icon-button class="accordion-toggle">
                        @if (newsletter.isOpen) {
                            <!-- <mat-icon>expand_less</mat-icon> -->
                            <img class="open-state-icon simplified-arrow-less" src="/assets/icons/down_simplified_arrow__blue.svg" alt="" />
                        }
                        @else {
                            <!-- <mat-icon>expand_more</mat-icon> -->
                            <img class="open-state-icon simplified-arrow-more" src="/assets/icons/down_simplified_arrow__blue.svg" alt="" />
                        }
                    </button>
                </div>

                <div class="content">
                    <div class="image-wrap"><img [src]="newsletter.img" /></div>

                    <div class="body"><p [innerHTML]="newsletter.body"></p></div>

                    <div class="button-wrap">
                        <app-prom-animated-button [theme]="'deep-blue'" [arrow]="'right'" (click)="openNewsletterDialog(newsletter)">
                            {{'Subscribe' | uppercase}}
                        </app-prom-animated-button>
                    </div>
                </div>
            </div>
        }
    </div>
}
