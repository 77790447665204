import { Component } from '@angular/core';
import { scrollToTop } from '../../services/utilities.service';
import { ISitePageLink } from '../../models/common';
import { TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FOOTER_DATA_SIM, FOOTER_DATA_SIM__ITA } from '../../models/constants';

@Component({
  selector: 'app-footer-sim',
  templateUrl: './footer-sim.component.html',
  styleUrl: './footer-sim.component.scss',
  standalone: true,
  imports: [RouterModule],
})
export class FooterSimComponent {

  footerData?: ISitePageLink[];
  itaFooterData?: ISitePageLink[];

  get currentLang(): string {
    return this.translate.currentLang;
  }

  currentYear = new Date().getFullYear();

  constructor(
    private readonly translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loadFooterData();
  }

  private async loadFooterData() {
    this.footerData = FOOTER_DATA_SIM;
    this.itaFooterData = FOOTER_DATA_SIM__ITA;
  }

  scrollToTop() {
    scrollToTop({behavior: "smooth"});
  }
}
