import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { ISimAnteoArchive, ISimEventArchive } from '../../../models/sim';
import { CommonModule } from '@angular/common';
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { SectionContactUsSimComponent } from "../../../components/section-contact-us-sim/section-contact-us-sim.component";
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DotsLoaderComponent } from "../../../components/dots-loader/dots-loader.component";
import { LocaleEnum } from '../../../models/common';

const INITIAL_ANTEO_LIMIT: number = 10;
const ANTEO_LIMIT_INCREMENT: number = 10;

const INITIAL_EVENT_LIMIT: number = 5;
const EVENT_LIMIT_INCREMENT: number = 5;

const USE_ANTEO_LATEST_TEMPLATE: boolean = false;

@Component({
  selector: 'app-sim-archive',
  templateUrl: './sim-archive.component.html',
  styleUrl: './sim-archive.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, PromAnimatedButtonComponent, SectionContactUsSimComponent, DotsLoaderComponent],
})
export class SimArchiveComponent extends BaseSitePageComponent {
  get baseRoute(): string {
    switch (this.slug) {
      case 'anteo':               return 'sim-anteo';
      case 'in-formation-paths':  return 'sim-event';
      default:                    return '';
    }
  }

  // get isAnteoLatestPage() {
  //   return window.location.href.endsWith('anteo/latest');
  // }
  isAnteoLatestPage: boolean = false;

  get isAnteoLatestTemplate() {
    return USE_ANTEO_LATEST_TEMPLATE && this.isAnteoLatestPage;
  }

  limit: number = 0;

  override pageData?: ISimAnteoArchive | ISimEventArchive;

  override ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.queryParams.subscribe(params => {
      const latest = params['latest'];
      this.isAnteoLatestPage = latest === 'true';
    });
  }

  override async loadPage() {
    // console.log(this.slug, "->", this.baseRoute);

    this.limit = this.isAnteoLatestTemplate ? 1 : (this.slug === 'anteo' ? INITIAL_ANTEO_LIMIT : INITIAL_EVENT_LIMIT);

    try {
      switch (this.slug) {
        case 'anteo':
          this.pageData = await this.api.simPages.anteoArchive();
          if (this.isAnteoLatestPage) {
            this.toggleItem(this.pageData.items[0], 0, true);
          }
          break;
        case 'in-formation-paths':
          this.pageData = await this.api.simPages.list({ baseRoute: 'sim-event' });
          // this.pageData = simEventsArchive;
          break;
        default:
          this.pageData = undefined;
      }
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    }
  }

  onLoadMoreClick() {
    this.limit += (this.slug === 'anteo' ? ANTEO_LIMIT_INCREMENT : EVENT_LIMIT_INCREMENT);
  }

  toggleItem(item: any, index: number, isInit?: boolean) {
    if (this.isAnteoLatestTemplate && !isInit) return;

    item.isOpen = !item.isOpen;

    if (item.isOpen && this.baseRoute === 'sim-anteo') {
      if (!item.issueContent) {
        this.loadAnteoIssue(item, (item as {issueNumber: string}).issueNumber);
      }
    }
  }

  loadingAnteoIssue: boolean = false;
  private async loadAnteoIssue(item: any, issueNumber: string) {
    this.loadingAnteoIssue = true;
    try {
      item.issueContent = await this.api.simPages.anteoIssue(issueNumber);
    } catch (ex: any) {
      console.error(ex);
    } finally {
      this.loadingAnteoIssue = false;
    }
  }
}

const simEventsArchive: ISimEventArchive = {
  baseRoute: 'sim-event',
  root: 'general-articles',
  locale: LocaleEnum.IT,
  slug: 'in-formation-paths',
  title: 'Pecorsi di InFormazione',
  subtitle: '',
  items: [
    {
      issueNumber: 'Bruxelles 2023',
      title: 'Uno sguardo ampio verso un mondo in transizione',
      pubDate: new Date('2023-09-26'),
      body: `<p>
        Il titolo scelto per il 17° percorso di InFormazione di Prometeia Advisor Sim riflette la dimensione globale, la molteplicità e la sovrapposizione dei processi che caratterizzano la transizione verso nuovi assetti del sistema geopolitico ed economico mondiale.</p>
        <p>Una transizione che subisce le accelerazioni derivanti dalle emergenze climatiche ed energetiche, dall’intensità e dalla diffusione con cui si affermano le tecnologie digitali e, sul versante delle relazioni internazionali, dai conflitti militari ed economici che stanno ridefinendo i rapporti tra i Paesi.</p>
        <p>La transizione è un cammino incerto soprattutto quando manca il consenso sugli obiettivi finali e la capacità delle politiche di guidare il cambiamento. L’Europa è al centro di questa transizione con le difficoltà dovute alla continua tensione tra progettualità comune e sovranità nazionali. Secondo un’antica etimologia, Europa significa “sguardo ampio”, da cui il titolo scelto per l’edizione del percorso di quest’anno. L’Unione europea può svolgere un ruolo da protagonista proprio se il progetto su cui si basa è sostenuto da uno sguardo ampio, una visione di insieme dei processi in corso che necessita di una gestione comune. Lo stesso sguardo che deve orientare le scelte di investimento e in particolare quelle degli investitori istituzionali abituati a orizzonti di lungo periodo che oggi vanno declinati in relazione all’ampiezza dei temi e dei fattori che influenzano i profili di rischio e di redditività delle diverse attività finanziarie e reali.</p>
        <p>Con l’appuntamento di Bruxelles, Prometeia Advisor Sim offre un’opportunità di confronto tra investitori e operatori finanziari sollecitando con le proprie analisi una riflessione ispirata a quella visione ampia richiesta dalla complessità e incertezza della transizione in corso.</p>
        <p>Parafrasando lo scrittore austriaco Karl Kraus, dobbiamo dunque esercitarci a praticare l’arte di cogliere con uno sguardo un’immagine del mondo. Ma quante cose entrano in quello sguardo!
      </p>`
    },
    {
      issueNumber: 'Madrid 2021',
      title: '¿Dal new normal al never normal?',
      pubDate: new Date('2023-09-26'),
      body: `<p>
        La Great Financial Crisis del 2007-2008 ha lasciato ferite profonde nell’economia, che cominciavano a sanarsi quando ha fatto irruzione la pandemia da Covid-19, con una forza d’urto tale da trasformare le nostre esistenze. Abbiamo così rimandato le aspirazioni di un ritorno alla “vita di prima”, in attesa che la crisi sanitaria cessasse.</p>
        <p>La pandemia sembra ora dare i primi solidi segni di arretramento, ma la normalità pare allontanarsi nuovamente a causa della guerra in Ucraina. Non sappiamo ancora dove questo conflitto, che auspichiamo rientri nel più breve tempo possibile, possa portare. Quello che sappiamo è che le sue implicazioni geopolitiche, economiche e sociali rendono ancora più incerto il ritorno a un nuovo equilibrio, una nuova normalità. E se invece il “new normal” fosse in realtà un “never normal”? Siamo entrati in una fase storica, dove le situazioni di crisi rendono ancora più incerto il governo dei cambiamenti strutturali che hanno da tempo assunto una dimensione globale.</p>
        <p>L’emergenza climatica, con le opportunità e i costi di una transizione verso società ed economie sostenibili sotto il profilo ambientale e sociale, è destinata a ridefinire la struttura produttiva delle nostre economie, premiando imprese e settori più capaci di adattare o innovare i propri processi e prodotti.</p>
        <p>Altrettanto dirompente è l’impatto della rivoluzione digitale sui modi di produzione, sul contenuto di servizio dei prodotti, sui comportamenti di consumo. Infine, la transizione demografica, che abbiamo cominciato a delineare già dal primo incontro di InFormazione a Lisbona, nel 2005. Temi complessi, vere e proprie sfide che sono anche al centro dell’agenda del Piano nazionale di ripresa e resilienza e che rappresentano una prova cruciale per tutti gli operatori economici e finanziari, alla quale certamente non si sottraggono gli investitori istituzionali italiani. In questo contesto economico e politico in continua evoluzione, le decisioni finanziarie richiedono capacità analitiche e un punto di osservazione indipendente e competente, in grado di comprendere e gestire il cambiamento: interpretare questo ruolo è l’ambizione di Prometeia Advisor Sim, in ciò confortati nel tempo dai risultati del nostro impegno a favore degli investitori.</p>
        <p>Il titolo che abbiamo dato al nostro XVI Percorso di inFormazione: “¿Dal new normal al never normal?”, se da un lato sottolinea la maggiore incertezza degli scenari con cui siamo chiamati a confrontarci, dall’altro stimola una riflessione a tutto campo, meno condizionata da “sentieri” interpretativi già battuti. Con le parole di Antonio Machado: “Caminante, no hay camino: se hace camino al andar”.
      </p>`
    }
  ]
}
