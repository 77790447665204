import { Injectable } from '@angular/core';
import { LocaleEnum } from '../models/common';

export type RecognizedBrowsers = 'Opera' | 'Chrome' | 'Safari' | 'Firefox' | 'IE' | 'unknown';

@Injectable({
  providedIn: 'root',
})
export class BrowserServices {

  constructor() {}
  
  public myBrowser(): RecognizedBrowsers {
    const UA = navigator.userAgent;
    if (!UA) return 'unknown';
    
    // console.log("UA", UA);

    if (UA.indexOf("Opera") != -1 || UA.indexOf('OPR') != -1 || UA.includes('OPR')) {
      return 'Opera';
    } else if (UA.indexOf("Chrome") != -1 && !!(window as any)['chrome']) {
      return 'Chrome';
    } else if (UA.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (UA.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if (UA.indexOf("MSIE") != -1) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  public myDevice(): string {
    const UA = navigator.userAgent;
    if (!UA) return 'unknown';

    if (UA.indexOf("iPhone OS") != -1) {
      return "iPhone";
    } else if (UA.indexOf("Android") != -1) {
      return "Android";
    } else if (UA.includes("Mac") && "ontouchend" in document) {
      return this.iOSversion();
    } else {
      return "other";
    }
  }

  private iOSversion(): string {
    if (window.indexedDB) { return 'iOS_8+'; }
    if (window.SpeechSynthesisUtterance) { return 'iOS_7'; }
    // if (window.matchMedia) { return 'iOS_5'; }
    if (window.history && 'pushState' in window.history) { return 'iOS_4'; }
    return 'other_iOS';
  }

  public myLanguage(): string {
    /** 
     * navigator.language is the standard;
     * window.navigator.language is a non-standard implementation in some browsers, and the only one in IE 
     * */
    const deviceLang = navigator.language || window.navigator.language;
    const lang = deviceLang.split("-")[0] == LocaleEnum.IT ? LocaleEnum.IT : LocaleEnum.IT;
    // console.log("device lang:", deviceLang, lang);
    // if (!this.cookieService.isLangStored()) {
    //   this.cookieService.setLangCookie();
    // }
    
    return lang;
  }
} 