<div class="p-cards size-{{size}}">
    @for (card of cards; track $index) {
        <div class="p-card-wrap">
            @if (size !== 'full') {
                <app-prom-card [card]="card" [cardIndex]="$index" [size]="size" [arrowColor]="arrowColor" [titleColor]="titleColor" [theme]="theme" [forcedCardHeight]="calculatedHeight" [sectionID]="sectionID"></app-prom-card>
            }
            @else {
                <app-prom-card [card]="card" [cardIndex]="$index" [size]="size" [arrowColor]="arrowColor" [titleColor]="titleColor" [theme]="theme" [sectionID]="sectionID"></app-prom-card>
            }
        </div>
    }
</div>
