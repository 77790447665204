import { createAssertionFunction } from '../../../errors/createAssertionFunction.mjs';
import '../../../types/errors.mjs';
import '../../../errors/errorHelpers.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var AuthConfigurationErrorCode;
(function (AuthConfigurationErrorCode) {
  AuthConfigurationErrorCode["AuthTokenConfigException"] = "AuthTokenConfigException";
  AuthConfigurationErrorCode["AuthUserPoolAndIdentityPoolException"] = "AuthUserPoolAndIdentityPoolException";
  AuthConfigurationErrorCode["AuthUserPoolException"] = "AuthUserPoolException";
  AuthConfigurationErrorCode["InvalidIdentityPoolIdException"] = "InvalidIdentityPoolIdException";
  AuthConfigurationErrorCode["OAuthNotConfigureException"] = "OAuthNotConfigureException";
})(AuthConfigurationErrorCode || (AuthConfigurationErrorCode = {}));
const authConfigurationErrorMap = {
  [AuthConfigurationErrorCode.AuthTokenConfigException]: {
    message: 'Auth Token Provider not configured.',
    recoverySuggestion: 'Make sure to call Amplify.configure in your app.'
  },
  [AuthConfigurationErrorCode.AuthUserPoolAndIdentityPoolException]: {
    message: 'Auth UserPool or IdentityPool not configured.',
    recoverySuggestion: 'Make sure to call Amplify.configure in your app with UserPoolId and IdentityPoolId.'
  },
  [AuthConfigurationErrorCode.AuthUserPoolException]: {
    message: 'Auth UserPool not configured.',
    recoverySuggestion: 'Make sure to call Amplify.configure in your app with userPoolId and userPoolClientId.'
  },
  [AuthConfigurationErrorCode.InvalidIdentityPoolIdException]: {
    message: 'Invalid identity pool id provided.',
    recoverySuggestion: 'Make sure a valid identityPoolId is given in the config.'
  },
  [AuthConfigurationErrorCode.OAuthNotConfigureException]: {
    message: 'oauth param not configured.',
    recoverySuggestion: 'Make sure to call Amplify.configure with oauth parameter in your app.'
  }
};
const assert = createAssertionFunction(authConfigurationErrorMap);
export { AuthConfigurationErrorCode, assert };
