import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dots-loader',
  templateUrl: './dots-loader.component.html',
  styleUrl: './dots-loader.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class DotsLoaderComponent {

  @Input() size: string = "16px";
  @Input() color: string = "lightgrey";

  dotsQuantity: number = 3;

  get dots(): string[] {
    return Array(this.dotsQuantity).fill('.');
  }

  get height(): string {
    return this.size;
  }

  get width(): string {
    return (parseFloat(this.size) * 1.1 * this.dotsQuantity) + "px";
  }

}
