<app-video-embedder [source]="slideVideoItem" [isOpenEvt]="openVideoSignal"></app-video-embedder>

<div class="carousel">
    <owl-carousel-o [options]="customOptions">
        @for (slide of slides; track $index) {
            <ng-template carouselSlide [id]="getSlideID(slide)">
                <div class="slide-content">
                    <img class="cover" [src]="slide.cover" alt="{{slide.label}} slide">
                    
                    <div class="content-wrap">
                        <div class="content">
                            <h2 [ngClass]="{'content--quote': true, 'in-single-slide-carousel': slides.length === 1}">
                                <span class="text--{{quotationMarkColor}}">“</span>
                                <br>
                                {{slide.quote}}
                            </h2>
        
                            <h4 class="content--label-and-details"><span class="content--label">{{slide.label}}</span>, {{slide.details}}</h4>
    
                            @if (slide.link && slide.link.url) {
                                <div class="button-wrap">
                                    <app-prom-animated-button [theme]="promAnimatedButtonTheme" [bgColor]="'dark'" [arrow]="'right'" [carouselFix]="true" (click)="openVideo(slide)">
                                        {{slide.link.label | uppercase}}
                                    </app-prom-animated-button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ng-template>
        }
    </owl-carousel-o>
</div>
