import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaSectionComponent } from '../../../components/media-section/media-section.component';
import { PromCardsListComponent } from '../../../components/prom-cards-list/prom-cards-list.component';
import { RoutesToPagesComponent } from '../../../components/routes-to-pages/routes-to-pages.component';
import { SectionContactUsComponent } from '../../../components/section-contact-us/section-contact-us.component';
import { SectionNewsHeadlinesComponent } from '../../../components/section-news-headlines/section-news-headlines.component';
import { LongArrowDownComponent } from "../../../components/long-arrow-down/long-arrow-down.component";
import { PageRoots } from '../../../models/pages';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { ISolutionsPage } from '../../../models/solutions';

const ROOT_SLUG: PageRoots = 'solutions';

@Component({
  selector: 'app-solutions-page',
  templateUrl: './solutions-page.component.html',
  styleUrl: './solutions-page.component.scss',
  standalone: true,
  imports: [CommonModule, PromCardsListComponent, MediaSectionComponent, RoutesToPagesComponent, SectionContactUsComponent, SectionNewsHeadlinesComponent, LongArrowDownComponent],
})
export class SolutionsPageComponent extends BaseSitePageComponent implements OnInit {

  override readonly pageRoot: PageRoots = ROOT_SLUG;
  get videoSrc() {
    if (!this.pageData?.parent) return "";
    return `/cdn2/site/intro-videos/${this.pageData.parent}.mp4`;
  };

  override pageData?: ISolutionsPage;

  get isAlternateSolutionsPage(): boolean {
    return (this.slug !== null && ["saas-cloud"].includes(this.slug)) ?? false;
    // return false;
  }

  protected override formatPage() {
    super.formatPage();
    this.formatMediaTitles();
  }

  private formatMediaTitles() {
    this.pageData?.mediaSection?.items.forEach((item) => {
      if (item.type === 'text' && item.size !== 'full' && item.title ) {
        item.title = item.title.replace(/ &/g, '<br>&');
      }
    });
  }
  
}
