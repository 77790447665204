import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiArService } from '../../services/api-ar.service';
import { AmplifyAuthService, AwsExportsType } from '../../services/amplify-auth.service';
import { AlumniAuthComponent } from '../alumni-auth/alumni-auth.component';
import { SafePipe } from '../../pipes/safe.pipe';
import { MatIconModule } from '@angular/material/icon';
import { AR_PRIVACY_ROUTE } from '../../models/constants';

@Component({
  selector: 'app-ar-dialog-auth',
  templateUrl: './ar-dialog-auth.component.html',
  styleUrls: ['./ar-dialog-auth.component.scss'],
  
  imports: [AlumniAuthComponent, SafePipe, MatIconModule, TranslateModule],
  standalone: true,
})
export class ArDialogAuthComponent  {

  get currentLang(): string {
    return this.translate.currentLang;
  }

  private poolStatusSubscription: Subscription | null = null;

  arPrivacyRoute = AR_PRIVACY_ROUTE;
  // data = {
  //   title: this.translate.instant("AR.TITLE"),  // "Area Riservata",
  //   textPrivacy : this.translate.instant("AR.PRIVACY", {route: AR_PRIVACY_ROUTE}),  // "Cliccando su 'Sign in' confermi di aver preso visione <a href=\"/it/article/publishing\">dell'Informativa per il trattamento dei dati personali per il servizio publishing Prometeia</a>"
  // }

  pool : AwsExportsType = 'ar';
  tokenCognito : string | null = '';
  constructor(
    public dialogRef: MatDialogRef<ArDialogAuthComponent>, 
    public translate: TranslateService, 
    private amplifyService: AmplifyAuthService,
    public apiAR: ApiArService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) {
      this.poolStatusSubscription = this.amplifyService.poolARStatus.subscribe( status => {        
      if(status.islogged){
        console.log('token', status.token);
        this.tokenCognito = status.token;        
        this.loginAR();
      }      
    });
  }

  async loginAR() {    
    try{
      if(this.tokenCognito == null) throw new Error();
     
      const login = await this.apiAR.libraries.login(this.tokenCognito);
      console.log(login);
      if(login){       
        this.dialog.closeAll(); 
        this.resevedArea();
      }                 
    }
    catch(error) {
      this.openSnackBar(this.translate.instant("AR.WARNING__ACCOUNT_NOT_ACTIVE"));
      setTimeout(() => {
        this.amplifyService.logout('ar');
        console.log(error);
      }, 100);
    }
    finally {
      //TODO
    //  await this.amplifyService.logout('ar');
   } 
  }

  resevedArea() {
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate([`/${this.currentLang}/reserved-area`]);
    else window.location.reload();
  }

  openSnackBar(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
