import { Component, Input } from '@angular/core';
import { ButtonTheme } from '../../models/common';

@Component({
  selector: 'app-prom-animated-button',
  standalone: true,
  imports: [],
  templateUrl: './prom-animated-button.component.html',
  styleUrl: './prom-animated-button.component.scss'
})
export class PromAnimatedButtonComponent {
  @Input() arrow: 'down' | 'right' | 'top-right' | 'none' = 'none';
  @Input() theme: ButtonTheme | 'in-search-page' = 'fluo-green';
  @Input() forceHoverColor?: ButtonTheme;
  @Input() bgColor: 'light' | 'dark' = 'light';
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() glowOnClick: boolean = false;

  @Input() carouselFix: boolean = false;
}
