// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var AuthValidationErrorCode;
(function (AuthValidationErrorCode) {
  AuthValidationErrorCode["EmptySignInUsername"] = "EmptySignInUsername";
  AuthValidationErrorCode["EmptySignInPassword"] = "EmptySignInPassword";
  AuthValidationErrorCode["CustomAuthSignInPassword"] = "CustomAuthSignInPassword";
  AuthValidationErrorCode["EmptySignUpUsername"] = "EmptySignUpUsername";
  AuthValidationErrorCode["EmptySignUpPassword"] = "EmptySignUpPassword";
  AuthValidationErrorCode["EmptyConfirmSignUpUsername"] = "EmptyConfirmSignUpUsername";
  AuthValidationErrorCode["EmptyConfirmSignUpCode"] = "EmptyConfirmSignUpCode";
  AuthValidationErrorCode["EmptyResendSignUpCodeUsername"] = "EmptyresendSignUpCodeUsername";
  AuthValidationErrorCode["EmptyChallengeResponse"] = "EmptyChallengeResponse";
  AuthValidationErrorCode["EmptyConfirmResetPasswordUsername"] = "EmptyConfirmResetPasswordUsername";
  AuthValidationErrorCode["EmptyConfirmResetPasswordNewPassword"] = "EmptyConfirmResetPasswordNewPassword";
  AuthValidationErrorCode["EmptyConfirmResetPasswordConfirmationCode"] = "EmptyConfirmResetPasswordConfirmationCode";
  AuthValidationErrorCode["EmptyResetPasswordUsername"] = "EmptyResetPasswordUsername";
  AuthValidationErrorCode["EmptyVerifyTOTPSetupCode"] = "EmptyVerifyTOTPSetupCode";
  AuthValidationErrorCode["EmptyConfirmUserAttributeCode"] = "EmptyConfirmUserAttributeCode";
  AuthValidationErrorCode["IncorrectMFAMethod"] = "IncorrectMFAMethod";
  AuthValidationErrorCode["EmptyUpdatePassword"] = "EmptyUpdatePassword";
})(AuthValidationErrorCode || (AuthValidationErrorCode = {}));
export { AuthValidationErrorCode };
