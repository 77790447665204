import { Component, Input } from '@angular/core';

/** example:
 * ```<app-gradient-text-wrapper> <h1 style="font-size: 50px;">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h1> </app-gradient-text-wrapper>```
*/
@Component({
  selector: 'app-gradient-text-wrapper',
  standalone: true,
  imports: [],
  templateUrl: './gradient-text-wrapper.component.html',
  styleUrl: './gradient-text-wrapper.component.scss'
})
export class GradientTextWrapperComponent {
  @Input() animated: boolean = true;
}
