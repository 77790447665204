import { Component } from '@angular/core';
import { MediaSectionComponent } from "../../components/media-section/media-section.component";
import { CommonModule } from '@angular/common';
import { RoutesToPagesComponent } from '../../components/routes-to-pages/routes-to-pages.component';
import { SectionContactUsComponent } from "../../components/section-contact-us/section-contact-us.component";
import { SectionNewsHeadlinesComponent } from "../../components/section-news-headlines/section-news-headlines.component";
import { LongArrowDownComponent } from "../../components/long-arrow-down/long-arrow-down.component";
import { ICustomersPage } from '../../models/customers';
import { PageRoots } from '../../models/pages';
import { BaseSitePageComponent } from '../base-site-page.component';

const ROOT_SLUG: PageRoots = 'customers';

@Component({
  selector: 'app-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrl: './customers-page.component.scss',
  standalone: true,
  imports: [CommonModule, MediaSectionComponent, RoutesToPagesComponent, SectionContactUsComponent, SectionNewsHeadlinesComponent, LongArrowDownComponent],
})
export class CustomersPageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;

  override pageData?: ICustomersPage;
}
