@if (pageData) {
    <div class="page insights-article {{categoryCssClass}}">
        <div class="cover-colored-filter"></div>
        
        <div class="article-and-related">
            <div class="title-box"></div>

            <div class="article-content">
                <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>

                @if (slug && ['whistleblowing-prometeia-spa', 'cookies'].includes(slug)) {
                    @switch (slug) {
                        @case ('whistleblowing-prometeia-spa') {
                            <div class="download-btn">
                                <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" (click)="notDefinedYet()">
                                    {{'FORMS.SUBMIT_REPORT' | translate | uppercase}}
                                </app-prom-animated-button>
                            </div>
                        }
                        @case ('cookies') {
                            <div class="download-btn">
                                <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" (click)="reopenCookiesSettings()">
                                    {{'COOKIES SETTINGS'}}
                                </app-prom-animated-button>
                            </div>
                        }
                    }
                }
    
                @if (pageData.body.includes('|__BUTTON_HERE__|') && pageButton) {
                    @let bodies = pageData.body.split('|__BUTTON_HERE__|');

                    @for (bodyFragment of bodies; track $index; let isLast = $last) {
                        <div class="body inner-html inner-html--insights-article" data-aos="fade-up" [innerHTML]="bodyFragment | safe : 'html'"></div>
                    
                        @if (!isLast) {
                            <div class="download-btn">
                                <a [href]="pageButton.attachment" target="_blank" download>
                                    <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'down'">
                                        @if (currentLang === 'en') {
                                            {{pageButton.label.en | uppercase}}
                                        }
                                        @else {
                                            {{pageButton.label.it | uppercase}}
                                        }
                                    </app-prom-animated-button>
                                </a>
                            </div>
                        }
                    }
                }
                @else {
                    <div class="body inner-html inner-html--insights-article" data-aos="fade-up" [innerHTML]="pageData.body | safe : 'html'"></div>
                }

                @if (slug && ['whistleblowing-prometeia-spa'].includes(slug)) {
                    @switch (slug) {
                        @case ('whistleblowing-prometeia-spa') {
                            <div class="download-btn">
                                <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" (click)="notDefinedYet()">
                                    {{'FORMS.SUBMIT_REPORT' | translate | uppercase}}
                                </app-prom-animated-button>
                            </div>
                        }
                    }
                }
                
                @if (pageData.attachment) {
                    <div class="download-btn">
                        <a [href]="pageData.attachment" target="_blank" download>
                            <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'down'">
                                {{'GENERAL.DOWNLOAD' | translate | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                }
            </div>
    
            <div class="related-wrapper">
                <div class="related-list"></div>
            </div>
        </div>
    </div>
}
@else {
    <div class="page-loading"></div>
}
