import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AmplifyAuthService, AwsExportsType } from '../../services/amplify-auth.service';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
@Component({
  selector: 'app-alumni-auth',
  templateUrl: './alumni-auth.component.html',
  styleUrls: ['./alumni-auth.component.scss'],
  standalone: true,
  imports: [AmplifyAuthenticatorModule],
})
export class AlumniAuthComponent implements OnInit, OnDestroy{
  @Input() pool?: AwsExportsType;
  private changeConfigSubscription: any = null;
  show : boolean = false;
  public formFields = {
    signIn: {
      username: {
        placeholder: 'Email',
        isRequired: true,
        label: 'Email'
      },
    },
  } 
  private poolStatusSubscription: Subscription | null = null;
  constructor(public amplifyAuthService: AmplifyAuthService, private router: Router, public translate: TranslateService) { }

  ngOnInit(): void {
    this.changeConfigSubscription = setTimeout(()=>{
      this.amplifyAuthService.config(this.pool);
      this.show = true;
    }, 1000);
  }

  ngOnDestroy(): void{
    this.amplifyAuthService.config('alumni');
    this.changeConfigSubscription = null;

    if (this.poolStatusSubscription) {
      this.poolStatusSubscription.unsubscribe();
      this.poolStatusSubscription = null;
    }
  }

}
