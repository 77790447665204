import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AmplifyAuthService } from '../../services/amplify-auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { IHeaderRow } from '../../models/header';
import { HeaderDataService } from '../../services/header-data.service';
import { PromAnimatedButtonComponent } from '../prom-animated-button/prom-animated-button.component';
import { DialogComponent } from '../dialog/dialog.component';
import { Subscription } from 'rxjs';
import { ArDialogAuthComponent } from '../ar-dialog-auth/ar-dialog-auth.component';
import { ISitePageLink } from '../../models/common';
import { scrollToTop } from '../../services/utilities.service';
import { MatIconModule } from '@angular/material/icon';
import { FOOTER_DATA_SPA, SOCIALS_LIST } from '../../models/constants';
import { ContactModalComponent } from '../section-contact-us/contact-modal.component';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatIconModule, PromAnimatedButtonComponent],
})
export class FooterComponent extends ContactModalComponent implements OnInit, OnDestroy {
  _headerData?: IHeaderRow[];
  get headerData(): IHeaderRow[] | undefined {
    return this._headerData /* ?? this.activatedRoute.snapshot.data['headerData'] as IHeaderRow[] | undefined */;
  }

  get filteredHeaderData(): IHeaderRow[] | undefined {
    return this._headerData?.filter(x => (x.children?.length ?? 0) > 1);
  }

  get shortHeaderData(): IHeaderRow[] | undefined {
    return this._headerData?.filter(x => (x.children?.length ?? 0) <= 1);
  }

  footerData?: ISitePageLink[];

  socialsList: {type: string, link: string}[] = SOCIALS_LIST;

  currentYear = new Date().getFullYear();

  constructor(
    protected override readonly dialog: MatDialog,
    protected override readonly translate: TranslateService,
    protected override readonly api: ApiService,
    protected override readonly headerDataService: HeaderDataService,
    private readonly amplifyService: AmplifyAuthService,
    private readonly router: Router,
  ) {
    super(dialog, translate, api, headerDataService);
  }

  ngOnInit(): void {
    this.loadHeaderData();
    this.loadFooterData();

    this.checkPoolStatus();

    this.routerEventsSubscription = this.router.events.subscribe((event: any) => {     
      if(event){
        this.checkPoolStatus();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyAR();
  }

  private async loadHeaderData() {
    if (!this.headerData) {
      const hData = await this.headerDataService.getFormattedHeaderData();
      this._headerData = hData; //.filter(x => x.slug !== 'sim');
    }
  }

  private async loadFooterData() {
    this.footerData = FOOTER_DATA_SPA;
  }

  getFirstLevelChildren(firstLevel: IHeaderRow, block?: 'second-half') {
    if (firstLevel.slug !== 'expertise') {
      return firstLevel.children;
    }
    
    const midIndex = firstLevel.children?.length! / 2;
    if (block === 'second-half') {
      return firstLevel.children?.slice(midIndex, firstLevel.children?.length!);
    } else {
      return firstLevel.children?.slice(0, midIndex);
    }
  }

  getThirdLevelLabel(thirdLevel: IHeaderRow): string[] {
    // return thirdLevel.label.split(" & ").map((x, i) => (i !== 0 ? "& " : "") + x);
    return [thirdLevel.label];
  }

  scrollToTop() {
    scrollToTop({behavior: "smooth"});
  }


  /** SITE PAGES (mobile) */
  activeSitePagesFirstLevelIndex?: number;

  toggleSitePages(index: number, el?: HTMLElement) {
    if (this.activeSitePagesFirstLevelIndex === index) {
      this.activeSitePagesFirstLevelIndex = undefined;
    } else {
      this.activeSitePagesFirstLevelIndex = index;
      if (el) {
        window.scroll({ top: el.offsetTop - 28, left: 0, behavior: 'smooth' });
      }
    }
  }



  /** RESERVED AREA */
  private COOKIE_TOKEN_AR : string = 'prom-ar-token';

  isLoggedAR : boolean = false;
  showLogout : boolean = false;

  private routerEventsSubscription?: Subscription;

  private destroyAR() {
    this.checkPoolStatus();
    this.routerEventsSubscription?.unsubscribe();
  }

  private async checkPoolStatus() {
    this.isLoggedAR = await this.amplifyService.isLoggedAR(this.COOKIE_TOKEN_AR);
    this.showLogout = this.isLoggedAR && window.location.href.includes('reserved-area');    
  }

  async login() {    
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px',
      id: 'modal-ar-login'
    });
    dialogRef.afterClosed().subscribe( async () => {
      this.checkPoolStatus();
    })
  }

  async logout() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant('AR.LOGOUT_CONFIRM'), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      if(result){
        const r = await this.amplifyService.logout('ar');
        this.amplifyService.removeTokenAR(this.COOKIE_TOKEN_AR);
        this.router.navigate(['/']);
        this.checkPoolStatus();    
      }
      else{
        dialogRef.close();
      }
    });
  }  

  resevedArea(){
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate(['/', 'reserved-area']);
    else window.location.reload();
  }
}
