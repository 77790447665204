import { AfterViewInit, Component, Input, signal, WritableSignal } from '@angular/core';
import { ISitePageMediaSection, PageRoots } from '../../models/pages';
import { CommonModule } from '@angular/common';
import { SafePipe } from '../../pipes/safe.pipe';
import { ButtonTheme, IMediaSectionItem, INewsReference, LocaleEnum, NewsTypesWithIconEnum } from '../../models/common';
import { VideoEmbedderComponent } from "../video-embedder/video-embedder.component";
import { DEFAULT_VIDEO_HEIGHT, DEFAULT_VIDEO_WIDTH, MOBILE_BREAKPOINT } from '../../models/constants';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { getYoutubeID, roundTo } from '../../services/utilities.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { KebabCasePipe } from "../../pipes/kebab-case.pipe";
import { SectionNewsHeadlinesComponent } from '../section-news-headlines/section-news-headlines.component';

@Component({
  selector: 'app-media-section',
  templateUrl: './media-section.component.html',
  styleUrl: './media-section.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, SafePipe, KebabCasePipe, VideoEmbedderComponent, PromAnimatedButtonComponent, SectionNewsHeadlinesComponent],
})
export class MediaSectionComponent implements AfterViewInit {

  readonly supportedIcons: NewsTypesWithIconEnum[] = Object.values(NewsTypesWithIconEnum);
  readonly articleRoot: string = '/about-us/insights/article';
  
  @Input() mediaSection?: ISitePageMediaSection;
  @Input() pageRoot?: PageRoots;
  @Input() insightList?: INewsReference[];
  @Input() currentLang: string = LocaleEnum.IT;

  isViewInitialized: boolean = false;

  openVideoSignal: WritableSignal<boolean> = signal(false);

  ngAfterViewInit () {
    this.isViewInitialized = true;
  }

  get playSymbol(): string {
    if (this.pageRoot === "careers") {
      return "/assets/icons/play_symbol_yellow.png";
    } else {
      return "/assets/icons/play_symbol.png";
    }
  }

  getButtonTheme(item: IMediaSectionItem): ButtonTheme {
    if (this.pageRoot === "solutions" && item.type === "text+link") {
      return 'deep-blue';
    }
    if (this.pageRoot === "careers") {
      return 'fluo-yellow';
    }

    switch (item.theme) {
      case "white": return 'deep-blue';
      case "deep-blue": return 'fluo-green';
      case "fluo-green": return 'deep-blue';
      case "electric-blue": return 'fluo-green';
      case "sim-red": return 'sim-white';
      default: return 'fluo-green';
    }

    return 'fluo-green';
  }

  videoWidth(index: number) {
    if (!this.isViewInitialized) {
      const mediaSize = this.mediaSection?.items?.[index]?.size;
      switch (mediaSize) {
        case "full": return window.innerWidth ?? DEFAULT_VIDEO_WIDTH;
        case "half": return roundTo(window.innerWidth / 2, 0) ?? DEFAULT_VIDEO_WIDTH;
        case "third": return roundTo(window.innerWidth / 3, 0) ?? DEFAULT_VIDEO_WIDTH;
      }
    }
    return document.getElementById(`media-section-item-${index}`)?.clientWidth;
  }

  videoHeight(index: number) {
    const maxHeight = DEFAULT_VIDEO_HEIGHT;
    if (!this.isViewInitialized) return maxHeight;
    const clientHeight = document.getElementById(`media-section-item-${index + 1}`)?.clientHeight;
    return clientHeight ? Math.min(clientHeight, maxHeight) : maxHeight;
  }

  activeVideoSection?: IMediaSectionItem;
  openVideo(item: IMediaSectionItem) {
    this.activeVideoSection = item;
    // window.open(item.url, '_blank');
    // console.log("Open video", item);
    if (item.type !== "video" || !item.url || !item.cover) {
      return;
    }
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      window.open("https://youtu.be/" + getYoutubeID(item.url), '_blank');
      return;
    }
    this.openVideoSignal.set(true);
  }

  getInsightFooterPosition(mediaIndex: number): 'absolute' | 'relative' {
    const el = document.getElementById(`news-info-${mediaIndex}`)?.getBoundingClientRect();
    if ((el?.height ?? 0) > 200) {
      return 'relative';
    }
    return 'absolute';
  }
}
