import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocaleEnum } from '../../models/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  standalone: true,
  imports: [TranslateModule, RouterModule],
})
export class PageNotFoundComponent implements OnInit {

  get currentLang(): string {
    return this.translate.currentLang?.toLowerCase() || LocaleEnum.IT;
  }

  constructor(
    private readonly translate: TranslateService,
  ) { }

  ngOnInit(): void {

  }

}
