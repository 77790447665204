import { Injectable } from '@angular/core';
import { IHeaderRow } from '../models/header';
import { ApiService } from './api.service';
import { Throttle } from 'lodash-decorators';
import { deepClone } from './utilities.service';


@Injectable({
  providedIn: 'root'
})
export class HeaderDataService {

  private headerData?: IHeaderRow[];

  constructor(
    private readonly api: ApiService,
  ) { }

  private async getHeaderData(options?: {ignoreCache?: boolean}): Promise<IHeaderRow[]> {
    if (!this.headerData || options?.ignoreCache) {
      try {
        this.headerData = await this.api.header.get();
        // console.log('Got header data', this.headerData);
      } catch (ex) {
        console.error('Error getting header data', ex);
      }
    } else {
      // console.log('Using cached header data', this.headerData);
    }

    if (this.headerData && !this.headerData.find((x: any) => x.slug === 'sim')) {
      this.headerData.push(
        {slug: 'sim', label: 'ADVISOR SIM', url: '/sim'},
      );
    }

    return deepClone(this.headerData);
  }

  @Throttle(500)
  async getFormattedHeaderData(): Promise<IHeaderRow[]> {
    let res = this.headerData;
    if (!res) {
      res = await this.getHeaderData({ignoreCache: true});
    }

    res = deepClone(this.headerData);
    res?.forEach(fl => {
      fl.children?.forEach(sl => {
        if (fl.slug === 'expertise' && sl.slug === 'exp_ds') {
          sl.label = sl.label.replaceAll(" & ", "_&_").replaceAll(" ", "<br>").replaceAll("_", " ");
        } else {
          sl.label = sl.label.replace(" &", "<br>&");
        }
        
        // sl.children?.forEach(tl => {
        //   tl.label = tl.label.replaceAll(" &", "<br>&");
        // });
      });
    });

    return res as any;
  }

  clearHeaderData() {
    this.headerData = undefined;
  }

  async findLabelBySlug(slug: string) {
    while (!this.headerData) {
      await this.getHeaderData();
    }

    const res = this.recursiveFindHeaderRowBySlug(slug, this.headerData);
    // console.log(res);
    return res?.label;
  }

  async findHeaderRowBySlug(slug: string) {
    while (!this.headerData) {
      await this.getHeaderData();
    }

    return this.recursiveFindHeaderRowBySlug(slug, this.headerData, {extraDetails: true});
  }

  private recursiveFindHeaderRowBySlug(slug: string, data: IHeaderRow[], options?: {extraDetails?: boolean}): IHeaderRow | undefined {
    for (const el of data) {
      if (el.slug === slug) {
        return el;
      }

      if (el.children) {
        const res = this.recursiveFindHeaderRowBySlug(slug, el.children, options);

        if (res && options?.extraDetails) {
          if ((res as any).parent) {
            (res as any).grandparent = el;
          } else {
            (res as any).parent = el;
          }
          return res;
        }
      }
    }

    return undefined;
  };
}
