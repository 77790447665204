export interface IARDocumentFolder {
    _id?: string,
    path?: string,
    parent?: string,
    name?: string,
    children?: IARDocumentFolder[],
    sortOrder?: number,
    createdAt?: Date,
    updatedAt?: Date,
    attachments: IARAttachment[]
}

export interface IARAttachment {
    id?: string,
    /** DocumentFolder che contiene l'allegato */
    folder?: IARDocumentFolder | string,
    name?: string,
    title?: string,
    published?: boolean,
    subscriptionLimit?: Date,
    createdAt?: Date,
    updatedAt?: Date,
    /** Uri della risorsa per il download HTTP(S) */
    uri?: string,
}

export const paths = {
    //login: 'login',

    home: 'home',

    /** who we are */
    aboutUs: 'about-us',
    ourTeam: 'our-team',
    socialResponsibility: 'social-responsibility',
    promHQ: 'prom-hq',
    ourNetwork: 'our-network',
    countryPage: 'country-page',
    ourPartnership: 'our-partnership',

    /** our expertise */
    riskPlanningControl: 'risk-planning-control',
    financialDistribution: 'financial-distribution',
    strategicAdvisory: 'strategic-advisory',
    financialAdvisory: 'financial-advisory',
        anteo: 'financial-advisory_anteo',
        anteoArchive: 'financial-advisory_anteo-archive',
        anteoArticle: 'financial-advisory_anteo-article',
        financialAdvisoryConsultancy: 'financial-advisory_consultancy',
        societaryOrgans: 'financial-advisory_our-team',    // financial-advisory_team
                                    // 29F8 ;	002F ;	MA	#* ( ⧸ → / ) BIG SOLIDUS → SOLIDUS	#  
                                    // https://www.compart.com/en/unicode/U+29F8
                                    // Codice HTML: &#10744;
                                    // Codice CSS: #css_example:after {  content: "\29F8";  } 
                                    // Codice BASH: $ echo -e '\u29F8'
        informationPaths: 'financial-advisory_information-paths',
        informationPathsArchive: 'financial-advisory_information-paths-archive',
    esgClimate: 'esg-climate',
    techSolutions: 'tech-solutions',
    riskTechAndWealthTech: 'risk-tech-and-wealth-tech', // redirects to " techSolutions: 'tech-solutions' "
    saasCloud: 'saas-cloud',
        promAWS: 'prometeia-and-aws',
        promMsAzure: 'prometeia-and-ms-azure',
    appliedDataScienceAndAdvancedAnalytics: 'applied-data-science-and-advanced-analytics',
    riskTech: 'risk-tech',
    wealthTech: 'wealth-tech',

    discoverMore: "discover-more",

    // RnD: 'R-and-D',
    research_bi_reporting: 'research-bi-reporting', //Research, BI & Reporting
    ourStory: 'our-story',
    dataScience: 'data-science',

    // insights: 'insights',
    news: 'news',
    newsArticle: 'news-article',
    newsletter: 'newsletter',
    trendingTopics: 'trending-topics',
    trendingTopicsArticle: 'trending-topics-article',
    trendingTopicsArchive: 'trending-topics-archive',

    /** career */
    ourCulture: 'our-culture',
    masterCorsiLaurea: 'master-corsi-laurea',
    careers: 'careers',
    jobOffer: 'job-offers',
    offer: 'offer',
    alumni: 'alumni',
    alumniUnsubscribe: 'alumni/unsubscribe',
    alumniStories: 'alumni-stories',
    promLife: 'prom-life',

    reservedArea: 'reserved-area',
    article: 'article',


    activateAccount: 'activate-account',
    searchResults: 'search-results',
    pageNotFound: '404'
}