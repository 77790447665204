<!-- <ng-container *ngIf="!data">
    <app-not-found-data *ngIf="notFound"></app-not-found-data>
</ng-container> -->

<div class="reserved-area prom-page  ">
    <div class="section-max-content">
        @if (showTopButtons) {
            <div class="top-buttons">
                <button mat-icon-button (click)="logout()"><mat-icon aria-hidden="false" aria-label="login" fontIcon="logout"></mat-icon></button>
            </div>
        }
        
        <div class="reserved-area--content {{showTopButtons ? 'with-top-buttons' : ''}}">
           
           <!--  <h4 class="breadcrumb-text neutra2_demi" *ngIf="componentsServices.getARnotLogged()">Attenzione, area riservata. Accedere per visualizzare i contenuti</h4> -->
            <ng-container>
               <a class="breadcrumb-text" [routerLink]="['/'+currentLang, route_ar]">Documenti</a>
               @for (item of breadcrumbs; track $index) {
                <span> 
                    <span class="material-icons breadcrumb-icon"> arrow_forward_ios </span>
                    <a class="breadcrumb-text neutra2_demi" [routerLink]="['/'+currentLang, route_ar, {folder:item.path}]">{{item.name}}</a>
                </span> 
               }
                
               
                <hr>
                <table class="folder-explorer">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="prom-caption___lato-regular">Nome</th>
                            <th class="prom-caption___lato-regular">Tipo</th>
                            <th class="prom-caption___lato-regular text-align-right">Ultima modifica</th>
                        </tr>
                    </thead>
                    <tbody>
                        @if(currentFolder?.children?.length){
                            <ng-container >
                                @for ( item of currentFolder?.children; track $index) {
                                    <tr class="tb-row {{item.children?.length ? 'has-children': ''}}">
                                        <td><span class="material-icons icon-folder"> folder_open </span></td>
                                        <td>
                                            <a class="library-item neutra2_demi" [routerLink]="['/'+currentLang, route_ar , {folder:item.path}]">{{item.name}}</a>
                                        </td>
                                        <td class="prom-caption___lato-regular "> Folder </td>
                                    </tr>
                                }
                                
                            </ng-container>
                        }
                        
                        @if(currentFolder?.attachments?.length){
                            <ng-container>
                                @for (item of currentFolder?.attachments; track $index) {
                                    <tr>
                                        <td><span class="material-icons icon-file"> insert_drive_file </span></td>
                                        <td>
                                            <a class="library-item neutra2_demi" [href]="item.uri">{{item.title}}</a>
                                        </td>
                                        <td class="prom-caption___lato-regular type-file"> {{getExtention(item.name || '')}} </td>
                                        <td class="prom-caption___lato-regular text-align-right">{{item.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                    </tr>
                                }
                                
                            </ng-container>
                        }
                        @if(!currentFolder?.children?.length && !currentFolder?.attachments?.length){
                            <ng-container>
                                <tr><td></td><td>Nessun file</td></tr>
                            </ng-container>
                        }
                        
                    </tbody>
                </table>                
            </ng-container>
        </div>
    </div>
</div>
