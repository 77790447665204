// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var AssociateSoftwareTokenException;
(function (AssociateSoftwareTokenException) {
  AssociateSoftwareTokenException["ConcurrentModificationException"] = "ConcurrentModificationException";
  AssociateSoftwareTokenException["ForbiddenException"] = "ForbiddenException";
  AssociateSoftwareTokenException["InternalErrorException"] = "InternalErrorException";
  AssociateSoftwareTokenException["InvalidParameterException"] = "InvalidParameterException";
  AssociateSoftwareTokenException["NotAuthorizedException"] = "NotAuthorizedException";
  AssociateSoftwareTokenException["ResourceNotFoundException"] = "ResourceNotFoundException";
  AssociateSoftwareTokenException["SoftwareTokenMFANotFoundException"] = "SoftwareTokenMFANotFoundException";
})(AssociateSoftwareTokenException || (AssociateSoftwareTokenException = {}));
var ChangePasswordException;
(function (ChangePasswordException) {
  ChangePasswordException["ForbiddenException"] = "ForbiddenException";
  ChangePasswordException["InternalErrorException"] = "InternalErrorException";
  ChangePasswordException["InvalidParameterException"] = "InvalidParameterException";
  ChangePasswordException["InvalidPasswordException"] = "InvalidPasswordException";
  ChangePasswordException["LimitExceededException"] = "LimitExceededException";
  ChangePasswordException["NotAuthorizedException"] = "NotAuthorizedException";
  ChangePasswordException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  ChangePasswordException["ResourceNotFoundException"] = "ResourceNotFoundException";
  ChangePasswordException["TooManyRequestsException"] = "TooManyRequestsException";
  ChangePasswordException["UserNotConfirmedException"] = "UserNotConfirmedException";
  ChangePasswordException["UserNotFoundException"] = "UserNotFoundException";
})(ChangePasswordException || (ChangePasswordException = {}));
var ConfirmDeviceException;
(function (ConfirmDeviceException) {
  ConfirmDeviceException["ForbiddenException"] = "ForbiddenException";
  ConfirmDeviceException["InternalErrorException"] = "InternalErrorException";
  ConfirmDeviceException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  ConfirmDeviceException["InvalidParameterException"] = "InvalidParameterException";
  ConfirmDeviceException["InvalidPasswordException"] = "InvalidPasswordException";
  ConfirmDeviceException["InvalidUserPoolConfigurationException"] = "InvalidUserPoolConfigurationException";
  ConfirmDeviceException["NotAuthorizedException"] = "NotAuthorizedException";
  ConfirmDeviceException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  ConfirmDeviceException["ResourceNotFoundException"] = "ResourceNotFoundException";
  ConfirmDeviceException["TooManyRequestsException"] = "TooManyRequestsException";
  ConfirmDeviceException["UsernameExistsException"] = "UsernameExistsException";
  ConfirmDeviceException["UserNotConfirmedException"] = "UserNotConfirmedException";
  ConfirmDeviceException["UserNotFoundException"] = "UserNotFoundException";
})(ConfirmDeviceException || (ConfirmDeviceException = {}));
var ConfirmForgotPasswordException;
(function (ConfirmForgotPasswordException) {
  ConfirmForgotPasswordException["CodeMismatchException"] = "CodeMismatchException";
  ConfirmForgotPasswordException["ExpiredCodeException"] = "ExpiredCodeException";
  ConfirmForgotPasswordException["ForbiddenException"] = "ForbiddenException";
  ConfirmForgotPasswordException["InternalErrorException"] = "InternalErrorException";
  ConfirmForgotPasswordException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  ConfirmForgotPasswordException["InvalidParameterException"] = "InvalidParameterException";
  ConfirmForgotPasswordException["InvalidPasswordException"] = "InvalidPasswordException";
  ConfirmForgotPasswordException["LimitExceededException"] = "LimitExceededException";
  ConfirmForgotPasswordException["NotAuthorizedException"] = "NotAuthorizedException";
  ConfirmForgotPasswordException["ResourceNotFoundException"] = "ResourceNotFoundException";
  ConfirmForgotPasswordException["TooManyFailedAttemptsException"] = "TooManyFailedAttemptsException";
  ConfirmForgotPasswordException["TooManyRequestsException"] = "TooManyRequestsException";
  ConfirmForgotPasswordException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  ConfirmForgotPasswordException["UserLambdaValidationException"] = "UserLambdaValidationException";
  ConfirmForgotPasswordException["UserNotConfirmedException"] = "UserNotConfirmedException";
  ConfirmForgotPasswordException["UserNotFoundException"] = "UserNotFoundException";
})(ConfirmForgotPasswordException || (ConfirmForgotPasswordException = {}));
var ConfirmSignUpException;
(function (ConfirmSignUpException) {
  ConfirmSignUpException["AliasExistsException"] = "AliasExistsException";
  ConfirmSignUpException["CodeMismatchException"] = "CodeMismatchException";
  ConfirmSignUpException["ExpiredCodeException"] = "ExpiredCodeException";
  ConfirmSignUpException["ForbiddenException"] = "ForbiddenException";
  ConfirmSignUpException["InternalErrorException"] = "InternalErrorException";
  ConfirmSignUpException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  ConfirmSignUpException["InvalidParameterException"] = "InvalidParameterException";
  ConfirmSignUpException["LimitExceededException"] = "LimitExceededException";
  ConfirmSignUpException["NotAuthorizedException"] = "NotAuthorizedException";
  ConfirmSignUpException["ResourceNotFoundException"] = "ResourceNotFoundException";
  ConfirmSignUpException["TooManyFailedAttemptsException"] = "TooManyFailedAttemptsException";
  ConfirmSignUpException["TooManyRequestsException"] = "TooManyRequestsException";
  ConfirmSignUpException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  ConfirmSignUpException["UserLambdaValidationException"] = "UserLambdaValidationException";
  ConfirmSignUpException["UserNotFoundException"] = "UserNotFoundException";
})(ConfirmSignUpException || (ConfirmSignUpException = {}));
var DeleteUserAttributesException;
(function (DeleteUserAttributesException) {
  DeleteUserAttributesException["ForbiddenException"] = "ForbiddenException";
  DeleteUserAttributesException["InternalErrorException"] = "InternalErrorException";
  DeleteUserAttributesException["InvalidParameterException"] = "InvalidParameterException";
  DeleteUserAttributesException["NotAuthorizedException"] = "NotAuthorizedException";
  DeleteUserAttributesException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  DeleteUserAttributesException["ResourceNotFoundException"] = "ResourceNotFoundException";
  DeleteUserAttributesException["TooManyRequestsException"] = "TooManyRequestsException";
  DeleteUserAttributesException["UserNotConfirmedException"] = "UserNotConfirmedException";
  DeleteUserAttributesException["UserNotFoundException"] = "UserNotFoundException";
})(DeleteUserAttributesException || (DeleteUserAttributesException = {}));
var DeleteUserException;
(function (DeleteUserException) {
  DeleteUserException["ForbiddenException"] = "ForbiddenException";
  DeleteUserException["InternalErrorException"] = "InternalErrorException";
  DeleteUserException["InvalidParameterException"] = "InvalidParameterException";
  DeleteUserException["NotAuthorizedException"] = "NotAuthorizedException";
  DeleteUserException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  DeleteUserException["ResourceNotFoundException"] = "ResourceNotFoundException";
  DeleteUserException["TooManyRequestsException"] = "TooManyRequestsException";
  DeleteUserException["UserNotConfirmedException"] = "UserNotConfirmedException";
  DeleteUserException["UserNotFoundException"] = "UserNotFoundException";
})(DeleteUserException || (DeleteUserException = {}));
var ForgetDeviceException;
(function (ForgetDeviceException) {
  ForgetDeviceException["ForbiddenException"] = "ForbiddenException";
  ForgetDeviceException["InternalErrorException"] = "InternalErrorException";
  ForgetDeviceException["InvalidParameterException"] = "InvalidParameterException";
  ForgetDeviceException["InvalidUserPoolConfigurationException"] = "InvalidUserPoolConfigurationException";
  ForgetDeviceException["NotAuthorizedException"] = "NotAuthorizedException";
  ForgetDeviceException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  ForgetDeviceException["ResourceNotFoundException"] = "ResourceNotFoundException";
  ForgetDeviceException["TooManyRequestsException"] = "TooManyRequestsException";
  ForgetDeviceException["UserNotConfirmedException"] = "UserNotConfirmedException";
  ForgetDeviceException["UserNotFoundException"] = "UserNotFoundException";
})(ForgetDeviceException || (ForgetDeviceException = {}));
var ForgotPasswordException;
(function (ForgotPasswordException) {
  ForgotPasswordException["CodeDeliveryFailureException"] = "CodeDeliveryFailureException";
  ForgotPasswordException["ForbiddenException"] = "ForbiddenException";
  ForgotPasswordException["InternalErrorException"] = "InternalErrorException";
  ForgotPasswordException["InvalidEmailRoleAccessPolicyException"] = "InvalidEmailRoleAccessPolicyException";
  ForgotPasswordException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  ForgotPasswordException["InvalidParameterException"] = "InvalidParameterException";
  ForgotPasswordException["InvalidSmsRoleAccessPolicyException"] = "InvalidSmsRoleAccessPolicyException";
  ForgotPasswordException["InvalidSmsRoleTrustRelationshipException"] = "InvalidSmsRoleTrustRelationshipException";
  ForgotPasswordException["LimitExceededException"] = "LimitExceededException";
  ForgotPasswordException["NotAuthorizedException"] = "NotAuthorizedException";
  ForgotPasswordException["ResourceNotFoundException"] = "ResourceNotFoundException";
  ForgotPasswordException["TooManyRequestsException"] = "TooManyRequestsException";
  ForgotPasswordException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  ForgotPasswordException["UserLambdaValidationException"] = "UserLambdaValidationException";
  ForgotPasswordException["UserNotFoundException"] = "UserNotFoundException";
})(ForgotPasswordException || (ForgotPasswordException = {}));
var GetUserException;
(function (GetUserException) {
  GetUserException["ForbiddenException"] = "ForbiddenException";
  GetUserException["InternalErrorException"] = "InternalErrorException";
  GetUserException["InvalidParameterException"] = "InvalidParameterException";
  GetUserException["NotAuthorizedException"] = "NotAuthorizedException";
  GetUserException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  GetUserException["ResourceNotFoundException"] = "ResourceNotFoundException";
  GetUserException["TooManyRequestsException"] = "TooManyRequestsException";
  GetUserException["UserNotConfirmedException"] = "UserNotConfirmedException";
  GetUserException["UserNotFoundException"] = "UserNotFoundException";
})(GetUserException || (GetUserException = {}));
var GetIdException;
(function (GetIdException) {
  GetIdException["ExternalServiceException"] = "ExternalServiceException";
  GetIdException["InternalErrorException"] = "InternalErrorException";
  GetIdException["InvalidParameterException"] = "InvalidParameterException";
  GetIdException["LimitExceededException"] = "LimitExceededException";
  GetIdException["NotAuthorizedException"] = "NotAuthorizedException";
  GetIdException["ResourceConflictException"] = "ResourceConflictException";
  GetIdException["ResourceNotFoundException"] = "ResourceNotFoundException";
  GetIdException["TooManyRequestsException"] = "TooManyRequestsException";
})(GetIdException || (GetIdException = {}));
var GetCredentialsForIdentityException;
(function (GetCredentialsForIdentityException) {
  GetCredentialsForIdentityException["ExternalServiceException"] = "ExternalServiceException";
  GetCredentialsForIdentityException["InternalErrorException"] = "InternalErrorException";
  GetCredentialsForIdentityException["InvalidIdentityPoolConfigurationException"] = "InvalidIdentityPoolConfigurationException";
  GetCredentialsForIdentityException["InvalidParameterException"] = "InvalidParameterException";
  GetCredentialsForIdentityException["NotAuthorizedException"] = "NotAuthorizedException";
  GetCredentialsForIdentityException["ResourceConflictException"] = "ResourceConflictException";
  GetCredentialsForIdentityException["ResourceNotFoundException"] = "ResourceNotFoundException";
  GetCredentialsForIdentityException["TooManyRequestsException"] = "TooManyRequestsException";
})(GetCredentialsForIdentityException || (GetCredentialsForIdentityException = {}));
var GetUserAttributeVerificationException;
(function (GetUserAttributeVerificationException) {
  GetUserAttributeVerificationException["CodeDeliveryFailureException"] = "CodeDeliveryFailureException";
  GetUserAttributeVerificationException["ForbiddenException"] = "ForbiddenException";
  GetUserAttributeVerificationException["InternalErrorException"] = "InternalErrorException";
  GetUserAttributeVerificationException["InvalidEmailRoleAccessPolicyException"] = "InvalidEmailRoleAccessPolicyException";
  GetUserAttributeVerificationException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  GetUserAttributeVerificationException["InvalidParameterException"] = "InvalidParameterException";
  GetUserAttributeVerificationException["InvalidSmsRoleAccessPolicyException"] = "InvalidSmsRoleAccessPolicyException";
  GetUserAttributeVerificationException["InvalidSmsRoleTrustRelationshipException"] = "InvalidSmsRoleTrustRelationshipException";
  GetUserAttributeVerificationException["LimitExceededException"] = "LimitExceededException";
  GetUserAttributeVerificationException["NotAuthorizedException"] = "NotAuthorizedException";
  GetUserAttributeVerificationException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  GetUserAttributeVerificationException["ResourceNotFoundException"] = "ResourceNotFoundException";
  GetUserAttributeVerificationException["TooManyRequestsException"] = "TooManyRequestsException";
  GetUserAttributeVerificationException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  GetUserAttributeVerificationException["UserLambdaValidationException"] = "UserLambdaValidationException";
  GetUserAttributeVerificationException["UserNotConfirmedException"] = "UserNotConfirmedException";
  GetUserAttributeVerificationException["UserNotFoundException"] = "UserNotFoundException";
})(GetUserAttributeVerificationException || (GetUserAttributeVerificationException = {}));
var GlobalSignOutException;
(function (GlobalSignOutException) {
  GlobalSignOutException["ForbiddenException"] = "ForbiddenException";
  GlobalSignOutException["InternalErrorException"] = "InternalErrorException";
  GlobalSignOutException["InvalidParameterException"] = "InvalidParameterException";
  GlobalSignOutException["NotAuthorizedException"] = "NotAuthorizedException";
  GlobalSignOutException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  GlobalSignOutException["ResourceNotFoundException"] = "ResourceNotFoundException";
  GlobalSignOutException["TooManyRequestsException"] = "TooManyRequestsException";
  GlobalSignOutException["UserNotConfirmedException"] = "UserNotConfirmedException";
})(GlobalSignOutException || (GlobalSignOutException = {}));
var InitiateAuthException;
(function (InitiateAuthException) {
  InitiateAuthException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  InitiateAuthException["ForbiddenException"] = "ForbiddenException";
  InitiateAuthException["InternalErrorException"] = "InternalErrorException";
  InitiateAuthException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  InitiateAuthException["InvalidParameterException"] = "InvalidParameterException";
  InitiateAuthException["InvalidSmsRoleAccessPolicyException"] = "InvalidSmsRoleAccessPolicyException";
  InitiateAuthException["InvalidSmsRoleTrustRelationshipException"] = "InvalidSmsRoleTrustRelationshipException";
  InitiateAuthException["InvalidUserPoolConfigurationException"] = "InvalidUserPoolConfigurationException";
  InitiateAuthException["NotAuthorizedException"] = "NotAuthorizedException";
  InitiateAuthException["ResourceNotFoundException"] = "ResourceNotFoundException";
  InitiateAuthException["TooManyRequestsException"] = "TooManyRequestsException";
  InitiateAuthException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  InitiateAuthException["UserLambdaValidationException"] = "UserLambdaValidationException";
  InitiateAuthException["UserNotConfirmedException"] = "UserNotConfirmedException";
  InitiateAuthException["UserNotFoundException"] = "UserNotFoundException";
})(InitiateAuthException || (InitiateAuthException = {}));
var ResendConfirmationException;
(function (ResendConfirmationException) {
  ResendConfirmationException["CodeDeliveryFailureException"] = "CodeDeliveryFailureException";
  ResendConfirmationException["ForbiddenException"] = "ForbiddenException";
  ResendConfirmationException["InternalErrorException"] = "InternalErrorException";
  ResendConfirmationException["InvalidEmailRoleAccessPolicyException"] = "InvalidEmailRoleAccessPolicyException";
  ResendConfirmationException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  ResendConfirmationException["InvalidParameterException"] = "InvalidParameterException";
  ResendConfirmationException["InvalidSmsRoleAccessPolicyException"] = "InvalidSmsRoleAccessPolicyException";
  ResendConfirmationException["InvalidSmsRoleTrustRelationshipException"] = "InvalidSmsRoleTrustRelationshipException";
  ResendConfirmationException["LimitExceededException"] = "LimitExceededException";
  ResendConfirmationException["NotAuthorizedException"] = "NotAuthorizedException";
  ResendConfirmationException["ResourceNotFoundException"] = "ResourceNotFoundException";
  ResendConfirmationException["TooManyRequestsException"] = "TooManyRequestsException";
  ResendConfirmationException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  ResendConfirmationException["UserLambdaValidationException"] = "UserLambdaValidationException";
  ResendConfirmationException["UserNotFoundException"] = "UserNotFoundException";
})(ResendConfirmationException || (ResendConfirmationException = {}));
var RespondToAuthChallengeException;
(function (RespondToAuthChallengeException) {
  RespondToAuthChallengeException["AliasExistsException"] = "AliasExistsException";
  RespondToAuthChallengeException["CodeMismatchException"] = "CodeMismatchException";
  RespondToAuthChallengeException["ExpiredCodeException"] = "ExpiredCodeException";
  RespondToAuthChallengeException["ForbiddenException"] = "ForbiddenException";
  RespondToAuthChallengeException["InternalErrorException"] = "InternalErrorException";
  RespondToAuthChallengeException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  RespondToAuthChallengeException["InvalidParameterException"] = "InvalidParameterException";
  RespondToAuthChallengeException["InvalidPasswordException"] = "InvalidPasswordException";
  RespondToAuthChallengeException["InvalidSmsRoleAccessPolicyException"] = "InvalidSmsRoleAccessPolicyException";
  RespondToAuthChallengeException["InvalidSmsRoleTrustRelationshipException"] = "InvalidSmsRoleTrustRelationshipException";
  RespondToAuthChallengeException["InvalidUserPoolConfigurationException"] = "InvalidUserPoolConfigurationException";
  RespondToAuthChallengeException["MFAMethodNotFoundException"] = "MFAMethodNotFoundException";
  RespondToAuthChallengeException["NotAuthorizedException"] = "NotAuthorizedException";
  RespondToAuthChallengeException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  RespondToAuthChallengeException["ResourceNotFoundException"] = "ResourceNotFoundException";
  RespondToAuthChallengeException["SoftwareTokenMFANotFoundException"] = "SoftwareTokenMFANotFoundException";
  RespondToAuthChallengeException["TooManyRequestsException"] = "TooManyRequestsException";
  RespondToAuthChallengeException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  RespondToAuthChallengeException["UserLambdaValidationException"] = "UserLambdaValidationException";
  RespondToAuthChallengeException["UserNotConfirmedException"] = "UserNotConfirmedException";
  RespondToAuthChallengeException["UserNotFoundException"] = "UserNotFoundException";
})(RespondToAuthChallengeException || (RespondToAuthChallengeException = {}));
var SetUserMFAPreferenceException;
(function (SetUserMFAPreferenceException) {
  SetUserMFAPreferenceException["ForbiddenException"] = "ForbiddenException";
  SetUserMFAPreferenceException["InternalErrorException"] = "InternalErrorException";
  SetUserMFAPreferenceException["InvalidParameterException"] = "InvalidParameterException";
  SetUserMFAPreferenceException["NotAuthorizedException"] = "NotAuthorizedException";
  SetUserMFAPreferenceException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  SetUserMFAPreferenceException["ResourceNotFoundException"] = "ResourceNotFoundException";
  SetUserMFAPreferenceException["UserNotConfirmedException"] = "UserNotConfirmedException";
  SetUserMFAPreferenceException["UserNotFoundException"] = "UserNotFoundException";
})(SetUserMFAPreferenceException || (SetUserMFAPreferenceException = {}));
var SignUpException;
(function (SignUpException) {
  SignUpException["CodeDeliveryFailureException"] = "CodeDeliveryFailureException";
  SignUpException["InternalErrorException"] = "InternalErrorException";
  SignUpException["InvalidEmailRoleAccessPolicyException"] = "InvalidEmailRoleAccessPolicyException";
  SignUpException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  SignUpException["InvalidParameterException"] = "InvalidParameterException";
  SignUpException["InvalidPasswordException"] = "InvalidPasswordException";
  SignUpException["InvalidSmsRoleAccessPolicyException"] = "InvalidSmsRoleAccessPolicyException";
  SignUpException["InvalidSmsRoleTrustRelationshipException"] = "InvalidSmsRoleTrustRelationshipException";
  SignUpException["NotAuthorizedException"] = "NotAuthorizedException";
  SignUpException["ResourceNotFoundException"] = "ResourceNotFoundException";
  SignUpException["TooManyRequestsException"] = "TooManyRequestsException";
  SignUpException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  SignUpException["UserLambdaValidationException"] = "UserLambdaValidationException";
  SignUpException["UsernameExistsException"] = "UsernameExistsException";
})(SignUpException || (SignUpException = {}));
var UpdateUserAttributesException;
(function (UpdateUserAttributesException) {
  UpdateUserAttributesException["AliasExistsException"] = "AliasExistsException";
  UpdateUserAttributesException["CodeDeliveryFailureException"] = "CodeDeliveryFailureException";
  UpdateUserAttributesException["CodeMismatchException"] = "CodeMismatchException";
  UpdateUserAttributesException["ExpiredCodeException"] = "ExpiredCodeException";
  UpdateUserAttributesException["ForbiddenException"] = "ForbiddenException";
  UpdateUserAttributesException["InternalErrorException"] = "InternalErrorException";
  UpdateUserAttributesException["InvalidEmailRoleAccessPolicyException"] = "InvalidEmailRoleAccessPolicyException";
  UpdateUserAttributesException["InvalidLambdaResponseException"] = "InvalidLambdaResponseException";
  UpdateUserAttributesException["InvalidParameterException"] = "InvalidParameterException";
  UpdateUserAttributesException["InvalidSmsRoleAccessPolicyException"] = "InvalidSmsRoleAccessPolicyException";
  UpdateUserAttributesException["InvalidSmsRoleTrustRelationshipException"] = "InvalidSmsRoleTrustRelationshipException";
  UpdateUserAttributesException["NotAuthorizedException"] = "NotAuthorizedException";
  UpdateUserAttributesException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  UpdateUserAttributesException["ResourceNotFoundException"] = "ResourceNotFoundException";
  UpdateUserAttributesException["TooManyRequestsException"] = "TooManyRequestsException";
  UpdateUserAttributesException["UnexpectedLambdaException"] = "UnexpectedLambdaException";
  UpdateUserAttributesException["UserLambdaValidationException"] = "UserLambdaValidationException";
  UpdateUserAttributesException["UserNotConfirmedException"] = "UserNotConfirmedException";
  UpdateUserAttributesException["UserNotFoundException"] = "UserNotFoundException";
})(UpdateUserAttributesException || (UpdateUserAttributesException = {}));
var VerifySoftwareTokenException;
(function (VerifySoftwareTokenException) {
  VerifySoftwareTokenException["CodeMismatchException"] = "CodeMismatchException";
  VerifySoftwareTokenException["EnableSoftwareTokenMFAException"] = "EnableSoftwareTokenMFAException";
  VerifySoftwareTokenException["ForbiddenException"] = "ForbiddenException";
  VerifySoftwareTokenException["InternalErrorException"] = "InternalErrorException";
  VerifySoftwareTokenException["InvalidParameterException"] = "InvalidParameterException";
  VerifySoftwareTokenException["InvalidUserPoolConfigurationException"] = "InvalidUserPoolConfigurationException";
  VerifySoftwareTokenException["NotAuthorizedException"] = "NotAuthorizedException";
  VerifySoftwareTokenException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  VerifySoftwareTokenException["ResourceNotFoundException"] = "ResourceNotFoundException";
  VerifySoftwareTokenException["SoftwareTokenMFANotFoundException"] = "SoftwareTokenMFANotFoundException";
  VerifySoftwareTokenException["TooManyRequestsException"] = "TooManyRequestsException";
  VerifySoftwareTokenException["UserNotConfirmedException"] = "UserNotConfirmedException";
  VerifySoftwareTokenException["UserNotFoundException"] = "UserNotFoundException";
})(VerifySoftwareTokenException || (VerifySoftwareTokenException = {}));
var VerifyUserAttributeException;
(function (VerifyUserAttributeException) {
  VerifyUserAttributeException["AliasExistsException"] = "AliasExistsException";
  VerifyUserAttributeException["CodeMismatchException"] = "CodeMismatchException";
  VerifyUserAttributeException["ExpiredCodeException"] = "ExpiredCodeException";
  VerifyUserAttributeException["ForbiddenException"] = "ForbiddenException";
  VerifyUserAttributeException["InternalErrorException"] = "InternalErrorException";
  VerifyUserAttributeException["InvalidParameterException"] = "InvalidParameterException";
  VerifyUserAttributeException["LimitExceededException"] = "LimitExceededException";
  VerifyUserAttributeException["NotAuthorizedException"] = "NotAuthorizedException";
  VerifyUserAttributeException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  VerifyUserAttributeException["ResourceNotFoundException"] = "ResourceNotFoundException";
  VerifyUserAttributeException["TooManyRequestsException"] = "TooManyRequestsException";
  VerifyUserAttributeException["UserNotConfirmedException"] = "UserNotConfirmedException";
  VerifyUserAttributeException["UserNotFoundException"] = "UserNotFoundException";
})(VerifyUserAttributeException || (VerifyUserAttributeException = {}));
var UpdateDeviceStatusException;
(function (UpdateDeviceStatusException) {
  UpdateDeviceStatusException["ForbiddenException"] = "ForbiddenException";
  UpdateDeviceStatusException["InternalErrorException"] = "InternalErrorException";
  UpdateDeviceStatusException["InvalidParameterException"] = "InvalidParameterException";
  UpdateDeviceStatusException["InvalidUserPoolConfigurationException"] = "InvalidUserPoolConfigurationException";
  UpdateDeviceStatusException["NotAuthorizedException"] = "NotAuthorizedException";
  UpdateDeviceStatusException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  UpdateDeviceStatusException["ResourceNotFoundException"] = "ResourceNotFoundException";
  UpdateDeviceStatusException["TooManyRequestsException"] = "TooManyRequestsException";
  UpdateDeviceStatusException["UserNotConfirmedException"] = "UserNotConfirmedException";
  UpdateDeviceStatusException["UserNotFoundException"] = "UserNotFoundException";
})(UpdateDeviceStatusException || (UpdateDeviceStatusException = {}));
var ListDevicesException;
(function (ListDevicesException) {
  ListDevicesException["ForbiddenException"] = "ForbiddenException";
  ListDevicesException["InternalErrorException"] = "InternalErrorException";
  ListDevicesException["InvalidParameterException"] = "InvalidParameterException";
  ListDevicesException["InvalidUserPoolConfigurationException"] = "InvalidUserPoolConfigurationException";
  ListDevicesException["NotAuthorizedException"] = "NotAuthorizedException";
  ListDevicesException["PasswordResetRequiredException"] = "PasswordResetRequiredException";
  ListDevicesException["ResourceNotFoundException"] = "ResourceNotFoundException";
  ListDevicesException["TooManyRequestsException"] = "TooManyRequestsException";
  ListDevicesException["UserNotConfirmedException"] = "UserNotConfirmedException";
  ListDevicesException["UserNotFoundException"] = "UserNotFoundException";
})(ListDevicesException || (ListDevicesException = {}));
const SETUP_TOTP_EXCEPTION = 'SetUpTOTPException';
export { AssociateSoftwareTokenException, ChangePasswordException, ConfirmDeviceException, ConfirmForgotPasswordException, ConfirmSignUpException, DeleteUserAttributesException, DeleteUserException, ForgetDeviceException, ForgotPasswordException, GetCredentialsForIdentityException, GetIdException, GetUserAttributeVerificationException, GetUserException, GlobalSignOutException, InitiateAuthException, ListDevicesException, ResendConfirmationException, RespondToAuthChallengeException, SETUP_TOTP_EXCEPTION, SetUserMFAPreferenceException, SignUpException, UpdateDeviceStatusException, UpdateUserAttributesException, VerifySoftwareTokenException, VerifyUserAttributeException };
