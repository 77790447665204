@if (card) {
    <div class="flipping-card" data-aos="fade-up">
        <div class="front-face">
            <img [src]="card.cover" alt="card_image">
            <h4 class="card-title" [innerHTML]="cardFrontTitle"></h4>
        </div>
    
        <div class="back-face">
            <h4 class="card-title" [innerHTML]="card.title"></h4>
            <p class="card-body">{{card.body}}</p>
        </div>
    </div>
}
