import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SectionContactUsComponent } from "../../components/section-contact-us/section-contact-us.component";
import { GradientTextWrapperComponent } from "../../components/gradient-text-wrapper/gradient-text-wrapper.component";
import { getYoutubeID, scrollToTop } from '../../services/utilities.service';
import { MediaSectionComponent } from "../../components/media-section/media-section.component";
import { TranslateService } from '@ngx-translate/core';
import { ISitePageNews } from '../../models/pages';
import { ApiService } from '../../services/api.service';
import { IMediaSectionItem } from '../../models/common';
import { VIDEO_SOURCES } from '../../models/constants';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, SectionContactUsComponent, GradientTextWrapperComponent, MediaSectionComponent],
})
export class HomePageComponent implements OnInit {

  readonly videoSrc = VIDEO_SOURCES.HOME;

  pageData?: {
    title: string,
    highlightedTitle: string,
    items: IMediaSectionItem[],
  };

  insights: ISitePageNews = { items: [] };

  get currentLang(): string {
    return this.translate.currentLang;
  }

  get introData(): { title: string, highlightedTitle: string } {
    return this.pageData ?? {
      title: "The future belongs to people who can",
      highlightedTitle: "transform the present."
    }
  }

  constructor (
    private readonly translate: TranslateService,
    private readonly api: ApiService,
  ) { }

  ngOnInit(): void {
    this.loadPageData();
    this.loadInsightList();

    scrollToTop();
  }

  private async loadPageData() {
    // this.pageData = {
    //   "title": "The future belongs to people who can",
    //   "highlightedTitle": "transform the present",
    //   "items": [
    //     {
    //       "type": "text",
    //       "size": "half",
    //       "theme": "electric-blue", // 'white' | 'deep-blue' | 'fluo-green' | 'electric-blue';
    //       "link": {
    //         "label": "more about us",
    //         "route": "/it/about-us/our-world"
    //       },
    //       "title": "We are leading providers of cutting-edge advisory services, tech solutions and research insights."
    //     },
    //     {
    //       "type": "insight",
    //       "size": "half",
    //       "title": "",
    //       "insight": {
    //         "slug": "rapporto-di-previsione",
    //         "title": "Rapporto di Previsione",
    //         "category": "events",
    //         "types": [],
    //         "heroImage": "/cdn2/news/2024/11/9818d952-0d2a-46a1-b8a7-bb968a897431.png",
    //         "eventDate": new Date("2024-10-29"),
    //         "subtitle": "Macroeconomic, sectoral and financial models and forecasts with the inclusion of ESG and climate risk factors. Training on ESG and climate risk regulatory developments and business implications."
    //       }
    //     },
    //     {
    //       "type": "insight-list", // widget per le 4 news più recenti escluso "case-studies"
    //       "size": "full",
    //       "theme": "white",
    //       "title": "",
    //     },
    //     {
    //       "type": "video",
    //       "size": "full",
    //       "url": "https://www.youtube.com/embed/6B9oEYr5vEY",
    //       "cover": "/cdn2/upload/2024/9/fc6392f0-7de8-4d42-aec5-c0ae1a2f1094.jpg",
    //       "title": "",
    //     },
    //     {
    //       "type": "full-section",
    //       "title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in sapien urna.",
    //       "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit amet, consectetur adipiscing elit dolor sit amet, consectetur adipiscing elit dolor sit amet, consectetur adipiscing elit dolor sit amet, consectetur adipiscing elit dolor sit amet, consectetur adipiscing elit. Nullam sit.",
    //       "cover": "/assets/images/prom-gradient.png",
    //       "imageUrl": "/assets/images/Prometeia50_Spa_NEG.png",
    //       "theme": "deep-blue",
    //       "link": {
    //         "label": "More about us",
    //         "route": "/about-us"
    //       }
    //     },
    //     {
    //       "type": "text+link",
    //       "size": "half",
    //       "theme": "deep-blue", // 'white' | 'deep-blue' | 'fluo-green' | 'electric-blue';
    //       "link": {
    //         "label": "discover more",
    //         "route": "/it/about-us/our-world"
    //       },
    //       "title": "Advanced Analytics & Modeling",
    //       "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed quam in orci scelerisque dignissim."
    //     },
    //     {
    //       "type": "insight",
    //       "size": "half",
    //       "title": "",
    //       "insight": {
    //         "slug": "prometeia-sponsor-of-riskminds-international-2024",
    //         "title": "Prometeia sponsor of RiskMinds International 2024",
    //         "category": "news",
    //         "types": [
    //           NewsTypesWithIconEnum.Video
    //         ],
    //       }
    //     },
    //     {
    //       "type": "full-section",
    //       "title": "We cultivate unique expertise to grow alongside our clients",
    //       "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit dolor sit amet, consectetur adipiscing elit dolor sit amet",
    //       "cover": "/assets/images/bg-expertise.png",
    //       "theme": "deep-blue",
    //       "link": {
    //         "label": "More about us",
    //         "route": "/about-us"
    //       }
    //     },
    //     {
    //       "type": "insight",
    //       "size": "half",
    //       "title": "",
    //       "insight": {
    //         "slug": "prometeia-strengthens-its-positioning-in-the-2023-risktech100",
    //         "title": "Prometeia strengthens its positioning in the 2023 RiskTech100",
    //         "category": "awards",
    //         "types": [
              
    //         ]
    //       }
    //     },
    //     {
    //       "type": "text+link",
    //       "size": "half",
    //       "theme": "white", // 'white' | 'deep-blue' | 'fluo-green' | 'electric-blue';
    //       "link": {
    //         "label": "discover more",
    //         "route": "/it/about-us/our-world"
    //       },
    //       "title": "SaaS & Cloud",
    //       "body": "Prometeia offers tech and regulatory solutions as a service, leveraging the expertise and the power of the Cloud provided by the most important market hyperscalers."
    //     },
    //     {
    //       "type": "image",
    //       "size": "half",
    //       "url": "/cdn2/upload/2024/10/70febe72-4679-4996-be39-1e423617acc0.png",
    //       "title": "",
    //     },
    //     {
    //       "type": "text+link",
    //       "size": "half",
    //       "theme": "deep-blue", // 'white' | 'deep-blue' | 'fluo-green' | 'electric-blue';
    //       "link": {
    //         "label": "open positions",
    //         "route": "/it/careers/open-positions"
    //       },
    //       "title": "Join Our Tribe",
    //       "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed quam in orci scelerisque dignissim."
    //     }
    //   ]
    // }

    try {
      this.pageData = await this.api.pages.home() as any;

      this.formatPage();
    } catch (ex) {
      console.error();
    }

    // console.log(this.pageData);
  }

  private formatPage() {
    this.editYoutubeVideoLinks();
  }

  private editYoutubeVideoLinks() {
    this.pageData?.items?.forEach((item: IMediaSectionItem) => {
      if (item.type === 'video' && item.url && !item.url.endsWith('.mp4')) {
        // console.log("YT ID:", getYoutubeID(item.url));
        item.url = `https://www.youtube-nocookie.com/embed/${getYoutubeID(item.url)}`;
      }
    });
  }

  private async loadInsightList() {
    try {
      const filters = { skip: 0, limit: 4, categories: 'ideas;news;awards;events' };
      const news: ISitePageNews = await this.api.pages.insights(filters);
      this.insights = news;
    } catch (ex) {
      console.error(ex);
    }
  }

}
