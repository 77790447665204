@if (card) {
    <div class="image-card default-card-border border-color--{{borderColor}} size--{{cardHeight}}" [ngStyle]="{'height': calculatedHeight ? calculatedHeight + 'px' : 'auto'}">
        <img class="card-image size--{{imageSize}}" [src]="card.cover" alt="card_image">
        <div class="card-info">
            <h4 class="card-title" [innerHTML]="card.title"></h4>

            @if (card.body.trim().startsWith('<p>')) {
                <div class="card-body {{useMarginInBody ? 'use-margin-in-body' : ''}}" [innerHTML]="card.body"></div>
            }
            @else {
                <div class="card-body {{useMarginInBody ? 'use-margin-in-body' : ''}}"><p [innerHTML]="card.body"></p></div>}
        </div>
    </div>
}
