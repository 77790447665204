import { Component, Input, OnInit } from '@angular/core';
import { ButtonTheme, IMediaSectionItem, LocaleEnum } from '../../../models/common';
import { CommonModule } from '@angular/common';
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ArDialogAuthComponent } from '../../../components/ar-dialog-auth/ar-dialog-auth.component';
import { DialogComponent } from '../../../components/dialog/dialog.component';
import { AmplifyAuthService } from '../../../services/amplify-auth.service';
import { scrollToTop } from '../../../services/utilities.service';

@Component({
  selector: 'app-reserved-area-list-item',
  templateUrl: './reserved-area-list-item.component.html',
  styleUrl: './reserved-area-list-item.component.scss',
  standalone: true,
  imports: [CommonModule, PromAnimatedButtonComponent],
})
export class ReservedAreaListItemComponent implements OnInit {

  @Input() block?: IMediaSectionItem;
  @Input() currentLang: string = LocaleEnum.IT;
  @Input() index: number = -1;
  @Input() buttonTheme: ButtonTheme = 'fluo-green';

  constructor(
    private readonly translate: TranslateService,
    private readonly amplifyService: AmplifyAuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    scrollToTop();
  }

  scrollToTop = scrollToTop;


  /** RESERVED AREA */
  private COOKIE_TOKEN_AR : string = 'prom-ar-token';

  isLoggedAR : boolean = false;
  showLogout : boolean = false;

  private routerEventsSubscription?: Subscription;

  private destroyAR() {
    this.checkPoolStatus();
    this.routerEventsSubscription?.unsubscribe();
  }

  private async checkPoolStatus() {
    this.isLoggedAR = await this.amplifyService.isLoggedAR(this.COOKIE_TOKEN_AR);
    this.showLogout = this.isLoggedAR && window.location.href.includes('reserved-area');    
  }

  async login() {    
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px',
      id: 'modal-ar-login'
    });
    dialogRef.afterClosed().subscribe( async () => {
      this.checkPoolStatus();
    })
  }

  async logout() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant('AR.LOGOUT_CONFIRM'), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      if(result){
        const r = await this.amplifyService.logout('ar');
        this.amplifyService.removeTokenAR(this.COOKIE_TOKEN_AR);
        this.router.navigate(['/']);
        this.checkPoolStatus();    
      }
      else{
        dialogRef.close();
      }
    });
  }  

  resevedArea(){
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate(['/', 'reserved-area']);
    else window.location.reload();
  }

}
