import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase',
  standalone: true
})
export class KebabCasePipe implements PipeTransform {

  transform(value: string): string {
    if (typeof value !== 'string') {
      return '';
    }

    return value.toLowerCase().replace(/\s+/g, '-');
  }

}
