{
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Nq99kripE",
    "aws_user_pools_web_client_id": "4u8eud9711deifavlhqd2chetc",
    "oauth": {
        "domain": "prometeia-alumni-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://www.prometeia.3logic.it/alumni-authenticated",
        "redirectSignOut": "https://www.prometeia.3logic.it/alumni-disconnected",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
}