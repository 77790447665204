// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var Framework;
(function (Framework) {
  // < 100 - Web frameworks
  Framework["WebUnknown"] = "0";
  Framework["React"] = "1";
  Framework["NextJs"] = "2";
  Framework["Angular"] = "3";
  Framework["VueJs"] = "4";
  Framework["Nuxt"] = "5";
  Framework["Svelte"] = "6";
  // 100s - Server side frameworks
  Framework["ServerSideUnknown"] = "100";
  Framework["ReactSSR"] = "101";
  Framework["NextJsSSR"] = "102";
  Framework["AngularSSR"] = "103";
  Framework["VueJsSSR"] = "104";
  Framework["NuxtSSR"] = "105";
  Framework["SvelteSSR"] = "106";
  // 200s - Mobile framework
  Framework["ReactNative"] = "201";
  Framework["Expo"] = "202";
})(Framework || (Framework = {}));
var Category;
(function (Category) {
  Category["API"] = "api";
  Category["Auth"] = "auth";
  Category["Analytics"] = "analytics";
  Category["DataStore"] = "datastore";
  Category["Geo"] = "geo";
  Category["InAppMessaging"] = "inappmessaging";
  Category["Interactions"] = "interactions";
  Category["Predictions"] = "predictions";
  Category["PubSub"] = "pubsub";
  Category["PushNotification"] = "pushnotification";
  Category["Storage"] = "storage";
})(Category || (Category = {}));
var AnalyticsAction;
(function (AnalyticsAction) {
  AnalyticsAction["Record"] = "1";
  AnalyticsAction["IdentifyUser"] = "2";
})(AnalyticsAction || (AnalyticsAction = {}));
var ApiAction;
(function (ApiAction) {
  ApiAction["GraphQl"] = "1";
  ApiAction["Get"] = "2";
  ApiAction["Post"] = "3";
  ApiAction["Put"] = "4";
  ApiAction["Patch"] = "5";
  ApiAction["Del"] = "6";
  ApiAction["Head"] = "7";
})(ApiAction || (ApiAction = {}));
var AuthAction;
(function (AuthAction) {
  AuthAction["SignUp"] = "1";
  AuthAction["ConfirmSignUp"] = "2";
  AuthAction["ResendSignUpCode"] = "3";
  AuthAction["SignIn"] = "4";
  AuthAction["FetchMFAPreference"] = "6";
  AuthAction["UpdateMFAPreference"] = "7";
  AuthAction["SetUpTOTP"] = "10";
  AuthAction["VerifyTOTPSetup"] = "11";
  AuthAction["ConfirmSignIn"] = "12";
  AuthAction["DeleteUserAttributes"] = "15";
  AuthAction["DeleteUser"] = "16";
  AuthAction["UpdateUserAttributes"] = "17";
  AuthAction["FetchUserAttributes"] = "18";
  AuthAction["ConfirmUserAttribute"] = "22";
  AuthAction["SignOut"] = "26";
  AuthAction["UpdatePassword"] = "27";
  AuthAction["ResetPassword"] = "28";
  AuthAction["ConfirmResetPassword"] = "29";
  AuthAction["FederatedSignIn"] = "30";
  AuthAction["RememberDevice"] = "32";
  AuthAction["ForgetDevice"] = "33";
  AuthAction["FetchDevices"] = "34";
  AuthAction["SendUserAttributeVerificationCode"] = "35";
  AuthAction["SignInWithRedirect"] = "36";
})(AuthAction || (AuthAction = {}));
var DataStoreAction;
(function (DataStoreAction) {
  DataStoreAction["Subscribe"] = "1";
  DataStoreAction["GraphQl"] = "2";
})(DataStoreAction || (DataStoreAction = {}));
var GeoAction;
(function (GeoAction) {
  GeoAction["SearchByText"] = "0";
  GeoAction["SearchByCoordinates"] = "1";
  GeoAction["SearchForSuggestions"] = "2";
  GeoAction["SearchByPlaceId"] = "3";
  GeoAction["SaveGeofences"] = "4";
  GeoAction["GetGeofence"] = "5";
  GeoAction["ListGeofences"] = "6";
  GeoAction["DeleteGeofences"] = "7";
})(GeoAction || (GeoAction = {}));
var InAppMessagingAction;
(function (InAppMessagingAction) {
  InAppMessagingAction["SyncMessages"] = "1";
  InAppMessagingAction["IdentifyUser"] = "2";
  InAppMessagingAction["NotifyMessageInteraction"] = "3";
})(InAppMessagingAction || (InAppMessagingAction = {}));
var InteractionsAction;
(function (InteractionsAction) {
  InteractionsAction["None"] = "0";
})(InteractionsAction || (InteractionsAction = {}));
var PredictionsAction;
(function (PredictionsAction) {
  PredictionsAction["Convert"] = "1";
  PredictionsAction["Identify"] = "2";
  PredictionsAction["Interpret"] = "3";
})(PredictionsAction || (PredictionsAction = {}));
var PubSubAction;
(function (PubSubAction) {
  PubSubAction["Subscribe"] = "1";
})(PubSubAction || (PubSubAction = {}));
var PushNotificationAction;
(function (PushNotificationAction) {
  PushNotificationAction["InitializePushNotifications"] = "1";
  PushNotificationAction["IdentifyUser"] = "2";
})(PushNotificationAction || (PushNotificationAction = {}));
var StorageAction;
(function (StorageAction) {
  StorageAction["UploadData"] = "1";
  StorageAction["DownloadData"] = "2";
  StorageAction["List"] = "3";
  StorageAction["Copy"] = "4";
  StorageAction["Remove"] = "5";
  StorageAction["GetProperties"] = "6";
  StorageAction["GetUrl"] = "7";
})(StorageAction || (StorageAction = {}));
export { AnalyticsAction, ApiAction, AuthAction, Category, DataStoreAction, Framework, GeoAction, InAppMessagingAction, InteractionsAction, PredictionsAction, PubSubAction, PushNotificationAction, StorageAction };
