import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios, { AxiosInstance, HttpStatusCode } from 'axios';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocaleEnum } from '../models/common';

export interface IRestClientConfig {
  baseUrl: string;
}



@Injectable({
  providedIn: 'root'
})
export class RestClientService {

  private axios?: AxiosInstance;
  private authTokens?: { token: string; refreshToken: string } = undefined;
  private config?: IRestClientConfig;

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) { }

  public async defaultInit(): Promise<AxiosInstance> {
    return await this.init({
      baseUrl: this.config ? this.config.baseUrl : environment.api.baseEndpoint,
    });
  }

  public async init(config: IRestClientConfig): Promise<AxiosInstance> {
    /** set up rest client config */
    this.config = config;

    
    try {
      this.axios = this.newClientInstance();
      this.setupInterceptors();
      // if (this.authTokens) {
      //   this.axios.defaults.headers.common['Authorization'] = `Bearer ${this.authTokens.token}`;
      // }
      this.setupLocalization();
      return this.axios;
    } catch (ex) {
      console.error(ex);
    }

    return axios;
  }

  private _localization?: string;
  public setupLocalization(localization?: string, message?: string): void {
    this._localization = localization ?? this.translate.currentLang ?? 'it';
    if (message) console.log('setupLocalization', this._localization, message);
    this.axios!.defaults.headers.common['x-locale'] = this._localization;
  }

  /**
   * A getter method for the rest client.
   *
   * @return {AxiosInstance} The Axios instance used as the rest client
   * 
   * @example ```
   * restClient.get<IUser>('/users/me', undefined);
   * ```
   * @example ```
   * restClient.get<IProject>('/projects/${prjID}', { state: 'ACTIVE' });
   * ```
   * @example ```
   * restClient.post('/projects/', body);
   * ```
  */
  public get restClient(): AxiosInstance {
    if (!this.config) {
      throw new Error('Rest client not initialized (err ID: 0x0010)');
    }

    const localeFromTranslate: string | undefined = this.translate.currentLang;

    /** initialize rest client and interceptors */
    try {
      this.axios = this.newClientInstance();
      this.setupInterceptors();
      this.setupLocalization(localeFromTranslate);
      return this.axios;
    } catch (ex) {
      console.error(ex);
      throw new Error('Rest client not initialized (err ID: 0x0011)');
    }
    
  }

  public get authorization(): string | undefined {
    if (!this.authTokens) {
      return;
    }
    console.log("Authorization OK");
    return `Bearer ${this.authTokens.token}`;
  }


  private newClientInstance() {
    if (!this.config) {
      throw new Error('Rest client not initialized (err ID: 0x1010)');
    }
    
    return axios.create({
      baseURL: this.config.baseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      paramsSerializer: (params: any) => {
        let result = '';
        Object.keys(params).forEach(key => {
            result += `${key}=${encodeURIComponent(params[key])}&`;
        });
        return result.substring(0, result.length - 1);
      }
    });
  }

  private setupInterceptors() {
    if (!this.config || !this.axios) {
      throw new Error('Rest client not initialized (err ID: 0x2011)');
    }

    /** REQUEST interceptor */
    this.axios.interceptors.request.use(
      /** onFulfilled */
      async (config: any) => {
        return config;
      },

      /** onRejected */
      (error: any) => {
        return Promise.reject(error);
      }
    );



    /** RESPONSE interceptor */
    this.axios.interceptors.response.use(
      /** onFulfilled */
      (response: any) => response.data,

      /** onRejected */
      async (error: any) => {
        if ((!error?.response || error.response.status === HttpStatusCode.Unauthorized) && this.authTokens?.refreshToken && !error.config._retry) {
          error.config._retry = true;
          try {
            return this.axios!.request(error.config);
          } catch (ex: any) {
            console.error('Failed to renew auth tokens', ex.message);
          }
        } else if (!error?.response || error.response.status === HttpStatusCode.Unauthorized) {
          this.router.navigate(['/']);
        }

        return Promise.reject(error);
      }
    )
  }
}
