import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocaleEnum } from '../../models/common';
import { RestClientService } from '../../services/rest-client.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PromAnimatedButtonComponent } from '../prom-animated-button/prom-animated-button.component';

@Component({
  selector: 'app-header-sim',
  templateUrl: './header-sim.component.html',
  styleUrl: './header-sim.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatButtonModule, MatIconModule, PromAnimatedButtonComponent],
})
export class HeaderSimComponent {

  arURL: string = "https://am.advisor.prometeia.net/";

  get currentLang(): string {
    return this.translate.currentLang;
  }

  isLanguagesOpen: boolean = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly restClient: RestClientService,
    private readonly router: Router,
  ) { }

  changeLanguage(newLocale?: string) {
    const loc = newLocale ?? (this.translate.currentLang === LocaleEnum.EN ? LocaleEnum.IT : LocaleEnum.EN);
    this.isLanguagesOpen = false;
    this.translate.use(loc);
    // this.translate.reloadLang(this.translate.currentLang);
    this.restClient.setupLocalization(newLocale);
    this.reloadCurrentRoute(loc);
  }

  reloadCurrentRoute(newLocale: string) {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.startsWith("/sim/it/") ? currentUrl.replace("/sim/it/", `/sim/${newLocale}/`) : currentUrl.replace("/sim/en/", `/sim/${newLocale}/`);

    this.router.navigateByUrl(currentUrl);
  }

}
