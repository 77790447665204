import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AmplifyAuthService } from './amplify-auth.service';
import { STRING_SEPARATOR } from '../models/constants';
import { LocaleEnum } from '../models/common';
import { BrowserServices } from './browser.service';

export interface ICookieServiceRet {
    statistics_analytics: string | null,
    marketing: string | null,
}

export const ST_AN_INDEX: number = 1;
export const MRKT_INDEX: number = 2;

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    private storage = window.localStorage;
    private session = window.sessionStorage;

    private static MAIN_KEY = 'LOCAL:COOKIE_MAIN';
    private static STATISTICS_ANALYTICS_KEY = 'LOCAL:COOKIE_AN_ST';
    private static MARKETING_KEY = 'LOCAL:COOKIE_MRKT';

    private static SPECIAL_MSG_KEY = 'SESSION:SP_MSG';

    private static LANG_KEY = 'LOCAL:LANG';

    private cookieLabelMain = "main";
    private cookieLabelAnalytics = "st_an";
    private cookieLabelMarketing = "markt";

    public forceReopenCookieModal: boolean = false;


    constructor(
        private readonly browserServices: BrowserServices,
    ) { }

    public getCookieLabelMain() {
        return this.cookieLabelMain;
    }

    // public getCookieLabelAnalytics() {
    //     return this.cookieLabelAnalytics;
    // }

    // public getCookieLabelMarketing() {
    //     return this.cookieLabelMarketing;
    // }


    private getDate() {
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return (year)*10000 + month*100 + day;
    }

    private getExpiration() {
        /** adds a year to the current date */
        return this.getDate() + 10000;
    }

    /** LOCAL STORAGE */

    /** @returns {boolean} true if main cookie is stored */
    public isCookiesStored(): boolean {
        return this.loadMainCookie() != null;
    }

    public loadMainCookie(): string | null {
        const main_cookie_and_expiration = this.storage.getItem(CookieService.MAIN_KEY) || null;

        if (!main_cookie_and_expiration) return null;

        const main_cookie = main_cookie_and_expiration.split(STRING_SEPARATOR)[0];
        const expiration: number = Number(main_cookie_and_expiration.split(STRING_SEPARATOR)[1]);

        // console.log(main_cookie, expiration);

        if (!expiration || isNaN(expiration) || this.getDate() >= expiration) {
            console.log("Cookie expired");
            this.removeMainCookie();
            this.removeCookiePreferences();
            return null;
        } /* else {
            console.log("Cookie still valid");
        } */

        return main_cookie;
        
        // console.error("Cookie Service", "error in loadMainCookie:", main_cookie);
    }

    public async setMainCookie() {
        let main_cookie = this.getCookieLabelMain();

        if (main_cookie) {
            // console.log("Cookie Service", "setMainCookie:", main_cookie + STRING_SEPARATOR + this.getExpiration());
            this.storage.setItem(CookieService.MAIN_KEY, main_cookie + STRING_SEPARATOR + this.getExpiration());
        } else {
            console.error("Cookie Service", "error in setMainCookie:", main_cookie);
        }

    }

    public removeMainCookie() {
        this.storage.removeItem(CookieService.MAIN_KEY);
    }



    public loadCookiePreferences(): ICookieServiceRet {
        let st_an = this.storage.getItem(CookieService.STATISTICS_ANALYTICS_KEY) || null;
        let mrkt = this.storage.getItem(CookieService.MARKETING_KEY) || null;

        if (st_an || mrkt) {
            if (!st_an) {
                return {statistics_analytics: null, marketing: mrkt};
            }
            if (!mrkt) {
                return {statistics_analytics: st_an, marketing: null};
            }
            return {statistics_analytics: st_an, marketing: mrkt};
        } else {
            // console.error("CookiePreferences Service", "error in loadCookiePreferences:", st_an, mrkt);
            return {statistics_analytics: null, marketing: null};
        }
    }

    public async setCookiePreferences(cookiePreference: number[]) {
        // console.log("CookiePreferences", cookiePreference, cookiePreference[ST_AN_INDEX] == 1, cookiePreference[MRKT_INDEX] == 1);

        let st_an = null;
        if (cookiePreference[ST_AN_INDEX] == 1) st_an = this.cookieLabelAnalytics;

        let mrkt = null;
        if (cookiePreference[MRKT_INDEX] == 1) mrkt = this.cookieLabelMarketing;
        

        if (st_an || mrkt) {
            if (st_an) {
                this.storage.setItem(CookieService.STATISTICS_ANALYTICS_KEY, st_an);
            }
            if (mrkt) {
                this.storage.setItem(CookieService.MARKETING_KEY, mrkt);
            }
        } /* else {
            console.error("CookiePreferences Service", "error in setCookiePreferences:", st_an, mrkt);
        } */

    }

    public removeCookiePreferences() {
        this.storage.removeItem(CookieService.STATISTICS_ANALYTICS_KEY);
        this.storage.removeItem(CookieService.MARKETING_KEY);
    }

    public isAnalyticsEnabled() {   //google analytics, hubspot & tableau
        const cookie = this.loadCookiePreferences().statistics_analytics || null;
        const cookie_enabled: boolean = (cookie != null && cookie.length > 0) && cookie == this.cookieLabelAnalytics;
        return cookie_enabled && this.loadMainCookie() != null;
    }

    public isMarketingEnabled() {   //youtube
        const cookie = this.loadCookiePreferences().marketing || null;
        const cookie_enabled: boolean = (cookie != null && cookie.length > 0) && cookie == this.cookieLabelMarketing;
        return cookie_enabled && this.loadMainCookie() != null;
    }



    /** SESSION STORAGE */
    public loadSpecialMessageSessionCookie(): string | null {
        let special_msg_cookie = this.session.getItem(CookieService.SPECIAL_MSG_KEY) || null;

        return special_msg_cookie;
        
        // console.error("Cookie Service", "error in loadSpecialMessageSessionCookie:", special_msg_cookie);
    }

    public async setSpecialMessageSessionCookie() {
        let special_msg_cookie = "sp_msg";
        
        if (special_msg_cookie) {
            // console.log("Cookie Service", "setSpecialMessageSessionCookie:", special_msg_cookie);
            this.session.setItem(CookieService.SPECIAL_MSG_KEY, special_msg_cookie);
        } /* else {
            console.error("Cookie Service", "error in setSpecialMessageSessionCookie:", special_msg_cookie);
        } */

    }


    /** LANGUAGE */
    private DEFAULT_LANG = LocaleEnum.IT;

    public getLang() {
        if (!this.isLangStored()) {
            this.setLangCookie();
        }
        this.DEFAULT_LANG = this.loadLangCookie() as LocaleEnum;

        return this.DEFAULT_LANG;
    }

    public isLangStored(): boolean {
        return this.loadLangCookie() != null;
    }
    
    public loadLangCookie(): string | null {
        const lang_cookie_and_expiration = this.storage.getItem(CookieService.LANG_KEY) || null;

        if (!lang_cookie_and_expiration) return null;

        const lang_cookie = lang_cookie_and_expiration.split(STRING_SEPARATOR)[0];
        const expiration: number = Number(lang_cookie_and_expiration.split(STRING_SEPARATOR)[1]);

        // console.log(main_cookie, expiration);

        if (!expiration || isNaN(expiration) || this.getDate() >= expiration) {
            // console.log("Cookie expired");
            this.removeLangCookie();
            return null;
        } /* else {
            console.log("Cookie still valid");
        } */

        return lang_cookie;
        
        // console.error("Cookie Service", "error in loadLangCookie:", lang_cookie);
    }

    public async setLangCookie(_lang: string | null = null) {
        //priorità: from url, from cookies, from browser
        let lang = _lang;
        if (!lang) lang = this.browserServices.myLanguage();

        if (lang) {
            // console.log("Cookie Service", "setLangCookie:", lang + STRING_SEPARATOR + this.getExpiration());
            this.storage.setItem(CookieService.LANG_KEY, lang + STRING_SEPARATOR + this.getExpiration());
        } /* else {
            console.error("Cookie Service", "error in setLangCookie:", lang);
        } */
    }

    public removeLangCookie() {
        this.storage.removeItem(CookieService.LANG_KEY);
    }
}
