import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ExpertisePageComponent } from './pages/expertise-page/expertise-page.component';
import { SolutionsPageComponent } from './pages/solutions/solutions-page/solutions-page.component';
import { SolutionsSubpageComponent } from './pages/solutions/solutions-subpage/solutions-subpage.component';
import { ReservedAreaComponent } from './pages/reserved-area/reserved-area.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { CustomersPageComponent } from './pages/customers-page/customers-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { InsightsPageComponent } from './pages/about-us/insights-page/insights-page.component';
import { InsightsArticlePageComponent } from './pages/about-us/insights-article-page/insights-article-page.component';
import { OpenPositionsPageComponent } from './pages/careers/open-positions-page/open-positions-page.component';
import { OpenPositionsArticlePageComponent } from './pages/careers/open-positions-article-page/open-positions-article-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { DEFAULT_LANG } from './models/common';
import { ReservedAreasListPageComponent } from './pages/reserved-areas-list-page/reserved-areas-list-page.component';
import { OurTribePageComponent } from './pages/careers/our-tribe-page/our-tribe-page.component';
import { SimLayoutComponent } from './layouts/sim-layout/sim-layout.component';
import { SimHomePageComponent } from './pages/sim/sim-home-page/sim-home-page.component';
import { SimPageComponent } from './pages/sim/sim-page/sim-page.component';
import { SimArchiveComponent } from './pages/sim/sim-archive/sim-archive.component';
import { SimArticleComponent } from './pages/sim/sim-article/sim-article.component';
import { SocialResponsibilityPageComponent } from './pages/about-us/social-responsibility-page/social-responsibility-page.component';
import { OurWorldPageComponent } from './pages/about-us/our-world-page/our-world-page.component';
import { OurTeamPageComponent } from './pages/about-us/our-team-page/our-team-page.component';
import { ArticlePageComponent } from './pages/article-page/article-page.component';

export const routes: Routes = [
    {
        path: 'sim',
        component: SimLayoutComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}` },
            { path: ':lang', component: SimHomePageComponent },

            { path: 'page/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/page/:slug` },
            { path: ':lang/page/:slug', component: SimPageComponent },

            { path: 'in-formation-paths/latest', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/in-formation-paths/latest` },
            { path: ':lang/in-formation-paths/latest', component: SimArticleComponent },

            { path: ':slug/latest', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/:slug/latest` },
            { path: ':lang/:slug/latest', component: SimArchiveComponent },

            { path: 'archive/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/archive/:slug` },
            { path: ':lang/archive/:slug', component: SimArchiveComponent },

            { path: ':baseRoute/article/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/:baseRoute/article/:slug` },
            { path: ':lang/:baseRoute/article/:slug', component: SimArticleComponent },

            { path: '404', component: PageNotFoundComponent },
            { path: '**', component: PageNotFoundComponent }
        ]
    },
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}` },
            { path: ':lang', component: HomePageComponent },

            { path: 'expertise/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/expertise/:slug` },
            { path: ':lang/expertise/:slug', component: ExpertisePageComponent },

            { path: 'solutions/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/solutions/:slug` },
            { path: ':lang/solutions/:slug', component: SolutionsPageComponent },
                { path: 'solutions/:category/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/solutions/:category/:slug` },
                { path: ':lang/solutions/:category/:slug', component: SolutionsSubpageComponent },
            
            { path: 'customers/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/customers/:slug` },
            { path: ':lang/customers/:slug', component: CustomersPageComponent },
            

            { path: 'about-us/social-responsibility', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/about-us/social-responsibility` },
            { path: ':lang/about-us/social-responsibility', component: SocialResponsibilityPageComponent },

            { path: 'about-us/insights', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/about-us/insights` },
            { path: ':lang/about-us/insights', component: InsightsPageComponent },
                { path: 'about-us/insights/article/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/about-us/insights/article/:slug` },
                { path: ':lang/about-us/insights/article/:slug', component: InsightsArticlePageComponent },

            { path: 'about-us/our-world', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/about-us/our-world` },
            { path: ':lang/about-us/our-world', component: OurWorldPageComponent },

            { path: 'about-us/our-team', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/about-us/our-team` },
            { path: ':lang/about-us/our-team', component: OurTeamPageComponent },


            { path: 'careers/open-positions', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/careers/open-positions` },
            { path: ':lang/careers/open-positions', component: OpenPositionsPageComponent },
                { path: 'careers/open-positions/:jobID', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/careers/open-positions/:jobID` },
                { path: ':lang/careers/open-positions/:jobID', component: OpenPositionsArticlePageComponent },
                
            { path: 'careers/our-tribe', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/careers/our-tribe` },
            { path: ':lang/careers/our-tribe', component: OurTribePageComponent },


            { path: 'search', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/search` },
            { path: ':lang/search', component: SearchPageComponent },

            { path: 'article/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/article/:slug` },
            { path: ':lang/article/:slug', component: ArticlePageComponent },


            { path: 'reserved-areas', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/reserved-areas` },
            { path: ':lang/reserved-areas', component: ReservedAreasListPageComponent },

            { path: 'reserved-area', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/reserved-area` },
            { path: ':lang/reserved-area', component: ReservedAreaComponent },


            /** REDIRECTS - start */
            // { path: 'news/:slug', pathMatch: 'full', redirectTo: 'it/about-us/insights/article/:slug' },
            // { path: 'insights/:slug', pathMatch: 'full', redirectTo: 'it/about-us/insights/article/:slug' },
            // { path: ':lang/insights/:slug', pathMatch: 'full', redirectTo: ':lang/about-us/insights/article/:slug' },
            { path: 'trending-topics-article/:slug', pathMatch: 'full', redirectTo: `${DEFAULT_LANG}/about-us/insights/article/:slug` },
            { path: ':lang/trending-topics-article/:slug', pathMatch: 'full', redirectTo: ':lang/about-us/insights/article/:slug' },
            /** REDIRECTS - end */


            { path: '404', component: PageNotFoundComponent },
            { path: '**', component: PageNotFoundComponent }
        ]
    }
];
